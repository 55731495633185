import React, { useState } from "react";
import api from "../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ClipLoader } from "react-spinners";

const schema = yup.object().shape({
  treeId: yup.string().required("Tree ID is required"),
  image: yup.mixed().required("Tree Image is required"),
});

const TreeHistory = () => {
  const [treeId, setTreeId] = useState("");
  const [treeDetails, setTreeDetails] = useState(null);
  const [image, setImage] = useState(null);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSearching, setIsSearching] = useState(false);


  const { register, handleSubmit, formState: { errors }, setValue, watch, trigger ,reset} = useForm({
    resolver: yupResolver(schema),
  });
  

  const handleSearch = async () => {
    const isValid = await trigger("treeId"); // Validate treeId before proceeding
  
    if (!isValid) return; // Stop if validation fails
    setIsSearching(true);
    try {
      const response = await api.get(`/api/upload/treehistory/${watch("treeId")}`);
      const data = response.data;
      if (data.success) {
        setTreeDetails(data.tree);
        setError("");
      } else {
        setError("Tree not found");
        setTreeDetails(null);
      }
    } catch (error) {
      console.log(error);
      setError("Tree Does not exist for the user with the tree Id");
    }finally {
      setIsSearching(false);
    }
  };
  

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    // Check if the file is an image
    if (!file || !file.type.startsWith("image/")) {
      setError("Please upload a valid image file.");
      toast.error("Please upload a valid image file.");
      e.target.value = "";
      return;
    }

    setImage(e.target.files[0]);
  };

  const handleSubmitForm = async (e) => {
    // console.log("event Occured");
    // e.preventDefault();

    if (!treeDetails || !image) {
      setError("Please complete the form");
      return;
    }

    const formData = new FormData();
    formData.append("image", image);
    formData.append("tree_id", treeDetails.tree_id);
    formData.append("userId", treeDetails.userId);
    formData.append("imageName", image.name);

    try {
      setIsSubmitting(true);
      const response = await api.post(
        "/api/upload/update-tree-history",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.success) {
        toast.success("Tree verified and uploaded successfully!");
        setTreeId("");
        setTreeDetails(null);
        setImage(null);
        reset();
      }
      if (!response.data.success) {
        toast.error("error verifying tree");
      }
    } catch (error) {
      setError("An error occurred while submitting the form");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = () => {
    setTreeDetails(null);
    setImage(null);
    setError("");
    reset();
    toast.info("Upload canceled");
  };

  return (
    <div>
      <h3>Update Tree Growth</h3>
      <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
      
      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <input
            type="text"
            placeholder="Enter Tree ID *"
            {...register("treeId")}
            style={{ flex: "1", borderColor: errors.treeId ? "red" : "" }}
          />
          <button
            type="button"
            onClick={handleSearch}
            style={{ margin: "5px", marginBottom: "25px" }}
          >
             {isSearching ? (
              <ClipLoader size={20} color="#ffffff" />
            ) : (
              'Search'
            )}
          </button>
        </div>
        {errors.treeId && (
            <p className="error-message" style={{ color: "red" }}>
              {errors.treeId.message}
            </p>
          )}
        {error && <p style={{ color: "red" }}>{error}</p>}

        {treeDetails && (
          <div>
            <p>{treeDetails.treeName}</p>
            <p>{treeDetails.tree_id}</p>
            <input
              type="file"
              onChange={(e) => {
                handleImageUpload(e);
                setValue("image", e.target.files[0]); // Registering image in react-hook-form
              }}
              style={{ borderColor: errors.image ? "red" : "" }}
            />
            {errors.image && (
              <p className="error-message" style={{ color: "red" }}>
                {errors.image.message}
              </p>
            )}
         

          <button type="submit" disabled={isSubmitting}>
            {isSubmitting ? (
                  <>
                    <ClipLoader size={20} color="#ffffff" />
                  </>
                ) : (
                  'Submit'
                )}
          </button>
          <button
          type="button"
          onClick={handleCancel}
          disabled={isSubmitting}
          className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 disabled:bg-red-300"
        >
          Cancel
        </button>
        </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
};

export default TreeHistory;
