import React, { useState, useEffect } from "react";
import AreaMap from "../popupmaps/AreaMap";
import api from "../../axios/api";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { FaMapMarkerAlt } from "react-icons/fa";

const SelectArea = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState({});

  const fetchProjectData = async (projectId) => {
    try {
      const response = await api.get(
        `/api/project/getProjectEditDetails/${projectId}`
      );
      setProject(response.data.project);
      console.log(project);
      console.log(project);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchProjectData(projectId);
  }, [projectId]);

  return (
    <div>
      <ToastContainer />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "20px",
          borderRadius: "10px",
          marginBottom: "10px",
          backgroundColor: "#f9f9f9",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Project Name and Venue */}
        <div>
          <h2 style={{ margin: 0, fontSize: "35px", fontWeight: "bold" }}>
            {project?.name || ""}
          </h2>
          <div
            style={{
              marginTop: "10px",
              color: "black",
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FaMapMarkerAlt color="#3F6B33" style={{ marginRight: "5px" }} />
            <span style={{ marginRight: "8px", color: "black" }}>Venue:</span>
            {project?.address || "N/A"}
          </div>
        </div>

        {/* Note Box */}
        <div
          style={{
            padding: "10px",
            border: "2px dashed #3F6B33",
            borderRadius: "8px",
            backgroundColor: "#f0f9f0",
            color: "#3F6B33",
            fontSize: "15px",
            fontWeight: "bold",
            maxWidth: "320px", // Prevents the box from stretching too wide
            textAlign: "center",
          }}
        >
     Note:- Draw the area of plantation on the map using tools inside map on left and save.
        </div>
      </div>

      {/* Area Map */}
      <AreaMap
        lat={project.lat}
        lng={project.longi}
        projectId={projectId}
        geoJson={project.area_geojson}
      />
    </div>
  );
};

export default SelectArea;
