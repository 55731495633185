import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-draw";
import "leaflet-control-geocoder";
import * as turf from "@turf/turf";
import {
  FaSatellite,
  FaSave,
  FaTrash,
  FaEdit,
  FaArrowLeft,
  FaTimes,
} from "react-icons/fa";
import { toast } from "react-toastify";
import api from "../../axios/api";
import { useNavigate } from "react-router-dom";

const AreaMap = ({ lat, lng, projectId, geoJson }) => {
  const mapRef = useRef(null);
  const mapContainerRef = useRef(null);
  const drawnItemsRef = useRef(null);
  const [satelliteView, setSatelliteView] = useState(false);
  const [polygonAreas, setPolygonAreas] = useState([]);
  const [totalArea, setTotalArea] = useState(0);
  const [colorIndex, setColorIndex] = useState(0);
  const colorIndexRef = useRef(0);
  const navigate = useNavigate();
  // Color palette for different polygons
  const POLYGON_COLORS = [
    { name: "Red", fill: "#FF6B6B80", stroke: "#FF6B6B" },
    { name: "Turquoise", fill: "#4ECDC480", stroke: "#4ECDC4" },
    { name: "Sky Blue", fill: "#45B7D180", stroke: "#45B7D1" },
    { name: "Green", fill: "#96CEB480", stroke: "#96CEB4" },
    { name: "Yellow", fill: "#FFEEAD80", stroke: "#FFEEAD" },
    { name: "Pink", fill: "#D4A5A580", stroke: "#D4A5A5" },
    { name: "Purple", fill: "#9B59B680", stroke: "#9B59B6" },
    { name: "Blue", fill: "#3498DB80", stroke: "#3498DB" },
    { name: "Orange", fill: "#E67E2280", stroke: "#E67E22" },
    { name: "Emerald", fill: "#2ECC7180", stroke: "#2ECC71" },
  ];

  // Function to add GeoJSON to map
  const addGeoJsonToMap = (geoJsonData) => {
    try {
      const parsedGeoJson =
        typeof geoJsonData === "string" ? JSON.parse(geoJsonData) : geoJsonData;
      setColorIndex(1); // Reset color index when loading GeoJSON

      parsedGeoJson.forEach((feature) => {
        const color = getNextColor();
        const layer = L.geoJSON(feature, {
          style: {
            fillColor: color.fill,
            fillOpacity: 0.5,
            color: color.stroke,
            weight: 2,
          },
        });
        drawnItemsRef.current.addLayer(layer);
      });

      const bounds = drawnItemsRef.current.getBounds();
      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds);
      }

      calculatePolygonAreas();
    } catch (error) {
      console.error("Error adding GeoJSON to map:", error);
      toast.error("Error loading saved areas");
    }
  };

  //update area api call function
  const UpdateArea = async (projectId, geoJson, Area) => {
    if (!projectId || !geoJson || !Area) {
      toast.error("Please provide all required information.");
      return;
    }

    const formData = new FormData();
    formData.append("projectId", projectId);
    formData.append("geoJson", geoJson);
    formData.append("Area", Area);

    try {
      const response = await api.post(`/api/project/updatearea`, formData);
      if (response.data.success) {
        toast.success("Marked Area saved Successfully");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message || "Error saving area");
    }
  };

  // Function to save all details to context
  const saveMapDetails = async () => {
    const layers = drawnItemsRef.current.getLayers();
    if (layers.length === 0) {
      toast.error("Please draw at least one area on the map.");
      return;
    }

    const geoJsonData = [];
    let totalArea = 0;

    layers.forEach((layer) => {
      const shape =
        layer instanceof L.Circle
          ? turf.circle(
              [layer.getLatLng().lng, layer.getLatLng().lat],
              layer.getRadius() / 1000,
              { units: "kilometers" }
            )
          : layer.toGeoJSON();

      geoJsonData.push(shape);
      totalArea += turf.area(shape);
    });

    await UpdateArea(projectId, JSON.stringify(geoJsonData), totalArea);
  };

  // Function to toggle the satellite view
  const toggleSatelliteView = () => {
    if (mapRef.current) {
      const baseLayers = {
        Default: L.tileLayer("https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}", {
          maxZoom: 19,
        }),
        Satellite: L.tileLayer(
          "https://mt1.google.com/vt/lyrs=m&hl=local&x={x}&y={y}&z={z}",
          { maxZoom: 19 }
        ),
      };

      if (satelliteView) {
        baseLayers.Default.addTo(mapRef.current);
      } else {
        baseLayers.Satellite.addTo(mapRef.current);
      }

      setSatelliteView(!satelliteView);
    }
  };

  // Function to calculate areas of individual polygons
  const calculatePolygonAreas = () => {
    const newPolygonAreas = [];
    let newTotalArea = 0;

    drawnItemsRef.current.eachLayer(function (layer) {
      let shape;
      let area;

      if (layer instanceof L.Circle) {
        const latlng = layer.getLatLng();
        const radius = layer.getRadius();
        shape = turf.circle([latlng.lng, latlng.lat], radius / 1000, {
          units: "kilometers",
        });
      } else {
        shape = layer.toGeoJSON();
      }

      area = turf.area(shape);
      // Get the color from the layer's style
      const layerStyle = layer.options.style || layer.options;
      const colorIndex = POLYGON_COLORS.findIndex(
        (c) => c.fill === layerStyle.fillColor
      );

      newPolygonAreas.push({
        area: area,
        color: POLYGON_COLORS[colorIndex],
        layer: layer,
        isSubtracted: false,
      });

      newTotalArea += area;
    });
    setPolygonAreas(newPolygonAreas);
    setTotalArea(newTotalArea);
  };

  // Function to get next color
  const getNextColor = () => {
    const currentColor =
      POLYGON_COLORS[colorIndexRef.current % POLYGON_COLORS.length];
    colorIndexRef.current += 1;
    setColorIndex(colorIndexRef.current);
    return currentColor;
  };

  // Remove a specific polygon
  const removePolygon = (index) => {
    const polygonToRemove = polygonAreas[index];
    drawnItemsRef.current.removeLayer(polygonToRemove.layer);

    const updatedPolygonAreas = polygonAreas.filter((_, i) => i !== index);
    setPolygonAreas(updatedPolygonAreas);

    // Recalculate total area
    const newTotalArea = updatedPolygonAreas.reduce((total, currPolygon) => {
      return currPolygon.isSubtracted
        ? total - currPolygon.area
        : total + currPolygon.area;
    }, 0);
    setTotalArea(newTotalArea);
  };

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map(mapContainerRef.current).setView(
        [lat ? lat : 20.5937, lng ? lng : 78.9629],
        lat || lng ? 15 : 5
      );

      L.tileLayer("https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}", {
        maxZoom: 19,
      }).addTo(mapRef.current);

      L.Control.geocoder({
        defaultMarkGeocode: false,
      })
        .on("markgeocode", function (e) {
          const bbox = e.geocode.bbox;
          const poly = L.polygon([
            bbox.getSouthEast(),
            bbox.getNorthEast(),
            bbox.getNorthWest(),
            bbox.getSouthWest(),
          ]).addTo(mapRef.current);
          mapRef.current.fitBounds(poly.getBounds());
        })
        .addTo(mapRef.current);

      const drawnItems = new L.FeatureGroup();
      mapRef.current.addLayer(drawnItems);
      drawnItemsRef.current = drawnItems;

      const drawControl = new L.Control.Draw({
        edit: {
          featureGroup: drawnItems,
        },
        draw: {
          polygon: true,
          rectangle: true,
          circle: true,
          marker: false,
          circlemarker: false,
          polyline: false,
        },
      });
      mapRef.current.addControl(drawControl);

      // Handle creation of new shapes
      mapRef.current.on(L.Draw.Event.CREATED, function (event) {
        const layer = event.layer;
        const color = getNextColor();

        layer.setStyle({
          fillColor: color.fill,
          fillOpacity: 0.5,
          color: color.stroke,
          weight: 2,
        });

        drawnItems.addLayer(layer);
        calculatePolygonAreas();
      });

      // Handle editing of shapes
      mapRef.current.on(L.Draw.Event.EDITED, function () {
        calculatePolygonAreas();
      });

      // Handle deletion of shapes
      mapRef.current.on(L.Draw.Event.DELETED, function () {
        calculatePolygonAreas();
      });

      // Load existing GeoJSON if available
      if (geoJson) {
        addGeoJsonToMap(geoJson);
      }
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [lat, lng, geoJson]);

  return (
    <div className="w-full h-[80vh] flex flex-row overflow-hidden">
      <div className="relative w-full h-full">
        <div
          id="map"
          ref={mapContainerRef}
          className="w-full h-full border-2 border-gray-300 rounded-lg shadow-lg"
        >
          <div className="absolute top-16 right-4 z-[1000] flex flex-col space-y-2">
            <button
              onClick={toggleSatelliteView}
              className="rounded-full shadow-lg flex items-center justify-center font-bold bg-green-500 text-white p-2 hover:bg-green-600 transition-colors"
            >
              <FaSatellite size={30} />
            </button>
          </div>
        </div>
      </div>

      <div className="w-50 h-full flex flex-col overflow-hidden bg-gray-100 m-1">
        <div className="flex items-center justify-between p-2 border-b border-gray-200">
          <h3 className="text-lg font-bold">Select Areas</h3>
          <button
            onClick={() => {
              navigate(`/dashboard/projectdashboard/${projectId}`);
            }}
            className="flex items-center gap-2 text-white-600"
          >
            <FaEdit /> Edit Project
          </button>
        </div>

        <div className="flex-grow overflow-y-auto p-2">
          {polygonAreas.length === 0 ? (
            <p className="text-gray-500 text-center">
              Select Areas on the map to measure their areas.
            </p>
          ) : (
            <div className="space-y-2">
              {polygonAreas.map((polygon, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between bg-white p-2 rounded-md shadow-sm"
                >
                  <div className="flex items-center space-x-2">
                    <div
                      className="w-3 h-3 rounded-full"
                      style={{ backgroundColor: polygon.color.stroke }}
                    />
                    <span className="text-sm text-black">
                      {polygon.color.name} Area {index + 1}:{" "}
                      {polygon.area.toFixed(2)} m²
                    </span>
                  </div>
                  <button
                    onClick={() => removePolygon(index)}
                    className="text-white-500 hover:text-white-700"
                  >
                    <FaTrash />
                  </button>
                </div>
              ))}
            </div>
          )}
          <div className="p-2 border-t border-gray-200 flex justify-end items-center">
            <div className="flex items-center gap-2">
              <button
                onClick={() => {
                  navigate("/dashboard/projectdashboard");
                }}
                className="flex items-center gap-2 text-white hover:bg-red-700 transition-colors bg-red-500 p-2 rounded"
              >
                <FaTimes /> Cancel
              </button>
              <button
                onClick={saveMapDetails}
                className="flex items-center justify-center gap-2 font-bold bg-green-500 text-white p-2 rounded hover:bg-green-600 transition-colors"
              >
                <FaSave /> Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaMap;
