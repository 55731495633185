import React, { useState, useEffect } from "react";
import api from "../../axios/api";
import "../../assets/NewFiles/Css/Dashboard.css";
import { useNavigate } from "react-router-dom";
import CreateUser from "./CreateUser";
import { ToastContainer,toast } from "react-toastify";
import {
  Tabs,
  Tab,
  Box,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";
import { FaEye, FaEyeSlash, FaCheckCircle ,FaEdit ,FaBan} from "react-icons/fa";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import * as yup from 'yup';

// Define validation schema using Yup
const validationSchema = yup.object({
  full_name: yup
    .string()
    .required('Full Name is required'),
  userEmail: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  phoneNumber: yup
    .string()
    .required('Phone number is required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
});

const ViewAllUsers = () => {
  const [tabValue, setTabValue] = useState(0);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  //For filters and pagination
  const [companies, setCompanies] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [userType, setUserType] = useState("");
  const [selectedOrg, setSelectedOrg] = useState("");
  const rowsPerPage = 10;
  const [formErrors, setFormErrors] = useState({});
  const [touched, setTouched] = useState({});

   // Edit user state
   const [editDialogOpen, setEditDialogOpen] = useState(false);
   const [editingUser, setEditingUser] = useState(null);
   const [editFormData, setEditFormData] = useState({
     full_name: "",
     userEmail: "",
     phoneNumber:"",
   });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("/api/admin/getUsers");
        const allUsers = response.data.users.filter(
          (user) =>
            user.role_code !== "admin" && user.role_code !== "superadmin"
        );
        setUsers(allUsers);
        setFilteredUsers(allUsers);
        setCompanies(response.data.companies);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    let result = users;

    if (userType) {
      result = result.filter(
        (user) => user.role_code.toLowerCase() === userType.toLowerCase()
      );
    }

    if (selectedOrg) {
      result = result.filter((user) => user.company_name === selectedOrg);
    }

    setFilteredUsers(result);
    setPage(1);
  }, [userType, selectedOrg, users]);

  const handleToggleShowOnApp = async (userId) => {
    try {
      await api.put(`/api/admin/user/${userId}/toggle-show-on-app`);
      const updatedUsers = users.map((user) =>
        user.id === userId ? { ...user, show_on_app: !user.show_on_app } : user
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error toggling show_on_app:", error);
    }
  };

  const handleToggleVerification = async (userId) => {
    try {
      await api.put(`/api/admin/verifyUser`, { userId });
      const updatedUsers = users.map((user) =>
        user.id === userId ? { ...user, isVerified: !user.isVerified } : user
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error toggling show_on_app:", error);
    }
  };

  const handleToggleBan = async (userId) => {
    try {
      await api.put(`/api/admin/ban`, { userId });
      const updatedUsers = users.map((user) =>
        user.id === userId ? { ...user, is_status: !user.is_status } : user
      );
      setUsers(updatedUsers);
    } catch (error) {
      console.error("Error toggling show_on_app:", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Calculate pagination
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedUsers = filteredUsers.slice(startIndex, endIndex);
  const pageCount = Math.ceil(filteredUsers.length / rowsPerPage);

  //function for edit User
  const handleEditClick = (user) => {
    setEditingUser(user);
    setEditFormData({
      full_name: user.full_name,
      userEmail: user.userEmail,
      phoneNumber:user.phoneNumber,
    });
    setFormErrors({});
    setTouched({});
    setEditDialogOpen(true);
  };

  const handleEditClose = () => {
    setEditDialogOpen(false);
    setEditingUser(null);
    setEditFormData({
      full_name: "",
      userEmail: "",
      phoneNumber:"",
    });
    setFormErrors({});
    setTouched({});
  };

  const handleEditSubmit = async () => {
    try {
      await validationSchema.validate(editFormData, { abortEarly: false });
      await api.put(`/api/admin/EditUser/${editingUser.id}`, editFormData);
      
      // Update the users state with the edited data
      const updatedUsers = users.map((user) =>
        user.id === editingUser.id
          ? { ...user, full_name: editFormData.full_name, userEmail: editFormData.userEmail ,phoneNumber: editFormData.phoneNumber}
          : user
      );
      
      setUsers(updatedUsers);
      toast.success("User updated successfully!");
      handleEditClose();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        // Handle validation errors
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setFormErrors(newErrors);
        // Set all fields as touched to show errors
        setTouched({
          full_name: true,
          userEmail: true,
          phoneNumber: true,
        });
      } else {
        toast.error(`Failed to update user ${error.response.data.message}`);
      }
    }
  };

  return (
    <Box sx={{ width: "100%", margin: "auto", padding: 2 }}>
      <ToastContainer />
      <Box sx={{ marginBottom: 2, display: "flex", justifyContent: "center" }}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="User List" />
          <Tab label="Create User" />
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {tabValue === 0 && (
          <>
            <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>User Type</InputLabel>
                <Select
                  value={userType}
                  label="User Type"
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="ngo">NGO</MenuItem>
                  <MenuItem value="individual">Individual</MenuItem>
                  <MenuItem value="corporate">Corporate</MenuItem>
                  <MenuItem value="employee">Employee</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Organization</InputLabel>
                <Select
                  value={selectedOrg}
                  label="Organization"
                  onChange={(e) => setSelectedOrg(e.target.value)}
                >
                  <MenuItem value="">All</MenuItem>
                  {companies.map((company) => (
                    <MenuItem key={company.id} value={company.company_name}>
                      {company.company_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ backgroundColor: "#002F43" }}>
                  <TableRow>
                    <TableCell sx={{ color: "#fff" }}>Full Name</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Email</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Role</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Total Trees</TableCell>
                    <TableCell sx={{ color: "#fff" }}>
                      Geotagged Trees
                    </TableCell>
                    <TableCell sx={{ color: "#fff" }}>Verified Trees</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Deleted Trees</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Last Login</TableCell>
                    <TableCell sx={{ color: "#fff" }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedUsers.map((user) => (
                    <TableRow key={user.id}>
                      <TableCell>{user.full_name}</TableCell>
                      <TableCell style={{ display: "flex" }}>
                        {" "}
                        {user.isVerified ? (
                          <FaCheckCircle
                            color="green"
                            style={{ marginRight: "8px" }}
                            data-tooltip-id="verify"
                            data-tooltip-content="Verified User"
                          />
                        ) : (
                          <FaCheckCircle
                            color="red"
                            style={{ marginRight: "8px" }}
                            data-tooltip-id="verify"
                            data-tooltip-content="Unverified User"
                          />
                        )}
                        {user.userEmail}
                      </TableCell>
                      <Tooltip
                        id="verify"
                        className="custom-Tooltip"
                        style={{ fontSize: "10px", padding: "5px" }}
                      />
                      <TableCell>{user.role_code}</TableCell>
                      <TableCell>{user.totalTrees}</TableCell>
                      <TableCell>{user.geoLocatedTreesCount}</TableCell>
                      <TableCell>{user.verifiedTrees}</TableCell>
                      <TableCell>{user.deletedTrees}</TableCell>
                      {user.lastLogin ? (
  <TableCell>
    {new Date(user.lastLogin).toLocaleString()}
  </TableCell>
) : <TableCell style={{textAlign:'center'}}>
-
</TableCell>}


                      <TableCell style={{ display: "flex" }}>
                        {/* Edit Button */}
                        <button
                          onClick={() => handleEditClick(user)}
                          style={{
                            padding: "4px",
                            height: "25px",
                            border: "1px solid black",
                          }}
                          className="toggle-btn"
                        >
                          <FaEdit
                            data-tooltip-id="action-btn-edit"
                            data-tooltip-content="Edit User"
                          />
                        </button>
                        <button
                          onClick={() => handleToggleShowOnApp(user.id)}
                          style={{
                            padding: "4px",
                            height: "25px",
                            border: "1px solid black",
                          }}
                          className={`toggle-btn ${
                            user.show_on_app ? "hide-btn" : "show-btn"
                          }`}
                        >
                          {user.show_on_app ? (
                            <FaEyeSlash
                              data-tooltip-id="action-btn-show"
                              data-tooltip-content="Hide from App"
                            />
                          ) : (
                            <FaEye
                              data-tooltip-id="action-btn-show"
                              data-tooltip-content="Show on app"
                            />
                          )}
                        </button>
                        <Tooltip
                          id="action-btn-show"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />

                        <button
                          onClick={() => handleToggleVerification(user.id)}
                          style={{
                            padding: "4px",
                            height: "25px",
                            border: "1px solid black",
                          }}
                          className={`toggle-btn ${
                            user.isVerified ? "show-btn" : "hide-btn"
                          }`}
                        >
                          {user.isVerified ? (
                            <FaCheckCircle
                              data-tooltip-id="action-btn-verify"
                              data-tooltip-content="Unverify User"
                            />
                          ) : (
                            <FaCheckCircle
                              data-tooltip-id="action-btn-show"
                              data-tooltip-content="Verify User"
                            />
                          )}
                        </button>
                        <Tooltip
                          id="action-btn-verify"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />

                      <button
                          onClick={() => handleToggleBan(user.id)}
                          style={{
                            padding: "4px",
                            height: "25px",
                            border: "1px solid black",
                          }}
                          className={`toggle-btn ${
                            user.is_status ? "show-btn" : "hide-btn"
                          }`}
                        >
                          {user.is_status ? (
                            <FaBan
                              data-tooltip-id="action-btn-ban"
                              data-tooltip-content="Ban User"
                            />
                          ) : (
                            <FaBan
                              data-tooltip-id="action-btn-ban"
                              data-tooltip-content="Unban User"
                            />
                          )}
                        </button>
                        <Tooltip
                          id="action-btn-ban"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                color="primary"
                  className="pagination-container"
              />
            </Box>
          </>
        )}
        {tabValue === 1 && <CreateUser />}
      </Box>
      <Dialog open={editDialogOpen} onClose={handleEditClose}>
        <DialogTitle>Edit User</DialogTitle>
        <p style={{fontSize:'13px',padding:'0px 24px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 2 }}>
            <TextField
              label="Full Name*"
              value={editFormData.full_name}
              onChange={(e) =>
                setEditFormData({ ...editFormData, full_name: e.target.value })
              }
              error={touched.full_name && Boolean(formErrors.full_name)}
              helperText={touched.full_name && formErrors.full_name}
              fullWidth
              multiline={true}
              rows={1}
            />
            <TextField
              label="Email*"
              value={editFormData.userEmail}
              onChange={(e) =>
                setEditFormData({ ...editFormData, userEmail: e.target.value })
              }
              error={touched.userEmail && Boolean(formErrors.userEmail)}
              helperText={touched.userEmail && formErrors.userEmail}
              fullWidth
              multiline={true}
              rows={1}
            />
             <TextField
              label="phoneNumber*"
              value={editFormData.phoneNumber}
              onChange={(e) =>
                setEditFormData({ ...editFormData, phoneNumber: e.target.value })
              }
              error={touched.phoneNumber && Boolean(formErrors.phoneNumber)}
              helperText={touched.phoneNumber && formErrors.phoneNumber}
              fullWidth
              multiline={true}
              rows={1}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSubmit} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewAllUsers;
