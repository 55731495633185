import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  IconButton, 
  TextField, 
  Button,
  CircularProgress,
  Typography,
  Box
} from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import api from '../axios/api';

const ActivityPoints = () => {
  const [activityData, setActivityData] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPoints();
  }, []);

  const fetchPoints = async () => {
    try {
      const response = await api.get('/api/admin/getPoints');
      if (response.status !== 200) {
        throw new Error('Failed to fetch points');
      }
      setActivityData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };


  const handleEdit = (id) => {
    setEditingId(id);
  };

  const handlePointsChange = (id, newPoints) => {
    setActivityData(activityData.map(row => 
      row.id === id ? { ...row, points: newPoints } : row
    ));
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const response = await api.post('/api/admin/updatePoints', activityData);

      if (response.status !== 200) {
        throw new Error('Failed to update points');
      }

      // Refresh the data after successful update
      await fetchPoints();
      setEditingId(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Box className="p-4">
      <Typography variant="h4" gutterBottom>Reward Points Setting</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#002F43" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>Activity Code</TableCell>
              <TableCell sx={{ color: "#fff" }}>Points</TableCell>
              <TableCell sx={{ color: "#fff" }}>Updated Date</TableCell>
              <TableCell sx={{ color: "#fff" }}>Updated By</TableCell>
              <TableCell sx={{ color: "#fff" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activityData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.activity_code}</TableCell>
                <TableCell>
                  {editingId === row.id ? (
                    <TextField
                    value={row.points}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (/^\d*$/.test(value) && !value.includes('.'))) {
                        handlePointsChange(row.id, value);
                      }
                    }}
                    type="number"
                    size="small"
                  />
                  
                  ) : (
                    row.points
                  )}
                </TableCell>
                <TableCell>{row.updated_date}</TableCell>
                <TableCell>{row.updated_by}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(row.id)} size="small">
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className="mt-4 flex justify-end">
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleSave}
          disabled={loading}
          style={{paddingTop:'10px',paddingLeft:'25px',paddingRight:'25px',fontSize:'20px',fontWeight:'bold'}}
        >
          {loading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </Box>      
    </Box>
  );
};

export default ActivityPoints;