import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  Pagination,
  CircularProgress,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  FormControlLabel,
  Checkbox,
  FormGroup,
  InputAdornment,
} from "@mui/material";
import { MonetizationOn, Close, Search } from "@mui/icons-material";
import api from "../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const itemsPerPage = 15;

const Activity = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(1);
  const [totalClaimedPoints, setTotalClaimedPoints] = useState(0);
  const [activities, setActivities] = useState([]);
  const [historyActivities, setHistoryActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [claimingReward, setClaimingReward] = useState(false);
  const [userName, setUserName] = useState("");

  //Verify User Modal
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  // New state for filters
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredActivities, setFilteredActivities] = useState([]);
  const [filterType, setFilterType] = useState({
    user: false,
    plant: false,
    status: false,
  });

  // Apply filters
  useEffect(() => {
    const currentActivities = activeTab === 0 ? activities : historyActivities;
    let filtered = [...currentActivities];

    if (searchQuery) {
      if (filterType.user) {
        filtered = filtered.filter((activity) =>
          activity.full_name.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (filterType.plant) {
        filtered = filtered.filter((activity) =>
          activity.treeName.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (filterType.status) {
        filtered = filtered.filter((activity) => {
          const status = getActivityStatus(activity);
          return status.toLowerCase().includes(searchQuery.toLowerCase());
        });
      }
    }

    if (selectedDate) {
      filtered = filtered.filter((activity) => {
        const activityDate = new Date(activity.created_at).setHours(0, 0, 0, 0);
        const filterDate = new Date(selectedDate).setHours(0, 0, 0, 0);
        return activityDate === filterDate;
      });
    }

    setFilteredActivities(filtered);
    setPage(1);
  }, [
    filterType,
    searchQuery,
    selectedDate,
    activities,
    historyActivities,
    activeTab,
  ]);

  const handleFilterTypeChange = (event) => {
    const { name, checked } = event.target;
    setFilterType((prev) => ({
      user: name === "user" ? checked : false,
      plant: name === "plant" ? checked : false,
      status: name === "status" ? checked : false,
    }));
    setSearchQuery(""); // Clear search when changing filter type
  };

  const getActivityStatus = (activity) => {
    if (activity.is_claimed === "0") return "Unclaimed";
    if (activity.is_claimed === "1" && activity.claim_passed === "0")
      return "Claimed";
    if (activity.is_claimed === "1" && activity.claim_passed === "1")
      return "Collected";
    if (activity.is_claimed === "1" && activity.claim_passed === "-1")
      return "Rejected";
    return "Unknown";
  };

  const getSearchPlaceholder = () => {
    if (filterType.user) return "Search by user name...";
    if (filterType.plant) return "Search by plant name...";
    if (filterType.status)
      return "Search by status (e.g., Claimed, Collected, Rejected)...";
    return "Select a filter type...";
  };

  //Send Verification Mail Function
  const handleSendVerificationEmail = async () => {
    setIsSendingEmail(true);
    try {
      const response = await api.post("/api/auth/send-verification-email");
      if (response.data.success) {
        toast.success(
          "Verification email sent! Please check your inbox and verify your account before trying again."
        );
        setIsVerificationModalOpen(false);
      } else {
        toast.error("Failed to send verification email. Please try again.");
      }
    } catch (error) {
      toast.error("Failed to send verification email. Please try again.");
    } finally {
      setIsSendingEmail(false);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, [activeTab]);

  const displayValue = (value) => value || "-";

  const fetchActivities = async () => {
    setLoading(true);
    try {
      if (activeTab === 0) {
        const response = await api.get("/api/points/upload");
        setActivities(response.data.Points);
        setTotalClaimedPoints(response.data.totalPoints.collected_points);
        setUserName(response.data.totalPoints.full_name);
      } else {
        const response = await api.get("/api/points/history");
        setActivities(response.data.Points);
        setHistoryActivities(response.data.Points);
        setTotalClaimedPoints(response.data.totalPoints.collected_points);
        setUserName(response.data.totalPoints.full_name);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to load activities");
    } finally {
      setLoading(false);
    }
  };

  const getPaginatedData = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredActivities.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil(filteredActivities.length / itemsPerPage);

  const paginatedActivities = getPaginatedData();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    setPage(1);
  };

  const claimReward = async (id) => {
    setClaimingReward(true);
    try {
      const res = await api.post("/api/points/claim", { id });
      if (res.data.success) {
        toast.success("You've claimed your reward!");
      }
      if (res.data.Action === "Verify User") {
        setIsVerificationModalOpen(true);
        // console.log("han Kro Verify");
      }
    } catch (error) {
      toast.error("Oops! Failed to claim the reward.");
    } finally {
      fetchActivities();
      setClaimingReward(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", margin: "auto", padding: 2 }}>
      <Typography variant="h6">
        Current Balance: {totalClaimedPoints} points ({userName})
      </Typography>
      <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        centered
        sx={{ marginBottom: 2 }}
      >
        <Tab label="New Tree Registered" />
        <Tab label="Existing Tree Updates" />
      </Tabs>

      {/* Filters Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          marginBottom: 3,
        }}
      >
        {/* Filter Type Checkboxes */}
        <FormGroup row sx={{ gap: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType.user}
                onChange={handleFilterTypeChange}
                name="user"
              />
            }
            label="Filter by User"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "black", // Change this to your desired color
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType.plant}
                onChange={handleFilterTypeChange}
                name="plant"
              />
            }
            label="Filter by Plant"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "black", // Change this to your desired color
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={filterType.status}
                onChange={handleFilterTypeChange}
                name="status"
              />
            }
            label="Filter by Status"
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "black", // Change this to your desired color
              },
            }}
          />
        </FormGroup>

        {/* Search and Date Filters */}
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TextField
            label="Search"
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            disabled={
              !filterType.user && !filterType.plant && !filterType.status
            }
            placeholder={getSearchPlaceholder()}
            fullWidth
            multiline={true}
                rows={1}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />

          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select Date"
            isClearable
            
            customInput={
              <TextField
                label="Activity Date"
                variant="outlined"
                sx={{ minWidth: 200 }}
                multiline={true}
                rows={1}
              />
            }
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      ></Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#002F43" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>Activity</TableCell>
              <TableCell sx={{ color: "#fff" }}>User</TableCell>
              <TableCell sx={{ color: "#fff" }}>Plant</TableCell>
              <TableCell sx={{ color: "#fff" }}>Activity Date/Time</TableCell>
              <TableCell sx={{ color: "#fff" }}>Collected Points</TableCell>
              <TableCell sx={{ color: "#fff" }}>Rejection Reason</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedActivities.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Typography>
                    {row.activity_code}({row.activity_points})
                  </Typography>
                </TableCell>
                <TableCell>{row.full_name}</TableCell>
                <TableCell>{row.treeName}</TableCell>
                <TableCell>
                  <Typography variant="caption" color="text.secondary">
                    {row.created_at}
                  </Typography>
                </TableCell>
                <TableCell>
                  {row.is_claimed === "0" ? (
                    <>
                      {" "}
                      <Button
                        variant="outlined"
                        size="small"
                        startIcon={
                          claimingReward ? (
                            <CircularProgress size={20} />
                          ) : (
                            <MonetizationOn style={{ color: "green" }} />
                          )
                        }
                        onClick={() => claimReward(row.ap_id)}
                        disabled={claimingReward}
                        sx={{
                          backgroundColor: "rgba(255, 235, 59, 0.1)",
                          color: "warning.dark",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "rgba(255, 235, 59, 0.2)",
                          },
                        }}
                      >
                        {claimingReward
                          ? "Claiming..."
                          : `${row.activity_points} - Claim`}
                      </Button>
                    </>
                  ) : row.is_claimed === "1" && row.claim_passed === "0" ? (
                    <Typography color="text.primary">
                      {row.activity_points} Claimed
                    </Typography>
                  ) : row.is_claimed === "1" && row.claim_passed === "1" ? (
                    <Typography color="text.primary">
                      {row.activity_points} Collected
                    </Typography>
                  ) : row.is_claimed === "1" && row.claim_passed === "-1" ? (
                    <Typography style={{ color: "red" }}>
                      0 - Claim Rejected
                    </Typography>
                  ) : (
                    <></>
                  )}
                </TableCell>
                <TableCell
                  style={{ color: row.rejection_reason ? "red" : "inherit" }}
                >
                  {displayValue(row.rejection_reason)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color="primary"
          className="pagination-container"
            />
        </Box>
      )}

      <ToastContainer closeOnClick />
      <Dialog
        open={isVerificationModalOpen}
        onClose={() => setIsVerificationModalOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          Email Verification Required
          <IconButton
            aria-label="close"
            onClick={() => setIsVerificationModalOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ mt: 2 }}>
            Your email needs to be verified before you can claim rewards. Click
            the button below to receive a verification email.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant="contained"
            onClick={handleSendVerificationEmail}
            disabled={isSendingEmail}
            startIcon={
              isSendingEmail ? (
                <CircularProgress size={20} color="inherit" />
              ) : null
            }
            sx={{
              backgroundColor: "#002F43",
              "&:hover": {
                backgroundColor: "#004c6d",
              },
            }}
          >
            {isSendingEmail ? "Sending..." : "Send Verification Email"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Activity;
