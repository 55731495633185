import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import api from '../axios/api';

const validationSchema = yup.object({
  oldPassword: yup.string().required('Old password is required'),
  newPassword: yup
    .string()
    .required('New password is required')
    .test('not-same', 'New password must be different from old password', function (value) {
      return value !== this.parent.oldPassword;
    }),
  confirmPassword: yup
    .string()
    .required('Confirm password is required')
    .oneOf([yup.ref('newPassword')], 'Passwords must match'),
});

const ChangePassword = () => {
  const [showPasswords, setShowPasswords] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleTogglePasswordVisibility = (field) => {
    setShowPasswords((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await api.post('/api/auth/change-password', {
        headers: {
          'Content-Type': 'application/json',
        },
        formData: {
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        },
      });

      resetForm();
      toast.success('Password changed successfully!');
    } catch (error) {
      console.error('Error changing password:', error);
      toast.error(`Failed to change password, ${error.response.data.message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const textFieldSx = {
    '& .MuiOutlinedInput-root': {
      height: '45px', // Reduced height
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 13px) scale(1)', // Adjusted label position
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)', // Adjusted shrunk label position
    },
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit} sx={{ padding:'50px', mx: 'auto' ,width:'500px',borderRadius:'10px',backgroundColor:'white'}}>
        <h2 style={{textAlign:'center'}}>Change Password</h2>
      <ToastContainer position="top-right" autoClose={3000}/>
      <Stack spacing={2} style={{alignItems:'center'}}> {/* Reduced spacing from 3 to 2 */}
        <TextField
          fullWidth
          id="oldPassword"
          name="oldPassword"
          label="Old Password"
          style={{width:'400px'}}
          type={showPasswords.oldPassword ? 'text' : 'password'}
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          sx={textFieldSx}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleTogglePasswordVisibility('oldPassword')}
                  edge="end"
                  size="small" // Reduced button size
                >
                  {showPasswords.oldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          id="newPassword"
          name="newPassword"
          label="New Password"
          style={{width:'400px'}}
          type={showPasswords.newPassword ? 'text' : 'password'}
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          sx={textFieldSx}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleTogglePasswordVisibility('newPassword')}
                  edge="end"
                  size="small"
                >
                  {showPasswords.newPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          id="confirmPassword"
          name="confirmPassword"
          label="Confirm New Password"
                    style={{width:'400px'}}
          type={showPasswords.confirmPassword ? 'text' : 'password'}
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
          helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
          sx={textFieldSx}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleTogglePasswordVisibility('confirmPassword')}
                  edge="end"
                  size="small"
                >
                  {showPasswords.confirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => formik.resetForm()}
            disabled={formik.isSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={formik.isSubmitting}
            startIcon={formik.isSubmitting && <ClipLoader size={20} color="#fff" />}
          >
            {formik.isSubmitting ? 'Saving...' : 'Save Changes'}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ChangePassword;