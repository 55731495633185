import React, { useState, useEffect,useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Close, CloudUpload,Download } from "@mui/icons-material";
import { toast } from "react-toastify";
import api from "../../axios/api";
import * as XLSX from "xlsx";
import { GlobalContext } from '../../contexts/GlobalContext';

const BulkUserModal = ({ open, onClose }) => {
  const { role,userId } = useContext(GlobalContext);
  const [userType, setUserType] = useState(role === 'ngo' || role === 'corporate' ? 'employee' : 'individual');
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successfulUsers, setSuccessfulUsers] = useState(0);
  const [failedUsers, setFailedUsers] = useState(0);
 

  useEffect(() => {
    if (open && userType === "employee") {
      fetchOrganizations();
    }
  }, [open, userType]);

  const fetchOrganizations = async () => {
    try {
      const response = await api.get("/api/user/organizations");
      const orgs = response.data[0];
      
      // If role is ngo or corporate, filter and auto-select organization
      if (role === 'ngo' || role === 'corporate') {
        const userOrg = orgs.find(org => org.created_by_user === userId);
        if (userOrg) {
          setSelectedOrganization(userOrg.id);
          setOrganizations([userOrg]); // Only show user's organization
        }
      } else {
        setOrganizations(orgs);
      }

    } catch (error) {
      toast.error("Failed to fetch organizations");
      console.error("Error fetching organizations:", error);
    }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel" ||
        file.type === "text/csv"
      ) {
        setSelectedFile(file);
      } else {
        toast.error("Please upload only Excel or CSV files");
        event.target.value = null;
      }
    }
  };

  const handleSubmit = async () => {
    if (!selectedFile) {
      toast.error("Please select a file to upload");
      return;
    }

    if (userType === "employee" && !selectedOrganization) {
      toast.error("Please select an organization");
      return;
    }

    setIsUploading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("userType", userType);
    if (userType === "employee") {
      formData.append("organizationId", selectedOrganization);
    }

    try {
      const response = await api.post("/api/bulk/createusers", formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(progress);
        },
      });

      if (response.data.success && response.data.processedUsers?.length > 0) {
        toast.success("Users created successfully");
        const { successful, failed } = response.data;
        setSuccessfulUsers(successful);
        setFailedUsers(failed);
        // Create workbook
        const workbook = XLSX.utils.book_new();

        // Convert processed users to worksheet
        const worksheet = XLSX.utils.json_to_sheet(
          response.data.processedUsers
        );

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Users Report");

        // Generate Excel file
        XLSX.writeFile(workbook, `users-report-${Date.now()}.xlsx`);

        return {
          success: true,
          message: `Successfully created ${response.data.successful} users. Download started.`,
          data: response.data,
        };

        onClose();
      } else {
        toast.error(response.data.message || "Failed to create users");
      }
    } catch (error) {
      toast.error(
        "Error creating users: " +
          (error.response?.data?.message || "Unknown error")
      );
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
      setSelectedFile(null);
    }
  };

  const handleDownloadSample = () => {
    try {
      // Create sample data
      const sampleData = [
        {
          Email: "example@email.com",
          Phone: "1234567890",
          Name: "John Doe",
          Password: "Abcxyz"
        }
      ];

      // Create workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(sampleData);

      // Set column widths
      const columnWidths = [
        { wch: 25 }, // Email
        { wch: 15 }, // Phone
        { wch: 20 }  // Name
      ];
      worksheet['!cols'] = columnWidths;

      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sample Template");

      // Generate and download Excel file
      XLSX.writeFile(workbook, "bulk-users-template.xlsx");

      toast.success("Sample template downloaded successfully");
    } catch (error) {
      toast.error("Failed to download sample template");
      console.error("Error downloading sample:", error);
    }
  };

  const handleCancel = () => {
    // Clear all fields and reset state variables to their initial state
    setUserType(role === 'ngo' || role === 'corporate' ? 'employee' : 'individual');
    setSelectedOrganization("");
    setSelectedFile(null);
    setIsUploading(false);
    setUploadProgress(0);
    setSuccessfulUsers(0);
    setFailedUsers(0);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={!isUploading ? handleCancel : undefined}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        Bulk Create Users
        {!isUploading && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
  startIcon={<Download />}
  onClick={handleDownloadSample}
  variant="outlined"
  size="small"
  sx={{
    mb: 2,
    backgroundColor: "#002F43",
    borderColor: "#002F43",
    color: "#FFFFFF", // Set text color to white for contrast
    '&:hover': {
      backgroundColor: "#004b6a", // Slightly lighter shade for hover
      borderColor: "#004b6a",
    }
  }}
>
              Download Sample Template
            </Button>
          </Box>
          <FormControl fullWidth>
            <InputLabel>User Type</InputLabel>
            <Select
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
              disabled={isUploading || role === 'ngo' || role === 'corporate'}
            >
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="employee">Employee</MenuItem>
            </Select>
          </FormControl>

          {userType === "employee" && (
            <FormControl fullWidth>
              <InputLabel>Organization</InputLabel>
              <Select
                value={selectedOrganization}
                onChange={(e) => setSelectedOrganization(e.target.value)}
                disabled={isUploading || role === 'ngo' || role === 'corporate'}
              >
                {organizations.map((org) => (
                  <MenuItem key={org.id} value={org.id}>
                    {org.company_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Box
            sx={{
              textAlign: "center",
              p: 3,
              border: "2px dashed #ccc",
              borderRadius: 2,
            }}
          >
            <input
              accept=".xlsx,.xls,.csv"
              style={{ display: "none" }}
              id="file-upload"
              type="file"
              onChange={handleFileSelect}
              disabled={isUploading}
            />
            <label htmlFor="file-upload">
              <Button
                component="span"
                startIcon={<CloudUpload />}
                variant="contained"
                disabled={isUploading}
              >
                Upload Excel/CSV
              </Button>
            </label>
            {selectedFile && (
              <Typography sx={{ mt: 2 }}>
                Selected file: {selectedFile.name}
              </Typography>
            )}
             
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: 'right', mr: 2,gap:'2' }}>
          {successfulUsers > 0 && (
            <Typography variant="body2" color="success.main" component="span"  sx={{ mr: 2}} style={{ fontWeight: 'bold' }}>
              {successfulUsers} Success
            </Typography>
          )}
          {failedUsers > 0 && (
            <Typography variant="body2" color="error.main" component="span" sx={{ mr: 2}} style={{fontWeight: 'bold' }}>
              {failedUsers} Failed
            </Typography>
          )}
           {(failedUsers > 0 || successfulUsers > 0) && <Typography variant="body2" color="black" component="span"  sx={{ fontWeight: 'bold' }}>
             For Detailed Status Check Downloaded xlsx file.
            </Typography>
            }
        </Box>
          {isUploading && (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress variant="determinate" value={uploadProgress} />
              <Typography sx={{ mt: 1 }}>
                Uploading and processing... {uploadProgress}%
              </Typography>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={handleCancel} disabled={isUploading} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isUploading || !selectedFile}
          sx={{
            backgroundColor: "#002F43",
            "&:hover": { backgroundColor: "#004c6d" },
          }}
        >
          {isUploading ? "Processing..." : "Create Users"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkUserModal;
