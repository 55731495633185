import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/NewFiles/Css/Upload.css";
import TreeUploadForm from "./TreeUploadForm";
import TreeHistory from "./TreeHistory";

const UploadComponent = () => {
  const [selectedForm, setSelectedForm] = useState("treeForm");
  
  return (
    <div>
      <div>
        <section className="bg-image pt-40 pb-40">
          <div className="container">
            <h2
              className="wow fadeInUp"
              data-wow-duration="1.2s"
              data-wow-delay=".2s"
            >
              Upload Tree
            </h2>
          </div>
        </section>

        <section className="contact pt-60 pb-60">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="content-upload  bg-image-upload">
                  <h2>Plant Upload Procedure</h2>
                  <p>
                    Kindly provide clear picture and geo location detail of
                    plant images for higher accuracy. Watch the tutorial video
                    for more information.
                  </p>
                  <ul className="upload">
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      On your device, enable the location service.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Verify that the camera has access to the location service.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Take pictures of plants with your phone.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Check the image file details in your phone's gallery to
                      see if the location is present in the captured images.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      For fast processing, copy the original photos to the
                      computer. (Optional)
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Upload plant photos using the file uploader provided on
                      this page.
                    </li>
                    <li>
                      <img
                        className="pe-2"
                        src="/images/icon/leaf.png"
                        alt="icon"
                        style={{ display: "inline" }}
                      />
                      Our Team will Verify the image in First Come First Serve
                      manner and will approve it for public listing
                    </li>
                  </ul>

                  <div className="col-lg-12">
                    <div className="container-youtube upload-page">
                      <iframe
                        title="ytvideo"
                        className="responsive-iframe"
                        src="https://www.youtube.com/embed/AkbGz3CYvqE?&loop=1&autoplay=0&controls=0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
  <div
    className="form-area"
    style={{
      backgroundColor: selectedForm === "treeForm" ? "#cdeac0" : "#87CEEB", // Apply different background colors
      padding: "20px", // Optional: Add padding to the form area
      border:'5px solid #3B8752'
    }}
  >
    <div style={{ marginBottom: "20px" }}>
      <button
        onClick={() => setSelectedForm("treeForm")}
        style={{
          color: "#335145",
          fontWeight: "bold",
          marginRight: "10px",
          backgroundColor: selectedForm === "treeForm" ? "#cdeac0" : "#cccccc",
          border:'2px solid black'
        }}
      >
        New Tree
      </button>
      <button
        onClick={() => setSelectedForm("anotherForm")}
        style={{
          color: "#335145",
          fontWeight: "bold",
          backgroundColor: selectedForm === "anotherForm" ? "#87CEEB" : "#cccccc",
          border:'2px solid black'
        }}
      >
        Existing Tree
      </button>
    </div>

    {/* Conditional rendering of forms */}
    {selectedForm === "treeForm" && <TreeUploadForm />}
    {selectedForm === "anotherForm" && <TreeHistory />}
  </div>
</div>
</div>
</div>
        </section>
      </div>
    </div>
  );
};

export default UploadComponent;
