import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import contactData from '../../assets/contactData';
import api from '../../axios/api';

// Validation schema
const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  contactNumber: yup.string().required("Contact number is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  organization: yup.string(),
  subject: yup.string().notOneOf(["Select Subject"], "Please select a subject").required("Subject is required"),
  message: yup.string().required("Message is required"),
});

const Form = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const { register, handleSubmit, formState: { errors }, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      subject: "Select Subject *",
    },
  });

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleOptionSelect = (option, onChange) => {
    onChange(option);
    setDropdownOpen(false);
  };

  const onSubmit = async (data) => {
    try {
      const response = await api.post('/api/user/sendContactForm', {
        ...data,
        contactMailEncoraa: contactData.email.title,
      });

      if (response.status === 200) {
        toast.success('Your message has been sent successfully.');
        reset();
      } else {
        toast.error('Failed to send your message. Please try again.');
      }
    } catch (error) {
      console.error('Error sending the contact form:', error);
      toast.error('An error occurred while sending your message. Please try again.');
    }
  };

  return (
    <div>
      <ToastContainer />
      <section className="bg-image pt-40 pb-40">
        <div className="container">
          <h2 className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
            Contact Us
          </h2>
        </div>
      </section>

      <section className="contact pt-60 pb-60">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6">
              <div className="content bg-image">
                <h2>
                  Have something in mind? <br />
                  Let's talk.
                </h2>
                <div className="arry">
                  <img src="/images/icon/arry.png" alt="" />
                </div>
                <ul>
                  <li>
                    <a href={contactData.address.href} target="_blank" rel="noreferrer">
                      <i className="fa-solid fa-location-dot"></i> {contactData.address.title}
                    </a>
                  </li>
                  <li>
                    <a href={contactData.phone.href}>
                      <i className="fa-solid fa-phone-volume"></i>{contactData.phone.title}
                    </a>
                  </li>
                  <li>
                    <a href={contactData.email.href} target="_blank" rel="noreferrer">
                      <i className="fa-solid fa-envelope"></i>{contactData.email.title}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-area">
                <form onSubmit={handleSubmit(onSubmit)}>
                <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
                <br/>
                {errors.name && <p className="error-message">{errors.name.message}</p>}
                  <input
                    type="text"
                    placeholder="Name *"
                    {...register("name")}
                  />
                 
                  {errors.contactNumber && <p className="error-message">{errors.contactNumber.message}</p>}
                  <input
                    type="text"
                    placeholder="Contact Number *"
                    {...register("contactNumber")}
                  />
                 
                  {errors.email && <p className="error-message">{errors.email.message}</p>}
                  <input
                    type="email"
                    placeholder="Email *"
                    {...register("email")}
                  />
                  

                  <input
                    type="text"
                    placeholder="Organization (optional)"
                    {...register("organization")}
                  />
{errors.subject && <p className="error-message">{errors.subject.message}</p>}
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <div className={`nice-select ${dropdownOpen ? 'open' : ''}`} onClick={toggleDropdown}>
                        <span className="current">{value}</span>
                        <ul className="list">
                          {["Select Subject", "Account", "Service", "Pricing", "Support"].map((option) => (
                            <li
                              key={option}
                              data-value={option}
                              className={`option ${option === value ? 'selected focus' : ''}`}
                              onClick={() => handleOptionSelect(option, onChange)}
                            >
                              {option}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  />
                  

                  <textarea
                    name="message"
                    placeholder="Message *"
                    {...register("message")}
                  ></textarea>
                  {errors.message && <p className="error-message">{errors.message.message}</p>}

                  <button type="submit">
                    Submit
                    <i className="fa-solid fa-arrow-right-long" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Form;