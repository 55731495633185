import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaUser } from 'react-icons/fa';
import api from '../../axios/api'
import { toast } from 'react-toastify';
import { FaWallet } from 'react-icons/fa';

import '../../assets/css/bootstrap.min.css';
import '../../assets/css/all.min.css';
import '../../assets/css/swiper-bundle.min.css';
import '../../assets/css/magnific-popup.css';
import '../../assets/css/animate.css';
import '../../assets/css/nice-select.css';
import '../../assets/css/style.css';

import { GlobalContext } from '../../contexts/GlobalContext';

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const { profilePicture,setProfilePicture  , setRole,isLoggedIn, setLoggedIn ,setUserId,setRedeemedPoints,collectedPoints,setCollectedPoints} = useContext(GlobalContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const isActive = (path) => {
    if (path === '/') {
      return window.location.pathname === '/';
    }
    return window.location.pathname.startsWith(path);
  };
  
  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };


  const handleLinkClick = () => {
    setMenuActive(false);
    setProfileMenuOpen(false);
  };

  const handleProfileMenuToggle = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  const logout = async () => {
    try {
      await api.post('/api/auth/signout');
      localStorage.removeItem('token');
      setLoggedIn(false);
    } catch (error) {
      console.error('Logout failed:', error.response ? error.response.data : error.message);
      toast.error('Logout failed. Please try again.');
    }finally{
        navigate('/'); 
        toast.success('Logout successful!');
    }
  };

  const profileIconStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    cursor: 'pointer',
  };

  const profileMenuStyle = {
    position: 'absolute',
    right: 0,
    background: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: 1000,
  };

  const profileMenuItemStyle = {
    padding: '10px 20px',
    cursor: 'pointer',
    borderBottom: '1px solid #ddd',
  };


  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await api.get('/api/auth/check');
        if (response.data.loggedIn) {
          setLoggedIn(true);
          setProfilePicture(response.data.profilePicture);
          setRole(response.data.role);
          setUserId(response.data.id);
          setRedeemedPoints(response.data.redeemed_points);
          setCollectedPoints(response.data.collected_points);
        } else {
          setLoggedIn(false);
          setRole('');
          setUserId('');
        }
      } catch (error) {
        console.error('Error checking auth status:', error);
        setLoggedIn(false);
        setRole('');
        setUserId('');
      }
    };

    checkAuthStatus();
  }, [setLoggedIn,setProfilePicture,setRole]);

  return (
    <header className="header">
      <div className="header-section">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo-menu">
              <Link to="/" onClick={handleLinkClick}><img src={'/images/encoraa-logo-d.png'} alt="logo" /></Link>
            </div>
            <div className="header-bar d-xl-none" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>

            <ul className={`main-menu ${menuActive ? 'active' : ''}`}>
              <li className={`header-menu-bg ${isActive('/') ? 'active' : ''}`}>
                <Link to="/" onClick={handleLinkClick}>Home</Link>
              </li>
              <li className={`header-menu-bg ${isActive('/TreeRegistry') ? 'active' : ''}`}>
                <Link to="/TreeRegistry" onClick={handleLinkClick}>Tree Metric</Link>
              </li>
              <li className={`header-menu-bg ${isActive('/map') ? 'active' : ''}`}>
                <Link to="/map" onClick={handleLinkClick}>Map</Link>
              </li>
              <li className={`header-menu-bg ${isActive('/trees') || isActive('/plantprofile') ?'active' : ''}`}>
                <Link to="/trees" onClick={handleLinkClick}>Trees</Link>
              </li>
              <li className={`header-menu-bg ${isActive('/upload') ? 'active' : ''}`}>
                <Link to="/upload" onClick={handleLinkClick}>Upload</Link>
              </li>
              <li className={`header-menu-bg ${pathname.includes('/statistics') ? 'active' : ''}`}>
                <Link to="/statistics/chart" onClick={handleLinkClick}>Statistics</Link>
              </li>
              <li className={`header-menu-bg ${isActive('/clients') || isActive('/clientprofile') ? 'active' : ''}`}>
                <Link to="/clients" onClick={handleLinkClick}>Clients</Link>
              </li>
              <li className={`header-menu-bg ${isActive('/projects') || isActive('/project') ? 'active' : ''}`}>
                <Link to="/projects" onClick={handleLinkClick}>Projects</Link>
              </li>
              <li className={`header-menu-bg ${isActive('/Leaders') || isActive('/userprofile') ? 'active' : ''}`}>
                <Link to="/Leaders" onClick={handleLinkClick}>Leaders</Link>
              </li>
              <li className={`header-menu-bg ${isActive('/contact') ? 'active' : ''}`}>
                <Link to="/contact" onClick={handleLinkClick}>Contact Us</Link>
              </li>
              {isLoggedIn && (
                <li className={`header-menu-bg ${isActive('/dashboard') ? 'active' : ''}`}>
                  <Link to="/dashboard/stats" onClick={handleLinkClick}>Dashboard</Link>
                </li>
              )}
              <li className="header-menu-bg profile-menu" style={{ position: 'relative' }}>
                <div className="profile-icon" onClick={handleProfileMenuToggle} style={profileIconStyle}>
                  {isLoggedIn ? (
                    <img src={profilePicture} alt="Profile" style={profileIconStyle} />
                  ) : (
                    <FaUser style={profileIconStyle} />
                  )}
                </div>
                {profileMenuOpen && (
                  <ul className="profile-sub-menu" style={profileMenuStyle}>
                    {isLoggedIn ? (
                      <>
                        <li style={profileMenuItemStyle} className={`${isActive('/profile') ? 'active' : ''}`}><Link to="/dashboard/profile" onClick={handleLinkClick}>Profile</Link></li>
                        <li style={profileMenuItemStyle} className={`${isActive('/activity') ? 'active' : ''}`}>
                        <Link
  to="/dashboard/activity"
  style={{
    padding:'5px',
    display: "flex",
    fontSize: "15px",
  }}
>
  <FaWallet size={12} color="white" style={{ marginRight: "5px",marginTop:'7px',marginLeft:'5px' }} />
  <span style={{ color: 'white' }}>
  {collectedPoints > 9000 ? `${Math.floor(collectedPoints / 1000)}K` : collectedPoints}
</span>
  {/* <span style={{color:'white'}}>{collectedPoints}</span> */}
  {/* <FaDollarSign size={20} color="gold" style={{ marginLeft: "0px",marginTop:'3px' ,marginRight:'0px'}} /> */}
</Link>

                        </li>
                        <li style={profileMenuItemStyle} className={`${isActive('/claim') ? 'active' : ''}`}><Link to="/dashboard/redeem" >Redeem</Link></li>
                        <li style={profileMenuItemStyle} className={`${isActive('/login') ? 'active' : ''}`}><Link to="/" onClick={logout}>Logout</Link></li>
                      </>
                    ) : (<>
                      <li style={profileMenuItemStyle} className={`${isActive('/login') ? 'active' : ''}`}><Link to="/login" onClick={handleLinkClick}>Login</Link></li>
                       <li style={profileMenuItemStyle} className={`${isActive('/login') ? 'active' : ''}`}><Link to="/signup" onClick={handleLinkClick}>SignUp</Link></li>
                       </>
                    )}
                  </ul>
                )}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
