import React, { useState } from 'react';
import api from '../../axios/api';
import { useEffect } from 'react';
import ReactLoading from "react-loading";
import { FaCalendarPlus, FaCheckCircle, FaClock, FaPagelines, FaTree } from "react-icons/fa";
import { FaTimesCircle } from 'react-icons/fa';
import "../../assets/NewFiles/Css/profilePosts.css";
import { FaUserAlt,FaCalendarAlt,FaMapMarkerAlt } from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ProfilePosts = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    treeName: false,
    uploadedBy: false,
    location: false,
  });
    const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [stats, setStats] = useState({});

  useEffect(() => {
    const checkUserAuth = async () => {
      try {
        const response = await api.get('/api/user/userdetails');

        if (response.data.isLoggedIn) {
          const { posts } = response.data.user;
          setPosts(posts);
        } 
      } catch (error) {
        console.error('Error checking user auth', error);
      } finally {
        setLoading(false);
      }
    };

    checkUserAuth();
  }, []);
  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters({
      ...filters,
      [name]: checked,
    });
  };

  const filteredData = posts.filter((item) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      (!filters.treeName ||
        item.treeName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.uploadedBy ||
        item.userName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.location ||
        item.address.toLowerCase().includes(lowerSearchTerm))
    );
  });

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <ReactLoading type="spin" color="#8BC34A" height={50} width={50} />
      </div>
    );
  }

  if (!posts.length) {
    return (
      <div style={styles.loadingContainer}>
        <p>No posts available.</p>
      </div>
    );
  }

  return (
    <div className="search-component">
      <div className="search-container">
        <div className="search-row">
          <div className="search-col">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              className="search-input"
            />
          </div>
          <div className="search-col">
            <div className="filters">
              <div className="checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    name="treeName"
                    checked={filters.treeName}
                    onChange={handleCheckboxChange}
                    className="checkbox-input"
                  />
                  Tree Name
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="location"
                    checked={filters.location}
                    onChange={handleCheckboxChange}
                    className="checkbox-input"
                  />
                  Location
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>


      <section className="card-section">
        <div className="card-container">
          <div className="card-row">
            {filteredData.map((item, index) => (
              <div key={index} className="card-col">
                <div className="blogCardItem">
                  <div className="blogCardImage">
                    <img
                      src={item.treeImageUrl}
                      alt={item.treeName}
                      className="card-image"
                    />
                  </div>

                  <span className="card-plantName">
                    
                  <span className="search-verified">
                  {
  item.isVerified === 1 ? (
    <FaCheckCircle color="green" />
  ) : item.isVerified === 2 ? (
    <FaTimesCircle style={{ color: 'red' }} />
  ) : (
    <FaCheckCircle color="yellow" />
  )
}

                      {/* {item.isVerified ? <FaCheckCircle color="green" /> : <FaCheckCircle color="yellow" />} */}
                    </span>
                    &nbsp;&nbsp;{item.treeName}
                  </span>

                  <div className="blogCardDetails">
                  <span className="card-detail" data-tooltip-id="View-More-plantName" data-tooltip-content="Tree Id">
                    <FaTree style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {item.tree_id}
                    <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
                    </span>
                    <span className="card-detail" data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded By">
                    <FaUserAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {item.userName}
                    <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
                    </span>
                    <span className="card-detail" data-tooltip-id="View-More-plantName" data-tooltip-content="Planted On">
                    <FaCalendarPlus style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {new Date(item.plantedOn).toDateString()}
                    <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
                    </span>
                    <span className="card-detail" data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded On">
                    <FaCalendarAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {new Date(item.createdAt).toDateString()}
                    <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
                    </span>
                    <span className="card-detail" data-tooltip-id="View-More-plantName" data-tooltip-content="Species">
                    <FaPagelines style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {item.speciesLabel}
                    <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
                    </span>
                    <span className="card-detail" data-tooltip-id="View-More-plantName" data-tooltip-content="Age">
                    <FaClock style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {item.age}
                    <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
                    </span>
                    <span className="card-detail" data-tooltip-id="View-More-plantName" data-tooltip-content="Location">
                    <FaMapMarkerAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {item.address}
                    <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
                    </span>
                  </div>
                  <span style={{color:'red',fontWeight:'bold',marginLeft:'5px'}}>
                    {item.rejection_reason}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProfilePosts;

const styles = {
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
};