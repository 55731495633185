// contactData.js
const contactData = {
  address: {
    title: "407 Payal Complex, B/s VSE, Sayajigunj, Vadodara-390005",
    href: "https://www.google.com/maps/place/Imageio+Knowledge+Solutions+Pvt.+Ltd/@22.3085221,73.1858956,17z/data=!3m1!4b1!4m6!3m5!1s0x395fcf36a21bea7d:0x589c449fe52202a2!8m2!3d22.3085221!4d73.1858956!16s%2Fg%2F11h6xr8txj?entry=ttu"
  },
  phone: {
    title: "+91 99248 04021",
    href: "tel:+91 99248 04021"
  },
  email: {
    title: "info@encoraa.com",
    href: "mailto:company.info@mail.com"
  }
};

export default contactData;