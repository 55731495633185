import React, { useEffect, useState ,useContext} from 'react';
import api from '../../axios/api';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { FaCheckCircle,FaUsers,FaDollarSign,FaWallet,FaGift,FaTree,FaMapMarkerAlt ,FaUserCircle,FaUserTimes} from 'react-icons/fa';
import '../../assets/NewFiles/Css/Stats.css';
import { GlobalContext } from '../../contexts/GlobalContext';

const StatsComponent = () => {
  const [stats, setStats] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [points,setPoints]=useState("");
  const { role } = useContext(GlobalContext);
  const [apiuserStatsData,setApiUserStatsData]=useState([]);
  const [apitreeStatsData,setApiTreeStatsData]=useState([]);
  const [apirewardStatsData,setApiRewardsStatsData]=useState([]);

  useEffect(() => {
    const fetchStats = async () => {
        try {
          if(role==='admin'){
            // First, try to fetch admin stats
            const adminResponse = await api.get('/api/admin/stats');
            if (adminResponse.data.stats) {
              setStats(adminResponse.data.stats[0]);
              setPoints(adminResponse.data.points[0]);
              setIsAdmin(true);
              setApiUserStatsData(adminResponse.data.UserStats);
              setApiTreeStatsData(adminResponse.data.TreeStats);
              setApiRewardsStatsData(adminResponse.data.RewardStats);
            }
                 }else{
                   const userResponse = await api.get('/api/user/stats');
                   if (userResponse.data.user) {
                     const userData = userResponse.data.user.user;
                     setStats({
                       collectedPoints:userData.collected_points,
                       redeemedPoints:userData.redeemed_points,
                       totalUsers: 1,
                       verifiedUsers: userData.isVerified ? 1 : 0,
                       totalTrees: userData.totalTrees,
                       geoLocatedTreesCount: userData.geoLocatedTreesCount,
                       verifiedTrees: userData.verifiedTrees,
                       deletedTrees: userData.deletedTrees,
                       redemptionRequested:userData.redeemption_requested_point,
                     });
                     setApiTreeStatsData(userResponse.data.user.treeStats);
                     setApiRewardsStatsData(userResponse.data.user.RewardStats);
                   }
                   
                 }
                 
         
        } catch (userError) {
          console.error('Error fetching user stats:', userError);
        }
    };

    fetchStats();
  }, []);

  if (!stats) {
    return <div>Loading...</div>;
  }

 // Define the months dynamically if needed or keep them fixed
const months = apitreeStatsData.map(stat => stat.registration_month) || ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'];

  const userStatsData = {
    labels: months,
    datasets: [
      {
        label: 'Total Users',
        backgroundColor: '#FF6384',
        data: apiuserStatsData.map(stat => parseInt(stat.registered_user_count, 10)),
      },
      {
        label: 'Verified Users',
        backgroundColor: '#36A2EB',
        data: apiuserStatsData.map(stat => parseInt(stat.verified_user_count, 10)),
      }
    ]
  };

  const treeStatsData = {
    labels: months,
    datasets: [
      {
        label: 'Total Trees Registered',
        backgroundColor: '#FFCE56',
        data: apitreeStatsData.map(stat => stat.total_trees_registered),
      },
      {
        label: 'Total Growth Count',
        backgroundColor: '#4BC0C0',
        data: apitreeStatsData.map(stat => stat.total_growth_count),
      }
    ]
  };

  const rewardStatsData = {
    labels: months,
    datasets: [
      {
        label: 'Users Balance',
        backgroundColor: '#FF6384',
        data: apirewardStatsData.map(stat => stat.total_approved_points),
      },
      {
        label: 'Redemption Requested',
        backgroundColor: '#36A2EB',
        data: apirewardStatsData.map(stat => stat.total_point_requested),
      },
      {
        label: 'Rewarded Points',
        backgroundColor: '#FFCE56',
        data: apirewardStatsData.map(stat => stat.total_point_approved),
      }
    ]
  };

  return (
    <div>
      <h1 style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '50px' }}>
        {isAdmin ? 'Admin Dashboard' : 'User Dashboard'}
      </h1>

      <div className="stats-boxes">
        {isAdmin && (
          <>
            <div className="stat-box" style={{backgroundColor:'#ccf9ff'}}>
              <FaUsers size={25}/>
              <h3>Total Users</h3>
              <p className='p-value'>{stats.totalUsers}</p>
            </div>
            <div className="stat-box" style={{backgroundColor:'#9EF4FF'}}>
          <FaUserTimes size={25} />
          <h3>Unverified Users</h3>
          <p className='p-value'>{stats.totalUsers - stats.verifiedUsers}</p>
        </div>
        <div className="stat-box" style={{backgroundColor:'#52EBFF'}}>
              <FaUserCircle size={25} color='green'/>
              <h3>Verified Users</h3>
              <p className='p-value'>{stats.verifiedUsers}</p>
            </div>
          </>
        )}
         {!isAdmin && (
          <>
            <div className="stat-box" style={{backgroundColor:'#ACCC9E'}}>
              <FaWallet size={25}/>
              <h3>Current Balance</h3>
              <p className='p-value'>{stats.collectedPoints}</p>
            </div>
            <div className="stat-box" style={{backgroundColor:'#7BAE65'}}>
              <FaDollarSign size={25}/>
              <h3>Redemption Requested</h3>
              <p className='p-value'>{stats.redemptionRequested}</p>
            </div>
            <div className="stat-box" style={{backgroundColor:'#4B7A11'}}>
              <FaGift size={25}/>
              <h3>Rewarded Points</h3>
              <p className='p-value'>{stats.redeemedPoints}</p>
            </div>
          </>
        )}
      </div>
      <div className="stats-boxes">
      <div className="stat-box" style={{backgroundColor:'#EDB88C'}}>
          <FaTree size={25}/>
          <h3>Total Trees</h3>
          <p className='p-value'>{stats.totalTrees}</p>
        </div>
        <div className="stat-box" style={{backgroundColor:'#E69A5C'}}>
          <FaMapMarkerAlt size={25}/>
          <h3>Geotagged Trees</h3>
          <p className='p-value'>{stats.geoLocatedTreesCount}</p>
        </div>
        <div className="stat-box" style={{backgroundColor:'#D87422'}}>
          <FaCheckCircle size={25} color='green'/>
          <h3>Verified Trees</h3>
          <p className='p-value'>{stats.verifiedTrees}</p>
        </div>
      </div>

      {isAdmin && (
         <div className="stats-boxes">
            <div className="stat-box" style={{backgroundColor:'#ACCC9E'}}>
              <FaWallet size={25}/>
              <h3>Users Balance</h3>
              <p className='p-value'>{points.total_collected_points}</p>
            </div>
            <div className="stat-box" style={{backgroundColor:'#7BAE65'}}>
              <FaDollarSign size={25}/>
              <h3>Users Redemption Requested</h3>
              <p className='p-value'>{points.total_redemption_requested_points}</p>
            </div>
            <div className="stat-box" style={{backgroundColor:'#4B7A11'}}>
          <FaGift size={25}/>
          <h3>Users Rewarded Points</h3>
          <p className='p-value'>{points.total_redeemed_points}</p>
        </div>
 </div>
        )}
      
      
      
      <div style={{ display: 'flex', justifyContent: 'space-around',gap:'5px' }}>

      {isAdmin && (
          <div style={{ width: '80%', marginBottom: '50px' ,boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',padding:'5px'}}>
             <h3> User Stats</h3>
             <Bar data={userStatsData} />
          </div>
             )}

        <div style={{ width: '80%', marginBottom: '50px',boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',padding:'5px' }}>
          <h3> Tree Stats</h3>
          <Bar data={treeStatsData} />
        </div>

        <div style={{ width: '80%', marginBottom: '50px',boxShadow:' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',padding:'5px' }}>
      <h3> Rewards Stats</h3>
      <Bar data={rewardStatsData} />
        </div>
        
      </div>
       
      
    </div>
  );
};

export default StatsComponent;