import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../contexts/GlobalContext';
import { useContext } from 'react';
import api from '../axios/api';

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, setLoggedIn, setRole, setUserId ,setRedeemedPoints,setCollectedPoints} = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');

      if (!token) {
        setIsLoading(false);
        setLoggedIn(false);
        navigate('/');
        return;
      }

      try {
        const response = await api.get('/api/auth/check');
        if (response.data.loggedIn) {
          setLoggedIn(true);
          setRole(response.data.role);
          setUserId(response.data.id);
          setRedeemedPoints(response.data.redeemed_points);
          setCollectedPoints(response.data.collected_points);
        } else {
          throw new Error('Not authenticated');
        }
      } catch (error) {
        console.error('Authentication check failed:', error);
        setLoggedIn(false);
        setRole('');
        setUserId('');
        navigate('/');
      } finally {
        setIsLoading(false);
      }
    };

    checkAuth();
  }, [setLoggedIn, setRole, setUserId, navigate]);

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;