import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaTrash, FaCheck ,FaUserAlt,FaCalendarAlt,FaMapMarkerAlt,FaPagelines,FaCalendarPlus,FaClock, FaTree} from "react-icons/fa";
import api from "../../axios/api";
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/NewFiles/Css/Dashboard.css";
import Select from "react-select";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const VerifyPosts = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("latest");
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [verificationFilter, setVerificationFilter] = useState("all");

  const rejectionOptions = [
    { value: "not_tree", label: "Not Tree" },
    { value: "blurry_image", label: "Blurry Image" },
    { value: "duplicate_image", label: "Duplicate Image" },
    { value: "dead_tree", label: "Dead Tree" },
    { value: "need_to_contact", label: "Need to contact" },
  ];

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleFilter = (e) => setFilter(e.target.value);
  const handleSortOrder = (e) => setSortOrder(e.target.value);
  const handleVerificationFilter = (e) => setVerificationFilter(e.target.value);

  const handleVerify = async (id) => {
    setIsVerifying(true);
    try {
      await api.patch(`/api/admin/posts/treehistory/${id}/verify`);
      fetchPosts();
      toast.success("Verified");
    } catch (error) {
      console.error("Error verifying post:", error);
      toast.error("Failed to verify post");
    } finally {
      setIsVerifying(false);
    }
  };
  
  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      await api.delete(`/api/admin/posts/treehistory/${id}`);
      fetchPosts();
      toast.error("Deleted");
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Failed to delete post");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleReject = async (id, reason) => {
    try {
      await api.post(`/api/admin/posts/treehistory/reject/${id}`, { Reason: reason });
      toast.success("Rejected successfully");
      fetchPosts();
    } catch (error) {
      console.error("Error rejecting post:", error);
      toast.error("Failed to reject post");
    }
  };

  const fetchPosts = async () => {
    try {
      const response = await api.get("/api/admin/gethistory");
      setPosts(response.data.images);
    } catch (error) {
      console.error("Error fetching posts:", error);
      toast.error("Failed to fetch posts");
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const handleSelectPost = (id) => {
    setSelectedPosts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((postId) => postId !== id)
        : [...prevSelected, id]
    );
  };

  const handleVerifySelected = async () => {
    setIsVerifying(true);
    try {
      await Promise.all(
        selectedPosts.map((id) => api.patch(`/api/admin/posts/treehistory/${id}/verify`))
      );
      toast.success("Selected posts verified");
      fetchPosts();
    } catch (error) {
      console.error("Error verifying selected posts:", error);
      toast.error("Failed to verify selected posts");
    } finally {
      setIsVerifying(false);
      setSelectedPosts([]);
    }
  };

  const filteredAndSortedPosts = posts
    .filter((post) => {
      const searchMatch = 
        post.tree_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        post.treename.toLowerCase().includes(searchTerm.toLowerCase());
      
      const filterMatch = 
        post.full_name.toLowerCase().includes(filter.toLowerCase());
      
      const verificationMatch = 
        verificationFilter === "all" ||
        (verificationFilter === "verified" && post.isVerified === 1) ||
        (verificationFilter === "rejected" && post.isVerified === 2) ||
        (verificationFilter === "unverified" && post.isVerified === 0);
      
      return searchMatch && filterMatch && verificationMatch;
    })
    .sort((a, b) => {
      if (sortOrder === "latest") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      } else {
        return new Date(a.createdAt) - new Date(b.createdAt);
      }
    });

  return (
    <div>
      <ToastContainer/>
      <h2>Verify Tree History</h2>
      <div className="controls">
        <input
          type="text"
          placeholder="Search by Tree ID, Name, or Planter..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-bar"
        />
        <input
          type="text"
          placeholder="Filter by Planter Name..."
          value={filter}
          onChange={handleFilter}
          className="filter-input"
        />
        <select
          value={sortOrder}
          onChange={handleSortOrder}
          className="sort-select"
        >
          <option value="latest">Latest</option>
          <option value="oldest">Oldest</option>
        </select>
        <select
          value={verificationFilter}
          onChange={handleVerificationFilter}
          className="sort-select"
        >
          <option value="all">All</option>
          <option value="verified">Verified</option>
          <option value="rejected">Rejected</option>
          <option value="unverified">Unverified</option>
        </select>
      </div>

      <button
        onClick={handleVerifySelected}
        className="verify-selected-button"
        disabled={selectedPosts.length === 0 || isVerifying}
      >
        <FaCheck /> Verify Selected
      </button>

      <div className="posts-grid">
        {filteredAndSortedPosts.map((post) => (
          <div
            key={post.id}
            className={`post-card ${post.isVerified === 1 ? "verified" : ""}`}
          >
            <input
              type="checkbox"
              checked={selectedPosts.includes(post.id)}
              onChange={() => handleSelectPost(post.id)}
              className="select-checkbox"
            />
            <img
              src={post.treeImageUrl}
              alt={post.imageName}
              className="post-image"
            /> 
            <div style={{textAlign:'left',padding: '10px', lineHeight: '1.5'}}>
            <h3 data-tooltip-id="View-More-plantName" data-tooltip-content="Tree Name">{post.treename}</h3>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Tree Id"><FaTree style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {post.tree_id}</p>
            <br/>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded By"><FaUserAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {post.full_name}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded On"><FaCalendarPlus style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {new Date(post.createdAt).toLocaleDateString()}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Planted On"><FaCalendarAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {new Date(post.plantedOn).toLocaleDateString()}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
           
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Species"><FaPagelines style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {post.tree_species}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Age"><FaClock style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {post.age}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            <p className="post-address p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Location"><FaMapMarkerAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/>{post.address}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
              <button
                onClick={() => handleVerify(post.id)}
                className={`verify-button ${
                  post.isVerified === 1 ? "verified" : ""
                }`}
                style={{
                  fontSize: '12px',
                  padding: '5px 5px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                }}
                disabled={post.isVerified === 1 || isVerifying}
              >
                <FaCheckCircle /> {post.isVerified === 1 ? "Verified" : "Verify"}
              </button>
              <button
                onClick={() => handleDelete(post.id)}
                className="delete-button"
                disabled={isDeleting}
                style={{
                  fontSize: '12px',
                  padding: '5px 5px',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <FaTrash /> Delete
              </button>
              <div style={{ width: '110px' }}>
              <Select
                options={rejectionOptions}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    handleReject(post.id, selectedOption.value);
                  }
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    fontSize: '12px',
                    padding: '0',
                    height: '30px',
                    minHeight: '20px',
                    
                  }),
                  menu: (provided) => ({
                    ...provided,
                    fontSize: '12px',
                  }),
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    height: '30px',
                  }),
                }}
                placeholder="Reject Post"
                className="reject-select"
                classNamePrefix="reject-select"
              />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerifyPosts;