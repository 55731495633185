import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import api from "../axios/api";
import axios from "axios";
import { toast } from "react-toastify";
import CoverPhoto from "../assets/images/banner/cover.jpg";
import "../assets/NewFiles/Css/ProfilePage.css";
import CompanyProfile from "./CompanyProfile";
import {
  FaDollarSign,
  FaPhone,
  FaCheckCircle,
  FaEnvelope,
  FaTree,
  FaGift,
  FaMapMarkerAlt,
} from "react-icons/fa";
import * as Yup from "yup";
import {
  Box,
  CircularProgress,
  Tabs,
  Tab,
} from "@mui/material";
import ChangePassword from "./ChangePassword";

const EditProfile = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const { isLoggedIn, setLoggedIn, setProfilePicture } =
    useContext(GlobalContext);
  const [imagePreview, setImagePreview] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [industryTypeOptions, setIndustryTypeOptions] = useState([]);
  const [collectedPoints, setCollectedPoints] = useState(0);
  const [redeemedPoints, setRedeemedPoints] = useState(0);
  const [redeemptionPoints, setRedeepmtionPoints] = useState(0);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState(0);

  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Name is required"),
    phoneNumber: Yup.string().required("Phone number is required"),
    ...(editedUser?.role_code === "corporate" && {
      company_name: Yup.string().required("Company name is required"),
      industry_type_id: Yup.string().required("Industry type is required"),
      website: Yup.string().url("Must be a valid URL").nullable(),
    }),
    ...(editedUser?.role_code === "employee" && {
      organization_id: Yup.string().required("Company is required"),
    }),
  });

  const fetchIndustryTypes = async () => {
    try {
      const response = await api.get("/api/user/industrytypes");
      setIndustryTypeOptions(response.data[0]);
    } catch (error) {
      console.error("Error fetching Organization Data:", error);
      toast.error("Failed to load organization options. Please try again.");
    }
  };

  useEffect(() => {
    fetchIndustryTypes();
    const fetchData = async () => {
      try {
        const response = await api.get("/api/user/userdetails");
        if (response.data.isLoggedIn) {
          const userData = response.data.user.user;
          setUser(userData);
          setEditedUser(userData);
          setCollectedPoints(response.data.user.user.collected_points);
          setRedeemedPoints(response.data.user.user.redeemed_points);
          setRedeepmtionPoints(
            response.data.user.user.redeemption_requested_point
          );
          setProfilePicture(userData.userProfilePic);
          setLoggedIn(true);

          if (userData.role_code === "employee") {
            const orgResponse = await api.get("/api/user/organizations");
            setOrganizations(orgResponse.data[0]);
          }
        } else {
          setLoggedIn(false);
        }
      } catch (error) {
        console.error("Error fetching user data", error);
        setLoggedIn(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoggedIn, setProfilePicture]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "userEmail") return;
    setEditedUser((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  const handleSave = async () => {
    try {
      await validationSchema.validate(editedUser, { abortEarly: false });

      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("userName", editedUser.full_name);
      formData.append("phoneNumber", editedUser.phoneNumber);

      if (editedUser.userProfilePic instanceof File) {
        formData.append("profilePicture", editedUser.userProfilePic);
      }
      if (editedUser.userCoverPic instanceof File) {
        formData.append("coverImage", editedUser.userCoverPic);
      }
       else if (editedUser.role_code === "employee") {
        formData.append("organizationId", editedUser.organization_id);
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/user/update/${user.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setUser({ ...editedUser });
        setIsEditing(false);
        toast.success("Profile updated successfully");
        window.location.reload();
      } else {
        console.error("Error updating profile:", response.data.message);
      }
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      } else {
        console.error("Error updating profile:", error);
      }
    }
  };

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setEditedUser({ ...user });
    setIsEditing(false);
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditedUser((prev) => ({ ...prev, userProfilePic: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditedUser((prev) => ({ ...prev, userCoverPic: file }));
      setCoverImagePreview(URL.createObjectURL(file));
    }
  };

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!isLoggedIn || !user) {
    return <div className="not-logged-in">You are not logged in.</div>;
  }

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  return (
<Box sx={{width:'100%'}}>
   {(user.role_code === "corporate"|| user.role_code === 'ngo') ? (
    <Tabs
        value={activeTab}
        onChange={handleChangeTab}
        centered
        sx={{ marginBottom: 2 }}
      >
        <Tab label="User Profile" />
        <Tab label="Company Profile" />
        <Tab label="Change Password" />
      </Tabs>
    )
    :( <Tabs
      value={activeTab}
      onChange={handleChangeTab}
      centered
      sx={{ marginBottom: 2 }}
    >
       <Tab label="User Profile" />
       <Tab label="Change Password" />
    </Tabs>
     
  )}


      {activeTab === 0 ? (
  // JSX for the first tab
  <div className="profile-container">
      <div className="cover-photo-wrapper">
        <img
          src={coverImagePreview || user.userCoverPic || CoverPhoto}
          alt="Cover"
          className="cover-photo"
        />
        {isEditing && (
          <div
            className="image-overlay"
            onClick={() => document.getElementById("coverPhotoInput").click()}
          >
            <span>Edit Cover Photo</span>
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          id="coverPhotoInput"
          onChange={handleCoverImageChange}
          className="file-input"
        />
      </div>

      <div className="profile-content">
        <div className="profile-header">
          <div className="profile-picture-wrapper">
            <img
              src={imagePreview || user.userProfilePic || "/images/sprout.png"}
              alt="Profile"
              className="profile-picture"
            />
            {isEditing && (
              <div
                className="image-overlay"
                onClick={() =>
                  document.getElementById("profilePictureInput").click()
                }
              >
                <span>Edit Profile Picture</span>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              id="profilePictureInput"
              onChange={handleProfilePictureChange}
              className="file-input"
            />
          </div>
          <div className="profile-info">
            <div className="name-and-wallet">
              <h2 style={{ width: "50%", margin: "0" }}>{user.full_name}</h2>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                }}
              >
                <p
                  className="wallet-icon"
                  style={{ marginRight: "5px", whiteSpace: "nowrap" }}
                >
                  Current Balance
                </p>
                {collectedPoints > 9000
                  ? `${Math.floor(collectedPoints / 1000)}K`
                  : collectedPoints}
                <FaDollarSign
                  color="green"
                  style={{ marginLeft: "5px", marginTop: "2px" }}
                />
              </div>
            </div>
            <p className="user-role">Role: {user.role_name}</p>
          </div>
        </div>

        <div className="profile-details">
          {isEditing ? (
            <div className="edit-form">
              <p style={{ fontSize: "13px" }}>
                NOTE: fields marked with <span style={{ color: "red" }}>*</span>{" "}
                are mandatory to input.
              </p>
              <div className="form-group">
                <label htmlFor="userName">
                  Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="full_name"
                  value={editedUser.full_name}
                  onChange={handleChange}
                  style={errors.full_name ? invalidFieldStyle : {}}
                  className={`profile-input`}
                />
                {errors.full_name && (
                  <span className="error-message">{errors.full_name}</span>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="phoneNumber">
                  Phone Number <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  value={editedUser.phoneNumber}
                  onChange={handleChange}
                  style={errors.phoneNumber ? invalidFieldStyle : {}}
                  className="profile-input"
                />
                {errors.phoneNumber && (
                  <span className="error-message">{errors.phoneNumber}</span>
                )}
              </div>
              {(user.role_code === "employee" ) && (
                <div className="form-group">
                  <label htmlFor="organization">Company</label>
                  <select
                    name="organization_id"
                    value={editedUser.organization_id}
                    onChange={handleChange}
                    className="profile-input"
                    style={errors.organization_id ? invalidFieldStyle : {}}
                  >
                    <option value="">Select Company</option>
                    {organizations.map((org) => (
                      <option key={org.id} value={org.id}>
                        {org.company_name}
                      </option>
                    ))}
                  </select>
                  {errors.organization_id && (
                    <span className="error-message">
                      {errors.organization_id}
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="user-details">
              <div className="detail-item">
                <span className="detail-label" style={{ display: "flex" }}>
                  <FaEnvelope
                    style={{ marginTop: "5px", marginRight: "2px" }}
                  />{" "}
                  Email :{" "}
                </span>
                <span className="detail-value">{user.userEmail}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label" style={{ display: "flex" }}>
                  <FaPhone style={{ marginTop: "5px", marginRight: "2px" }} />{" "}
                  Phone :{" "}
                </span>
                <span className="detail-value">{user.phoneNumber || ""}</span>
              </div>
              {user.role_code === "employee" && (
                <div className="detail-item">
                  <span className="detail-label">Company : </span>
                  <span className="detail-value">
                    {user.company_name || ""}
                  </span>
                </div>
              )}
              <div className="detail-item">
                <span className="detail-label" style={{ display: "flex" }}>
                  <FaTree style={{ marginTop: "5px", marginRight: "2px" }} />{" "}
                  Total Trees :{" "}
                </span>
                <span className="detail-value">{user.totalTrees}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label" style={{ display: "flex" }}>
                  <FaCheckCircle
                    color="green"
                    style={{ marginTop: "5px", marginRight: "2px" }}
                  />{" "}
                  Verified Trees :{" "}
                </span>
                <span className="detail-value">{user.verifiedTrees}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label" style={{ display: "flex" }}>
                  <FaMapMarkerAlt
                    style={{ marginTop: "5px", marginRight: "2px" }}
                  />{" "}
                  Geotagged Trees :{" "}
                </span>
                <span className="detail-value">
                  {user.geoLocatedTreesCount}
                </span>
              </div>
              <div className="detail-item">
                <span className="detail-label" style={{ display: "flex" }}>
                  <FaGift style={{ marginTop: "5px", marginRight: "2px" }} />{" "}
                  Rewarded points :{" "}
                </span>
                <span className="detail-value">{redeemedPoints}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label" style={{ display: "flex" }}>
                  <FaDollarSign
                    style={{ marginTop: "5px", marginRight: "2px" }}
                  />{" "}
                  Redemption Requested points :{" "}
                </span>
                <span className="detail-value">{redeemptionPoints}</span>
              </div>
            </div>
          )}
        </div>

        <div className="action-buttons">
          {isEditing ? (
            <>
              <button onClick={handleSave} className="button save-button">
                Save
              </button>
              <button onClick={handleCancel} className="button cancel-button">
                Cancel
              </button>
            </>
          ) : (
            <button onClick={handleEdit} className="button edit-button">
              Edit Profile
            </button>
          )}
        </div>
      </div>
    </div>
) : activeTab === 1 && (user.role_code === "corporate"|| user.role_code === 'ngo') ? (

 <CompanyProfile/>
) : (
  <ChangePassword />
)}    
    </Box>
  );
};

export default EditProfile;
