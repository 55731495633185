import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga4';
import api from '../axios/api';
import '../assets/NewFiles/Css/SignupPage.css';

const schema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  password: yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  enrollAs: yup.string().required('Please select an option'),
  organization: yup.string().when('enrollAs', {
    is: 'employee',
    then: (schema) => schema.required('Organization is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  industryType: yup.string().when('enrollAs', {
    is: (val) => val === 'corporate' || val === 'ngo',
    then: (schema) => schema.required('Industry type is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  customIndustryType: yup.string().when(['enrollAs', 'industryType'], {
    is: (enrollAs, industryType) => (enrollAs === 'corporate' || enrollAs === 'ngo') && industryType === 'Other',
    then: (schema) => schema.required('Custom industry type is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  companyName: yup.string().when('enrollAs', {
    is: (val) => val === 'corporate' || val === 'ngo',
    then: (schema) => schema.required('Company name is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  website: yup.string().url('Please enter a valid URL').notRequired(),
});

const SignupPage = () => {
  const navigate = useNavigate();
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [enrollAsOptions, setEnrollAsOptions] = useState([]);
  const [industryTypeOptions,setIndustryTypeOptions]=useState([])

  const { register, handleSubmit, formState: { errors }, watch ,setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const enrollAs = watch('enrollAs');

  useEffect(() => {
    const fetchEnrollAsOptions = async () => {
      try {
        const response = await api.get('/api/user/roles');
        setEnrollAsOptions(response.data[0]);
      } catch (error) {
        console.error('Error fetching Enroll As options:', error);
        toast.error('Failed to load enrollment options. Please try again.');
      }
    };

    fetchEnrollAsOptions();
  }, []);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      if (enrollAs === 'employee') {
        try {
          const response = await api.get('/api/user/organizations');
          setOrganizationOptions(response.data[0]);
        } catch (error) {
          console.error('Error fetching Organization Data:', error);
          toast.error('Failed to load organization options. Please try again.');
        }
      }
    };

    fetchOrganizationData();
  }, [enrollAs]);

  useEffect(() => {
    const fetchIndustryTypes = async () => {
      if (enrollAs === 'corporate' || enrollAs === 'ngo') {
        try {
          const response = await api.get('/api/user/industrytypes');
          setIndustryTypeOptions(response.data[0]);
        } catch (error) {
          console.error('Error fetching Organization Data:', error);
          toast.error('Failed to load organization options. Please try again.');
        }
      }
    };

    fetchIndustryTypes();
  }, [enrollAs]);


  const onSubmit = async (data) => {
    try {
      const userEmail = data.email.toLowerCase();
      const userName = data.name;

      let selectedCompanyId = null;
      if (data.enrollAs === 'employee' && data.organization) {
        const selectedOrg = organizationOptions.find(org => org.id.toString() === data.organization.toString());
        selectedCompanyId = selectedOrg ? selectedOrg.id : null;
      }

      const signupData = {
        userEmail,
        password: data.password,
        userName,
        enrollAs: data.enrollAs,
        companyName: data.enrollAs === 'employee' ? 
        organizationOptions.find(org => org.id.toString() === data.organization.toString())?.company_name : 
        data.companyName,
        website: data.website || '',
        industryType: data.industryType !== 'Other' ? data.industryType : data.customIndustryType,
        companyId: selectedCompanyId
      };

      await api.post('/api/auth/signup', signupData);

      ReactGA.event({
        category: 'User',
        action: 'Signup Click',
        label: 'Signup Form',
      });

      toast.success('Signup successful! You can now log in.');

      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      toast.error(`Signup failed: ${errorMessage}`);
    }
  };

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  return (
    <div className="signup-container">
    <div className="signup-form">
      <h2>Sign Up</h2>
      <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="signup-form-group">
            <label>Name <span style={{color:'red'}}>*</span></label>
            <input type="text" {...register('name')} placeholder="Enter your name" style={errors.name ? invalidFieldStyle : {}}/>
            {errors.name && <p className="error-message">{errors.name.message}</p>}
          </div>
          <div className="signup-form-group">
            <label>Email <span style={{color:'red'}}>*</span></label>
            <input type="email" {...register('email')} placeholder="Enter your email" style={errors.email ? invalidFieldStyle : {}}/>
            {errors.email && <p className="error-message">{errors.email.message}</p>}
          </div>
        </div>

        <div className="form-row">
          <div className="signup-form-group">
            <label>Password <span style={{color:'red'}}>*</span></label>
            <input type="password" {...register('password')} placeholder="Enter your password" style={errors.password ? invalidFieldStyle : {}} />
            {errors.password && <p className="error-message">{errors.password.message}</p>}
          </div>
          <div className="signup-form-group">
            <label>Confirm Password <span style={{color:'red'}}>*</span></label>
            <input type="password" {...register('confirmPassword')} placeholder="Confirm your password" style={errors.confirmPassword ? invalidFieldStyle : {}}/>
            {errors.confirmPassword && <p className="error-message">{errors.confirmPassword.message}</p>}
          </div>
        </div>

        <div className="form-row">
          <div className="signup-form-group">
            <label>Enroll As <span style={{color:'red'}}>*</span></label>
            <select {...register('enrollAs')} style={errors.enrollAs ? invalidFieldStyle : {}}>
              <option value="">Select</option>
              {enrollAsOptions.map((option) => (
                <option key={option.id} value={option.role_code}>{option.role_name}</option>
              ))}
            </select>
            {errors.enrollAs && <p className="error-message">{errors.enrollAs.message}</p>}
          </div>
          {enrollAs === 'employee' && (
            <div className="signup-form-group">
              <label>Select Organization <span style={{color:'red'}}>*</span></label>
              <select 
            {...register('organization')} 
            style={errors.organization ? invalidFieldStyle : {}}
            onChange={(e) => {
              setValue('organization', e.target.value);
              // Set companyId when organization is selected
              const selectedOrg = organizationOptions.find(org => org.id.toString() === e.target.value);
              setValue('companyId', selectedOrg ? selectedOrg.id : null);
            }}
          >
            <option value="">Select Organization</option>
            {organizationOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.company_name}
              </option>
            ))}
          </select>
              {errors.organization && <p className="error-message">{errors.organization.message}</p>}
            </div>
          )}

          {(enrollAs === 'corporate' || enrollAs === 'ngo') && (
          <>
              <div className="signup-form-group">
                <label>Industry Type <span style={{color:'red'}}>*</span></label>
                <select {...register('industryType')} style={errors.industryType ? invalidFieldStyle : {}}>
                  <option value="">Select Industry Type</option>
                  {industryTypeOptions.map((option) => (
                    <option key={option.id} value={option.id}>{option.type_name}</option>
                  ))}
                </select>
                {errors.industryType && <p className="error-message">{errors.industryType.message}</p>}
              </div>
            </>
          )}
        </div>

        {(enrollAs === 'corporate' || enrollAs === 'ngo') && (
          <>
            <div className="form-row">
            </div>
            <div className="form-row">
              <div className="signup-form-group">
                <label>Company Name <span style={{color:'red'}}>*</span></label>
                <input type="text" {...register('companyName')} placeholder="Enter your company name" style={errors.companyName ? invalidFieldStyle : {}}/>
                {errors.companyName && <p className="error-message">{errors.companyName.message}</p>}
              </div>
              <div className="signup-form-group">
                <label>Website</label>
                <input {...register('website')} placeholder="Enter your website" style={errors.website ? invalidFieldStyle : {}}/>
                {errors.website && <p className="error-message">{errors.website.message}</p>}
              </div>
            </div>
          </>
        )}


        <button type="submit" className="signup-button">Sign Up</button>
      </form>
      <div className='signup-navigation'>
        <p>Already have an account? </p><Link to="/login" className="option-link">Login</Link>
      </div>
      <ToastContainer />
    </div>
  </div>
  );
};

export default SignupPage;