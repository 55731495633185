import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Container,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  CheckCircleOutline as VerifiedIcon,
  ErrorOutline as ErrorIcon,
  Email as EmailIcon
} from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';

const ProfileVerification = () => {
  const [verificationStatus, setVerificationStatus] = useState('pending');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Add theme and media query for responsive design
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const token = new URLSearchParams(location.search).get('token');

  const handleVerification = async () => {
    if (!token) {
      setVerificationStatus('error');
      setMessage('Invalid verification link');
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/verify?token=${token}`);
      
      if (response.data.success) {
        setVerificationStatus('success');
        setMessage(response.data.message);
        toast.success("Your Email is verified");
      } else {
        setVerificationStatus('error');
        setMessage(response.data.message);
      }
    } catch (error) {
      setVerificationStatus('error');
      toast.error("Verification failed. Please try again.");
      setMessage(error.response?.data?.message || 'Verification failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirect = () => {
    navigate('/login');
  };

  return (
    <Container maxWidth="sm">
      <ToastContainer />
      <Box
        sx={{
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          py: 3, // Add padding for better mobile display
        }}
      >
        <Paper
          elevation={3}
          sx={{
            p: { xs: 2, sm: 4 }, // Responsive padding
            width: '100%',
            textAlign: 'center',
            borderRadius: 2,
            maxWidth: { xs: '95%', sm: '100%' }, // Responsive width
          }}
        >
          <Box 
            sx={{
              mb: 3,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <EmailIcon
              sx={{
                fontSize: { xs: 48, sm: 64 }, // Responsive icon size
                color: 'primary.main',
                mb: 2
              }}
            />
            <Typography 
              variant={isMobile ? "h6" : "h5"} 
              gutterBottom
            >
              Email Verification
            </Typography>
            <Typography 
              variant="body1" 
              color="textSecondary" 
              paragraph
              sx={{ px: { xs: 1, sm: 2 } }} // Add padding for better text wrapping
            >
              {verificationStatus === 'pending'
                ? 'Click the button below to verify your email address'
                : message}
            </Typography>
          </Box>

          {verificationStatus === 'pending' && (
            <Button
              variant="contained"
              color="primary"
              size={isMobile ? "medium" : "large"}
              onClick={handleVerification}
              disabled={isLoading}
              startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : null}
              sx={{ mb: 2 }}
              fullWidth={isMobile}
            >
              {isLoading ? 'Verifying...' : 'Verify Email'}
            </Button>
          )}

          {verificationStatus === 'success' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <VerifiedIcon
                sx={{
                  fontSize: { xs: 40, sm: 48 },
                  color: 'success.main',
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleRedirect}
                fullWidth={isMobile}
              >
                Go to Login
              </Button>
            </Box>
          )}

          {verificationStatus === 'error' && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <ErrorIcon
                sx={{
                  fontSize: { xs: 40, sm: 48 },
                  color: 'error.main',
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleRedirect}
                fullWidth={isMobile}
              >
                Go to Login
              </Button>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
};

export default ProfileVerification;