import React, { useEffect, useState, useCallback } from "react";
import { Progress, Tooltip } from "antd";
import MultiProgress from "react-multi-progress";
import { useParams } from "react-router-dom";
import api from "../axios/api";
import ReactLoading from "react-loading";
import {
  FaCheckCircle,
  FaTimesCircle,
  FaInstagram,
  FaUser,
  FaMapMarkerAlt,
  FaCalendarAlt,
  FaCity,
  FaFlag,
  FaHashtag,
} from "react-icons/fa";
import "../assets/NewFiles/Css/PlantProfile.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { FaPagelines,FaUserAlt,FaTree,FaCalendarPlus,FaCalendar,FaClock,FaMapMarkedAlt ,FaMapPin} from "react-icons/fa";

const TreeStages = ({ stagesData, treeInfo ,imageUrl}) => {
  return (
    <div className="mb-6">
      <h2 className="text-xl font-bold mb-3">Tree Growth</h2>
      <p className="text-sm text-gray-700 mb-5">
        Tree journey stages from nursing until maturity
      </p>

      <div
        id="progress-bar"
        style={{ height: "50px", width: "100%" }}
        className="mt-10"
      >
        <MultiProgress
          transitionTime={1.5}
          height={10}
          elements={stagesData.progress}
        />
      </div>
      <div className="flex flex-wrap justify-between ">
        {stagesData.progress.map((stage, index) => (
          <div key={index} className="flex items-center mr-2 mb-2">
            <span
              style={{
                backgroundColor: stage.color,
                borderRadius: "50%",
                width: "15px",
                height: "15px",
                display: "inline-block",
                marginRight: "5px",
              }}
            ></span>
            <span className="text-xs sm:text-sm text-gray-700">
              {stage.label}
            </span>
          </div>
        ))}
      </div>
      <div className="share-buttons" style={{ justifyContent: "flex-start" ,margin:'5px'}}>
            <FacebookShareButton url={window.location.href}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <button
              onClick={() => {
                navigator.clipboard
                  .writeText(window.location.href)
                  .then(() => {
                    const shareUrl = `https://www.instagram.com/`;
                    window.open(shareUrl, "_blank");
                  })
                  .catch((err) => {
                    console.error("Failed to copy: ", err);
                  });
              }}
              style={{
                height: "32px",
                width: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                padding: "5px",
                backgroundColor: "#D93F7C",
              }}
            >
              <FaInstagram size={32} />
            </button>
            <WhatsappShareButton url={window.location.href} img={imageUrl} title={treeInfo.treeName} separator={"\n"}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton url={window.location.href}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
    </div>
  );
};

const TreeInformation = ({ treeData }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };
  return (
    <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md mb-6">
      <h2 className="text-xl font-bold mb-4">Tree Information</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
        <div style={{display:'flex'}}>
          <FaHashtag style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Tree ID:</p>
          <p className="text-sm text-gray-600">{treeData.tree_id === "null" ? " " : treeData.tree_id}</p>
        </div>
  
        <div style={{display:'flex'}}>
          <FaUser style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Uploaded By:</p>
          <p className="text-sm text-gray-600">{treeData.full_name === "null" ? " " : treeData.full_name}</p>
        </div>
  
        <div style={{display:"flex"}}>
          <FaTree style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Tree Name:</p>
          <p className="text-sm text-gray-600">{treeData.treeName === "null" ? " " : treeData.treeName}</p>
        </div>

        <div>
            {treeData.isVerified ? (<>
              <FaCheckCircle className="text-green-500 inline-block" />
              <p className="text-sm text-gray-600">Verified</p>
            </>) : (<>
              <FaTimesCircle className="text-gray-500 inline-block" />
              <p className="text-sm text-gray-600">Unverified</p>
            </>)}
        </div>

        <div style={{display:'flex'}}>
            <FaCalendarAlt style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Planted On:</p>
          <p className="text-sm text-gray-600">{treeData.plantedOn === "null" ? " " : formatDate(treeData.plantedOn)}</p>
        </div>

        <div style={{display:'flex'}}>
          <FaClock style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Age:</p>
          <p className="text-sm text-gray-600">{treeData.age === "null" ? " " : `${treeData.age} years`}</p>
        </div>

        
        <div style={{display:'flex'}}>
          <FaPagelines style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Tree Species:</p>
          <p className="text-sm text-gray-600">{treeData.tree_species === "null" ? " " : treeData.tree_species}</p>
        </div>

        <div style={{display:'flex'}}>
          <FaCity style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">City:</p>
          <p className="text-sm text-gray-600">{treeData.city === "null" ? " " : treeData.city}</p>
        </div>

        
        <div style={{display:'flex'}}>
        <FaFlag style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">State:</p>
          <p className="text-sm text-gray-600">{treeData.state === "null" ? " " : treeData.state}</p>
        </div>


        <div style={{display:'flex'}}>
          <FaFlag style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Country:</p>
          <p className="text-sm text-gray-600">{treeData.country === "null" ? " " : treeData.country}</p>
        </div>

        <div className="coordinate-container">
          <FaMapPin style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Latitude:</p>
          <p className="text-sm text-gray-600">{treeData.lat === "null" ? " " : treeData.lat}</p>
        </div>
  
        <div className="coordinate-container">
          <FaMapPin style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Longitude:</p>
          <p className="text-sm text-gray-600">{treeData.lng === "null" ? " " : treeData.lng}</p>
        </div>
  
       
  
        
  
        
  
  
        <div className="col-span-1 sm:col-span-2" style={{display:'flex'}}>
          <FaMapMarkerAlt style={{marginTop:'5px'}}/>
          <p className="text-sm text-gray-600">Address:</p>
          <p className="text-sm text-gray-600">{treeData.address === "null" ? " " : treeData.address}</p>
        </div>
      </div>
    </div>
  );

};

const TreeCO2eProgress = ({ co2Progress }) => (
  <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
    <h2 className="text-xl font-bold mb-2">Tree CO2e Progress</h2>
    <p className="text-sm text-gray-700 mb-4">
      Progress of the Tree's CO2e offset over tracked stages
    </p>
    <Tooltip title={`${co2Progress.percentage}%`}>
      <Progress
        percent={co2Progress.percentage}
        strokeColor="#52c41a"
        showInfo={false}
      />
    </Tooltip>
  </div>
);

export default function PlantProfile({ mockData }) {
  const { treeId } = useParams();
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [imageDates, setImageDates] = useState([]);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/upload/treeData/${treeId}`);
        setTreeData(response.data.treeData[0]);
        const allImages = [
          response.data.treeData[0].treeImageUrl,
          ...response.data.treeHistory.map((hist) => hist.treeImageUrl),
        ];
        const allDates = [
          response.data.treeData[0].createdAt,
          ...response.data.treeHistory.map((hist) => hist.createdAt),
        ];
        setImages(allImages);
        setImageDates(allDates);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [treeId]);

  const handleImageLoad = useCallback(() => {
    setIsImageLoaded(true);
  }, []);

  useEffect(() => {
    if (lightboxIsOpen) {
      setIsImageLoaded(false);
      const img = new Image();
      img.src = images[photoIndex];
      img.onload = handleImageLoad;
      img.onerror = () => {
        console.error("Error loading image:", images[photoIndex]);
        setIsImageLoaded(true); // Set to true even on error to remove loading state
      };
    }
  }, [lightboxIsOpen, photoIndex, images, handleImageLoad]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ReactLoading type="spin" color="#8BC34A" height={50} width={50} />
      </div>
    );
  }

  return (
     <HelmetProvider>
    <>
      <Helmet>
        <title>Tree Profile</title>
        <meta name="description" content={treeData.tree_id} />
        
        
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="Mango Tree" />
        <meta property="og:description" content="Pachtoli, Chittaurgarh Tehsil, Chittorgarh, Rajasthan, 312613, India" />
        <meta property="og:image" content="https://res.cloudinary.com/dwie549up/image/upload/v1728021230/tree_images/lxgwzteklaasvqq5cllt.jpg" />

  
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={window.location.href} />
        <meta property="twitter:title" content={treeData.treeName} />
        <meta property="twitter:description" content={treeData.address} />
        <meta property="twitter:image" content={treeData.treeImageUrl} />

     
         <meta property="og:site_name" content="Tree Tracking Platform" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:image:alt" content={`Image of Mango Tree`} />
      </Helmet>

      <section className="bg-image pt-40 pb-40">
        <div className="container">
        <h2 className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
            Tree Growth {treeId}
          </h2>
        </div>
        
      </section>

      <div className="bg-gray-50 min-h-screen py-6 sm:py-10 " >
        <div className="container mx-9 px-4 sm:px-8 w-2/3">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-0 sm:gap-0">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <img
                src={treeData.treeImageUrl}
                alt="Tree"
                className="w-70 h-70 rounded-lg shadow-md mb-6 cursor-pointer"
                onClick={() => {
                  setPhotoIndex(0);
                  setLightboxIsOpen(true);
                }}
              />
              <div className="flex flex-wrap gap-4 pb-4">
                {images.map((img, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <img
                      src={img}
                      alt={`Tree history ${index}`}
                      className="w-32 h-32 object-cover rounded cursor-pointer"
                      onClick={() => {
                        setPhotoIndex(index);
                        setLightboxIsOpen(true);
                      }}
                    />
                    <span
                      style={{ color: "black" }}
                      className="text-s mt-2 text-center w-35"
                    >
                      {formatDate(imageDates[index])}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div>
              {/* <TreeStages
                stagesData={mockData.treeStages}
                treeInfo={treeData}
                imageUrl={treeData.treeImageUrl}
              /> */}
              <TreeInformation treeData={treeData} />
              <TreeCO2eProgress co2Progress={mockData.treeCO2e} />
            </div>
          </div>
        </div>
      </div>
      {lightboxIsOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => {
            setLightboxIsOpen(false);
            setIsImageLoaded(false);
          }}
          onMovePrevRequest={() => {
            setPhotoIndex((photoIndex + images.length - 1) % images.length);
            setIsImageLoaded(false);
          }}
          onMoveNextRequest={() => {
            setPhotoIndex((photoIndex + 1) % images.length);
            setIsImageLoaded(false);
          }}
          onImageLoad={handleImageLoad}
          loadingElement={
            <div className="flex justify-center items-center h-full">
              <ReactLoading
                type="spin"
                color="#8BC34A"
                height={50}
                width={50}
              />
            </div>
          }
          imageCaption={`Posted On: ${formatDate(imageDates[photoIndex])}`}
          reactModalStyle={{
            overlay: { zIndex: 1500 },
          }}
        />
      )}
    </>
     </HelmetProvider>
  );
}
