import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Box,
} from '@mui/material';

const TopUsersTable = () => {
  const [users, setUsers] = useState([]);
  const [totalCounts, setTotalCounts] = useState({ totalPosts: 0, totalOther: 0, totalUploads: 0 });

  useEffect(() => {
    const fetchTopUsers = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/User/topUsers`);
        setUsers(response.data);

        // Calculate totals
        let totalPosts = 0;
        let totalVerified=0;
        let totalPoints=0;
        response.data.forEach(user => {
          totalPosts += user.totalTrees;
          totalVerified +=user.verifiedTrees;
          totalPoints+=user.redeemed_points;
        });

        setTotalCounts({ totalPosts, totalVerified ,totalPoints});
      } catch (error) {
        console.error('Error fetching top users:', error);
      }
    };

    fetchTopUsers();
  }, []);

  return (
    <Box sx={{ padding: '30px 15px', textAlign: 'center' }}>
      <h1>Leaders</h1>
      <TableContainer 
        component={Paper} 
        sx={{ 
          marginTop: '20px',
          '& .MuiTableCell-root': {
            padding: '12px',
            fontSize: '1rem',
            '@media (max-width: 768px)': {
              padding: '8px',
              fontSize: '14px',
            },
            '@media (max-width: 480px)': {
              padding: '6px',
              fontSize: '12px',
            },
          }
        }}
      >
        <Table>
          <TableHead sx={{ backgroundColor: '#002F43' }}>
            <TableRow>
              <TableCell sx={{ color: '#fff' }}>Profile</TableCell>
              <TableCell sx={{ color: '#fff' }}>Name</TableCell>
              <TableCell sx={{ color: '#fff' }}>Plants</TableCell>
              <TableCell sx={{ color: '#fff' }}>Verified</TableCell>
              <TableCell sx={{ color: '#fff' }}>Reward Points Earned</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link 
                    href={`/userprofile/${user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Box
                      component="img"
                      src={user.userProfilePic}
                      alt={user.userName}
                      sx={{
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        marginRight: '10px',
                        transition: 'transform 0.3s, box-shadow 0.3s',
                        '@media (max-width: 768px)': {
                          width: '30px',
                          height: '30px',
                        },
                        '@media (max-width: 480px)': {
                          width: '25px',
                          height: '25px',
                        },
                        '&:hover': {
                          transform: 'scale(1.4)',
                          boxShadow: '0 10px 10px rgba(0, 0, 0, 0.2)',
                        }
                      }}
                    />
                  </Link>
                </TableCell>
                <TableCell>
                  <Link
                    href={`/userprofile/${user.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      fontWeight: 'bold',
                      textDecoration: 'none',
                      color: 'inherit'
                    }}
                  >
                    {user.full_name}
                  </Link>
                </TableCell>
                <TableCell>{user.totalTrees}</TableCell>
                <TableCell>{user.verifiedTrees}</TableCell>
                <TableCell>{user.redeemed_points}</TableCell>
              </TableRow>
            ))}
            <TableRow sx={{ '& .MuiTableCell-root': { fontWeight: 'bold' } }}>
              <TableCell>Total</TableCell>
              <TableCell />
              <TableCell>{totalCounts.totalPosts}</TableCell>
              <TableCell>{totalCounts.totalVerified}</TableCell>
              <TableCell >{totalCounts.totalPoints}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <style>
        {`
          @media (max-width: 768px) {
            h1 {
              font-size: 24px;
            }
          }

          @media (max-width: 480px) {
            h1 {
              font-size: 20px;
            }
          }
        `}
      </style>
    </Box>
  );
};

export default TopUsersTable;