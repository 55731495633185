import React, { useState, useEffect } from "react";
import api from "../../axios/api";
import "../../assets/NewFiles/Css/Dashboard.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {  Box ,TableContainer,Table,Paper,TableHead,TableRow,TableCell, TableBody,Button,Typography} from "@mui/material";
import { FaCheckCircle, FaFileExcel} from "react-icons/fa";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { useParams } from "react-router-dom";
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";

const ProjectContributors = () => {
  const [users, setUsers] = useState([]);
  const [projectData,setProjectData]=useState({});
  const { id } = useParams();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get(`/api/project/getProjectContributors/${id}`);
        setProjectData(response.data.projectData);
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = users.filter(
    (user) => user.role_code !== "admin" && user.role_code !== "superadmin"
  );

  const exportToExcel = () => {
    try {
      const wb = XLSX.utils.book_new();
      
      // Create project details array first
      const projectDetails = [
        ['Project Details', ''],
        ['Name', projectData?.name || ''],
        ['Start Date', projectData?.start_date || ''],
        ['End Date', projectData?.end_date || ''],
        ['Description', projectData?.big_desc || ''],
        ['Total Trees Target', projectData?.total_trees_target || ''],
        ['Total Trees Planted', projectData?.total_trees_planted || ''],
        ['Tags', projectData?.tags],
        ['ESG Goals', projectData?.esg_goals],
        ['Address', projectData?.address || ''],
        [], // Empty row for spacing
      ];
  
      // Create contributors data array
      const contributorsData = [
        [
          'Full Name',
          'Email',
          'Verification Status',
          'Role',
          'Total Trees',
          'Tree Growth',
          'Points Collected',
        ],
        ...filteredUsers.map(user => [
          user.full_name,
          user.userEmail,
          user.isVerified ? 'Verified' : 'Unverified',
          user.role_code,
          user.tree_uploaded_count,
          user.tree_history_updated_count,
          user.total_claimed_points,
        ])
      ];
  
      // Convert arrays to worksheet
      const ws = XLSX.utils.aoa_to_sheet([...projectDetails, ...contributorsData]);
  
      // Set column widths
      const maxWidth = 20;
      ws['!cols'] = Array(8).fill({ wch: maxWidth });
  
      // Create style objects
      const headerStyle = { 
        font: { bold: true, color: { rgb: "FFFFFF" } },
        fill: { fgColor: { rgb: "4F81BD" } },
        alignment: { vertical: 'center', horizontal: 'left' }
      };
  
      const labelStyle = {
        font: { bold: true },
        fill: { fgColor: { rgb: "DCE6F1" } },
        alignment: { vertical: 'center', horizontal: 'left' }
      };
  
      const contributorsHeaderStyle = {
        font: { bold: true },
        fill: { fgColor: { rgb: "E6F3FF" } },
        alignment: { vertical: 'center', horizontal: 'left' }
      };
  
      // Apply styles using cell references
      const range = XLSX.utils.decode_range(ws['!ref']);
      
      // Style project details header (first row)
      ws[XLSX.utils.encode_cell({ r: 0, c: 0 })] = { 
        ...ws[XLSX.utils.encode_cell({ r: 0, c: 0 })], 
        s: headerStyle 
      };
      ws[XLSX.utils.encode_cell({ r: 0, c: 1 })] = { 
        ...ws[XLSX.utils.encode_cell({ r: 0, c: 1 })], 
        s: headerStyle 
      };
  
      // Style project details
      for (let i = 1; i < projectDetails.length - 1; i++) {
        const cellA = XLSX.utils.encode_cell({ r: i, c: 0 });
        const cellB = XLSX.utils.encode_cell({ r: i, c: 1 });
        
        if (ws[cellA]) ws[cellA].s = labelStyle;
        if (ws[cellB]) ws[cellB].s = labelStyle;
      }
  
      // Style contributors header
      const contributorsHeaderRow = projectDetails.length;
      for (let i = 0; i < 7; i++) {
        const cell = XLSX.utils.encode_cell({ r: contributorsHeaderRow, c: i });
        if (ws[cell]) ws[cell].s = contributorsHeaderStyle;
      }
  
      // Add worksheet to workbook
      XLSX.utils.book_append_sheet(wb, ws, "Project Details & Contributors");
      
      // Generate file name with current date
      const fileName = `project_report_${new Date().toISOString().split('T')[0]}.xlsx`;
      
      // Save file
      XLSX.writeFile(wb, fileName);
      
      toast.success("Data exported successfully!");
    } catch (error) {
      console.error("Error exporting data:", error);
      toast.error("Failed to export data");
    }
  };

  return (
    <Box sx={{ width: "100%", margin: "auto", padding: 2 }}>
      <ToastContainer />
     <Box sx={{ padding: 2 }}>
     
     <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
  {/* Left spacer to push content to center */}
  <Box sx={{ flexGrow: 1 }} />

  {/* Centered Project Name */}
  <Box sx={{ textAlign: 'center', flexGrow: 2 }}>
    <h2>{projectData?.name || ''}</h2>
  </Box>

  {/* Right-aligned Button */}
  <Button
    variant="contained"
    startIcon={<FaFileExcel />}
    onClick={exportToExcel}
    sx={{
      backgroundColor: "#1D6F42",
      '&:hover': {
        backgroundColor: "#15552F",
      }
    }}
  >
    Export to Excel
  </Button>
</Box>

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#002F43" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>Full Name</TableCell>
              <TableCell sx={{ color: "#fff" }}>Email</TableCell>
              <TableCell sx={{ color: "#fff" }}>Role</TableCell>
              <TableCell sx={{ color: "#fff" }}>Total Trees</TableCell>
              <TableCell sx={{ color: "#fff" }}>Tree Growth</TableCell> 
              <TableCell sx={{ color: "#fff" }}>Points Collected</TableCell> 
            </TableRow>
            </TableHead>
            <TableBody>
            {filteredUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.full_name}</TableCell>
                <TableCell style={{display:'flex'}}> {user.isVerified ? <FaCheckCircle color="green" style={{ marginRight: '8px' }} data-tooltip-id="verify" data-tooltip-content="Verified User"/> : <FaCheckCircle color="red" style={{ marginRight: '8px' }} data-tooltip-id="verify" data-tooltip-content="Unverified User"/>}{user.userEmail}</TableCell>
                 <Tooltip id="verify" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
                <TableCell>{user.role_code}</TableCell>
                <TableCell>{user.tree_uploaded_count}</TableCell>
                <TableCell>{user.tree_history_updated_count}</TableCell>
                <TableCell>{user.total_claimed_points}</TableCell>
              </TableRow>
            ))}
            </TableBody>
          </Table>
      </TableContainer>
      </Box>
    </Box>
  );
};

export default ProjectContributors;
