import React from "react";
import { useState, useContext } from "react";
import api from "../../axios/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { useEffect } from "react";
import PopupMap from "../features/PopupMap";
import { GlobalContext } from "../../contexts/GlobalContext";
import imageCompression from "browser-image-compression";
import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CreateProject = () => {
  const navigate = useNavigate();
  const {
    address,
    setAddress,
    latlng,
    country,
    addressData,
    role,
    userId,
    setLatlng,
    setAddressData,
    setCountry,
  } = useContext(GlobalContext);
  const [organizationsOptions, setOrganizationsOptions] = useState([]);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
  const [esgOptions] = useState([
    { index: 1, value: "No Poverty", label: "No Poverty" },
    { index: 2, value: "Zero Hunger", label: "Zero Hunger" },
    {
      index: 3,
      value: "Good Health And Well Being",
      label: "Good Health And Well Being",
    },
    { index: 4, value: "Quality Education", label: "Quality Education" },
    { index: 5, value: "Gender Equality", label: "Gender Equality" },
    {
      index: 6,
      value: "Clean Water And Sanitation",
      label: "Clean Water And Sanitation",
    },
    {
      index: 7,
      value: "Affordable And Clean Energy",
      label: "Affordable And Clean Energy",
    },
    {
      index: 8,
      value: "Decent Work And Economic Growth",
      label: "Decent Work And Economic Growth",
    },
    {
      index: 9,
      value: "Industry-Innovation and Infrastructure",
      label: "Industry, Innovation and Infrastructure",
    },
    { index: 10, value: "Reduced Inequalities", label: "Reduced Inequalities" },
    {
      index: 11,
      value: "Sustainable Cities And Communities",
      label: "Sustainable Cities And Communities",
    },
    {
      index: 12,
      value: "Responsible Consumption And Production",
      label: "Responsible Consumption And Production",
    },
    { index: 13, value: "Climate Action", label: "Climate Action" },
    { index: 14, value: "Life Below Water", label: "Life Below Water" },
    { index: 15, value: "Life On Land", label: "Life On Land" },
    {
      index: 16,
      value: "Peace-Justice and Strong Institutions",
      label: "Peace, Justice and Strong Institutions",
    },
    {
      index: 17,
      value: "Partnerships For The Goals",
      label: "Partnerships For The Goals",
    },
  ]);
  
  const [tags, setTags] = useState([]);
  const [newProject, setNewProject] = useState({
    projectName: "",
    projectDescription: "",
    startDate: null,
    endDate: null,
    organizations: [],
    totalTreesTarget: "",
    esgGoals: [],
  });
  const [invalidFields, setInvalidFields] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    component: null,
  });

  // Function to handle modal opening with different components
  const openModal = (title, component) => {
    setModalConfig({
      isOpen: true,
      title,
      component,
    });
  };

  // Function to handle modal closing
  const closeModal = () => {
    setModalConfig({
      isOpen: false,
      title: "",
      component: null,
    });
  };

  // Function to render the dynamic component
  const renderComponent = () => {
    const Component = modalConfig.component;
    return Component ? <Component onClose={closeModal} /> : null;
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  };

  useEffect(() => {
    api
      .get("/api/user/organizations")
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const orgOptions = response.data[0].map((org) => ({
            value: org.id,
            label: org.company_name,
            created_by_user: org.created_by_user,
          }));
          if (role === "corporate" || role === "ngo") {
            const filtered = orgOptions.filter(
              (organization) => organization.created_by_user === userId
            );
            setNewProject((prevProject) => ({
              ...prevProject,
              organizations: filtered.map((org) => org.value),
            }));
            setOrganizationsOptions(filtered);
          } else if (role === "admin") {
            setOrganizationsOptions(orgOptions);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching organizations:", error);
      });
  }, [role, userId]);

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  const handleCreateProject = async () => {
    const newInvalidFields = [];

    if (!coverPhoto) newInvalidFields.push("cover");
    if (!newProject.projectName) newInvalidFields.push("projectName");
    if (!newProject.projectDescription) newInvalidFields.push("projectDesc");
    if (!newProject.totalTreesTarget) newInvalidFields.push("target");
    if (!newProject.startDate) newInvalidFields.push("startDate");
    if (!newProject.endDate) newInvalidFields.push("endDate");
    if (newProject.organizations.length === 0) newInvalidFields.push("Org");
    if (newProject.esgGoals.length === 0) newInvalidFields.push("Goals");
    if (tags.length === 0) newInvalidFields.push("tags");
    if (!address) newInvalidFields.push("address");

    if (newInvalidFields.length > 0) {
      setInvalidFields(newInvalidFields);
      toast.error("Please provide all required information.");
      return;
    }
    setIsSubmitting(true);

    const sortedEsgGoals = newProject.esgGoals.sort((a, b) => a.index - b.index).map(goal => goal.value);

    const formData = new FormData();
    formData.append("projectName", newProject.projectName);
    formData.append("projectDescription", newProject.projectDescription);
    formData.append(
      "startDate",
      newProject.startDate ? newProject.startDate.toISOString() : ""
    );
    formData.append(
      "endDate",
      newProject.endDate ? newProject.endDate.toISOString() : ""
    );
    formData.append("organizations", JSON.stringify(newProject.organizations));
    formData.append("totalTreesTarget", newProject.totalTreesTarget);
    formData.append("tags", JSON.stringify(tags));
    formData.append("esgGoals", JSON.stringify(sortedEsgGoals));
    formData.append("latitude", latlng && latlng.lat ? latlng.lat : "");
    formData.append("longitude", latlng && latlng.lng ? latlng.lng : "");
    formData.append("address", address);
    formData.append("city", addressData.city);
    formData.append("country", country);
    formData.append("state", addressData.state);
    formData.append("post_code", addressData.postcode);

    if (coverPhoto) {
      const compressedCover = await compressImage(coverPhoto);
      formData.append("projectCoverImage", compressedCover);
      formData.append("coverImageName", coverPhoto.name);
    }

    try {
      const response = await api.post("/api/project/createProject", formData);
      toast.success("Project created successfully!", {
        onClose: () => {
          setNewProject({
            projectName: "",
            projectDescription: "",
            startDate: null,
            endDate: null,
            organizations: [],
            totalTreesTarget: "",
            esgGoals: [],
          });
         
          navigate(
            `/dashboard/projectdashboard/projectArea/${response.data.projectId}`
          );
        }
      });

     
    } catch (error) {
      console.error("Error creating project:", error);
      toast.error("Error creating project.");
    } finally {
      setIsSubmitting(false);
      setTags([]);
      setCoverPhoto(null);
      setAddress("");
      setLatlng(null);
      setCountry(null);
      setAddressData({});
    }
  };

  const cancelProject = () => {
    navigate("/dashboard/projectdashboard");
    setAddress("");
    setLatlng(null);
    setCountry(null);
    setAddressData({});
  };

  return (
    <div className="modal-content">
      <ToastContainer />

      <Dialog
        open={modalConfig.isOpen}
        onClose={closeModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {modalConfig.title}
          <IconButton
            onClick={closeModal}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{renderComponent()}</DialogContent>
      </Dialog>

      <h2>Create New Project</h2>
      <p style={{ fontSize: "13px" }}>
        NOTE: fields marked with <span style={{ color: "red" }}>*</span> are
        mandatory to input.
      </p>

      <div className="form-row">
        <div>
          <label>
            Cover Photo <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="file"
            accept="image/*"
            className="cover-photo-input"
            onChange={(e) => {
              const file = e.target.files[0];
              setCoverPhoto(file);
              setCoverPhotoPreview(URL.createObjectURL(file));
            }}
            style={invalidFields.includes("cover") ? invalidFieldStyle : {}}
          />
          {coverPhotoPreview && (
            <img
              src={coverPhotoPreview}
              alt="Cover Preview"
              className="cover-photo-preview"
            />
          )}
        </div>
      </div>
      <div className="form-row">
        <div>
          <label>
            Project Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            value={newProject.projectName}
            onChange={(e) =>
              setNewProject({ ...newProject, projectName: e.target.value })
            }
            required
            style={
              invalidFields.includes("projectName") ? invalidFieldStyle : {}
            }
          />
        </div>
        <div>
          <label>
            Total Trees Target <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="number"
            value={newProject.totalTreesTarget}
            onChange={(e) => {
              const value = e.target.value;
              if (
                value === "" ||
                (/^\d*$/.test(value) && !value.includes("."))
              ) {
                setNewProject({ ...newProject, totalTreesTarget: value });
              }
            }}
            style={invalidFields.includes("target") ? invalidFieldStyle : {}}
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div>
          <label>
            Start Date <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            selected={newProject.startDate}
            onChange={(date) =>
              setNewProject({ ...newProject, startDate: date })
            }
            wrapperClassName={`${
              invalidFields.includes("startDate") ? "invalid-field" : ""
            }`}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select Start Date"
            required
          />
        </div>
        <div>
          <label>
            End Date <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            selected={newProject.endDate}
            onChange={(date) => setNewProject({ ...newProject, endDate: date })}
            dateFormat="yyyy-MM-dd"
            wrapperClassName={`${
              invalidFields.includes("endDate") ? "invalid-field" : ""
            }`}
            placeholderText="Select End Date"
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div>
          <label>
            Project Description <span style={{ color: "red" }}>*</span>
          </label>
          <textarea
            value={newProject.projectDescription}
            onChange={(e) =>
              setNewProject({
                ...newProject,
                projectDescription: e.target.value,
              })
            }
            style={
              invalidFields.includes("projectDesc") ? invalidFieldStyle : {}
            }
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div>
          <label>
            Organizations <span style={{ color: "red" }}>*</span>
          </label>
          {role === "corporate" || role === "ngo" ? (
            <Select
              options={organizationsOptions}
              isMulti
              value={organizationsOptions}
              onChange={(selectedOptions) =>
                setNewProject({
                  ...newProject,
                  organizations: selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [],
                })
              }
              styles={{
                control: (base) => ({
                  ...base,
                  ...(invalidFields.includes("Org")
                    ? { border: "2px solid red", boxShadow: "none" }
                    : {}),
                }),
              }}
            />
          ) : role === "admin" ? (
            <Select
              options={organizationsOptions}
              isMulti
              onChange={(selectedOptions) =>
                setNewProject({
                  ...newProject,
                  organizations: selectedOptions
                    ? selectedOptions.map((option) => option.value)
                    : [],
                })
              }
              styles={{
                control: (base) => ({
                  ...base,
                  ...(invalidFields.includes("Org")
                    ? { border: "2px solid red", boxShadow: "none" }
                    : {}),
                }),
              }}
            />
          ) : null}
        </div>

        <div>
          <label>
            ESG Goals <span style={{ color: "red" }}>*</span>
          </label>
          <Select
            options={esgOptions}
            isMulti
            onChange={(selectedOptions) =>
              setNewProject({
                ...newProject,
                esgGoals: selectedOptions
                  ? selectedOptions.map((option) => ({
                      value: option.value,
                      index: option.index,
                    }))
                  : [],
              })
            }            
            styles={{
              control: (base) => ({
                ...base,
                ...(invalidFields.includes("Goals")
                  ? { border: "2px solid red", boxShadow: "none" }
                  : {}),
              }),
            }}
            required
          />
        </div>
      </div>

      <div className="form-row">
        <div>
          <label>
            Tags <span style={{ color: "red" }}>*</span>
          </label>
          <div style={invalidFields.includes("tags") ? invalidFieldStyle : {}}>
            <TagsInput value={tags} onChange={setTags} />
          </div>
        </div>

        <div>
          <label>
            Address <span style={{ color: "red" }}>*</span>
          </label>

          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="text"
              placeholder="Select Location From Map *"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              readOnly
              style={{
                flex: "1",
                marginRight: "10px",
                ...(invalidFields.includes("address") ? invalidFieldStyle : {}),
              }}
              onClick={() => openModal("Select Location", PopupMap)}
            />
            <button
              type="button"
              className="fa-solid"
              onClick={() => openModal("Select Location", PopupMap)}
              style={{
                height: "60px",
                paddingRight: "55px",
                paddingLeft: "55px",
                marginBottom: "20px",
                backgroundColor: "#3E6294",
              }}
              tabIndex="3"
              disabled={isSubmitting}
            >
              📍
            </button>
          </div>
        </div>
      </div>

      <div className="button-container">
        <button disabled={isSubmitting} onClick={handleCreateProject}>
          {isSubmitting ? (
            <ClipLoader color="white" size="20" />
          ) : (
            <>Save Project</>
          )}
        </button>
        <button
          disabled={isSubmitting}
          className="btn-cancel"
          style={{ backgroundColor: "#E70000" }}
          onClick={() => {
            cancelProject();
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default CreateProject;
