import React,{useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactGA from 'react-ga4';
import 'react-tooltip/dist/react-tooltip.css'


import Landing from "./pages/Landing";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Technology from "./pages/Technology";
import Upload from "./pages/Upload";
import Search from "./pages/Search";
import Map from "./pages/Map";
import Statistics from "./pages/Statistics";

import Navbar from "./components/common/Navbar";
import Footer from "./components/common/Footer";

import { GlobalProvider } from "./contexts/GlobalContext";

import InteractiveTable from "./components/stats/InteractiveTable"
import Chart from './components/stats/Chart'
import UsersTable from "./components/stats/UsersTable";
import LoginPage from "./pages/Login";
import ForgetPasswordPage from "./pages/ForgetPassword";
import SignupPage from "./pages/SignUp";
import Dashboard from "./pages/Dashboard";
import ClientPage from "./pages/Client";
import Stats from './components/dashboard/Stats';
import EditProfile from "./pages/EditProfile";
import ProfilePosts from "./components/features/ProfilePosts";
import VerifyPosts from "./components/dashboard/VerifyPosts";
import ViewAllUsers from "./components/dashboard/ViewUsers";
import UpdateGallery from './components/dashboard/UpdateGallery';
import ClientImpactDashboard from "./pages/ClientImpactDashboard.js";
import PlantProfile from "./pages/PlantProfile";
import ProjectsDashboard from "./components/dashboard/ProjectsDashboard";
import IndividualProjectDashboard from "./components/dashboard/IndividualProjectDashboard";
import CreateProject from "./components/dashboard/CreateProject";
import ProjectGalleryIndividual from "./components/dashboard/ProjectGalleryIndividual";
import UserImpactDashboard from "./pages/UserImpactDashboard.js";
import Projects from './pages/Projects';
import ProjectPage from "./pages/ProjectPage.js";
import ProtectedRoute from "./components/ProtectedRoute.js";
import Leaders from "./pages/Leaders.js";
import VerifyHistory from "./components/dashboard/VerifyHistory.jsx";
import Activity from "./pages/Activity";
import ActivityPoints from "./pages/ActivityPoints.js";
import RedeemPoints from "./pages/RedeemPoints.js";
import ProfileVerification from "./pages/ProfileVerification.js";
import EmployeeTable from "./components/dashboard/EmployeeTable.jsx";
import ProjectContributors from "./components/dashboard/ProjectContributors.jsx";
import SelectArea from "./components/dashboard/SelectArea.jsx";

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(trackingId);

const mockData = {
  treeStages: {
    progress: [
      { label: "Seedling Stage", value: 20, color: "#87d068", },
      { label: "Planting and Tracking", value: 15, color: "#d30000", },
      { label: "Sapling Growth", value: 15, color: "#faad14" },
      { label: "Maturity", value: 50, color: "black" },
    ],
  },
  treeCO2e: {
    percentage: 75,
  },
};


function App() {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    // Function to handle offline state
    const handleOffline = () => {
      alert('You are offline! Please check your internet connection.');
    };

    // Add event listener for offline event
    window.addEventListener('offline', handleOffline);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <GlobalProvider> 
      <Router>
        <Navbar/>
        <div>
          <Routes>
            <Route path="/" element={<Landing/>}/>
            <Route path="/verify" element={<ProfileVerification/>}/>
            <Route path="TreeRegistry" element={<Home/>}/>
            <Route path="Contact" element={<Contact/>}/>
            <Route path="Technology" element={<Technology/>}/>
            <Route path="Upload" element={<Upload/>}/>
            <Route path="trees" element={<Search/>}/>
            <Route path="map" element={<Map/>}/>
            <Route path="statistics" element={<Statistics/>}>
              <Route path="chart" element={<Chart/>}/>
              <Route path="geographic" element={<InteractiveTable/>}/>
              <Route path="top50users" element={<UsersTable/>}/>
            </Route>
            <Route path="login" element={<LoginPage/>} />
            <Route path="forget-password" element={<ForgetPasswordPage/>} />
            <Route path="signup" element={<SignupPage/>} />
            <Route path="profile" element={<EditProfile/>} />
            <Route path="projects" element={<Projects/>}/>
            <Route path="dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
              <Route path="stats" element={<Stats/>}/>
              <Route path="profile" element={<EditProfile/>}/>
              <Route path="activity" element={<Activity/>}/>
              <Route path="mytrees" element={<ProfilePosts/>}/>
              <Route path="redeem" element={<RedeemPoints/>}/>
              {/* Admin Routes */}
              <Route path="verifytrees" element={<VerifyPosts/>}/>
              {/* <Route path="createuser" element={<CreateUser/>}/> */}
              <Route path="viewusers" element={<ViewAllUsers/>}/>
              <Route path="verifytreehistory" element={<VerifyHistory/>}/>
              <Route path="updategallery" element={<UpdateGallery/>}/>
              <Route path="rewardpoints" element={<ActivityPoints/>}/>
              {/* Admin Corporate Routes */}
              <Route path="projectdashboard/projectArea/:projectId" element={<SelectArea />} />
              <Route path="projectdashboard" element={<ProjectsDashboard/>}/>
              <Route path="projectdashboard/:id" element={<IndividualProjectDashboard/>}/>
              <Route path="projectdashboard/newproject" element={<CreateProject/>}/>
              <Route path="myemployees" element={<EmployeeTable/>}/>
              <Route path="projectdashboard/updateprojectgallery/:id" element={<ProjectGalleryIndividual/>}/>
              <Route path="projectcontributors/:id" element={<ProjectContributors />} />
            </Route>
            <Route path="clients" element={<ClientPage />} />
            <Route path="clientprofile/:clientId" element={<ClientImpactDashboard/>}/>
            <Route path="userprofile/:clientId" element={<UserImpactDashboard/>}/>
            <Route path="plantprofile/:treeId" element={<PlantProfile mockData={mockData}/>}/>
            <Route path="project/:projectid" element={<ProjectPage />} />
            <Route path="Leaders" element={<Leaders />} />
           
          </Routes>
        </div>
        <Footer/>
      </Router>
    </GlobalProvider>
  );
}

export default App;

// Test Commit for v2_T5 branch