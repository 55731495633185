import React,{useEffect,useState} from 'react';
import api from '../../axios/api';

import '../../assets/NewFiles/Css/gallery.css';

const Gallery = () => {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await api.get("/api/user/gallery");
        setGallery(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
  
    fetchPosts();
  }, []);


  return (
  <div className="gallery-wrapper">
  <h2 className="gallery-heading">Gallery</h2>
  <div className="gallery-container">
    {gallery.map((image, index) => (
      <div key={index} className="gallery-item">
        <img src={image.imageUrl} alt={`Gallery ${index + 1}`} />
        <div className="overlay">
          <div className="text">{image.imageDescription}</div>
        </div>
        <div className="title">{image.imageCaption}</div>
      </div>
    ))}
  </div>
</div>
  );
};

export default Gallery;
