import React, { useState, useEffect } from "react";
import api from "../../axios/api";
import "../../assets/NewFiles/Css/Dashboard.css";
import { ToastContainer } from "react-toastify";
import {Pagination, Button,Box ,TableContainer,Table,Paper,TableHead,TableRow,TableCell, TableBody} from "@mui/material";
import BulkUserModal from "./BulkUserModal";
import AddIcon from "@mui/icons-material/Add";


const EmployeeTable = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  const [isBulkUserModalOpen, setIsBulkUserModalOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get("/api/user/getemployees");
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = users.filter(
    (user) => user.role_code !== "admin" && user.role_code !== "superadmin"
  );

  const handlePageChange = (event, value) => {
    setPage(value);
  };

   // Calculate pagination
   const startIndex = (page - 1) * rowsPerPage;
   const endIndex = startIndex + rowsPerPage;
   const paginatedUsers = filteredUsers.slice(startIndex, endIndex);
   const pageCount = Math.ceil(filteredUsers.length / rowsPerPage);
 

  return (
    <Box sx={{ width: "100%", margin: "auto", padding: 2 }}>
       <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={()=>setIsBulkUserModalOpen(true)}
        sx={{ ml: 3 }}
      >
        Create Multiple Users
      </Button>
      <BulkUserModal 
  open={isBulkUserModalOpen} 
  onClose={() => setIsBulkUserModalOpen(false)} 
        />
      <ToastContainer />
      <Box sx={{ padding: 2 }}>
        {/* <div style={{fontSize:'30px',fontWeight:'bold',margin:'10px'}}> My Employees</div> */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#002F43" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>Full Name</TableCell>
              <TableCell sx={{ color: "#fff" }}>Email</TableCell>
              <TableCell sx={{ color: "#fff" }}>Total Trees</TableCell>
              <TableCell sx={{ color: "#fff" }}>Verified Trees</TableCell>
              <TableCell sx={{ color: "#fff" }}>Last Login</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell>{user.full_name}</TableCell>
                <TableCell>{user.userEmail}</TableCell>
                <TableCell>{user.totalTrees}</TableCell>
                <TableCell>{user.verifiedTrees}</TableCell>
                {user.lastLogin ? (
  <TableCell>
    {new Date(user.lastLogin).toLocaleString()}
  </TableCell>
) : <TableCell style={{textAlign:'center'}}>
-
</TableCell>}
              </TableRow>
            ))}
            </TableBody>
          </Table>
      </TableContainer>
      <Box
              sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}
            >
              <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                color="primary"
                  className="pagination-container"
              />
            </Box>
      </Box>
    </Box>
  );
};

export default EmployeeTable;
