import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../axios/api";
import ClipLoader from "react-spinners/ClipLoader";
import DatePicker from "react-datepicker";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../assets/NewFiles/Css/UpdateGallery.css";

const schema = yup.object().shape({
  image: yup
  .mixed()
    .test("required", "Image is required", (value) => {
      return value && value[0];
    }),
  imageCaption: yup
    .string()
    .required("Image Caption is required"),
  imageDescription: yup
    .string()
    .required("Image Description is required"),
  publishFromDate: yup.date().required("Publish From Date is required").typeError("Please enter a valid Publish From Date"),
  publishTillDate: yup
    .date()
    .required("Publish Till Date is required")
    .min(yup.ref("publishFromDate"), "End date must be after start date").typeError("Please enter a valid Publish Till Date"),
});

const PhotoUploadForm = () => {
  const [gallery, setGallery] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  

  // Form states
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Edit form states
  const [editImageCaption, setEditImageCaption] = useState("");
  const [editImageDescription, setEditImageDescription] = useState("");
  const [editPublishFromDate, setEditPublishFromDate] = useState("");
  const [editPublishTillDate, setEditPublishTillDate] = useState("");
  const [editImageFile, setEditImageFile] = useState(null);
  const [editErrors, setEditErrors] = useState({});

  const [publishFromDate, setPublishFromDate] = React.useState(null);
  const [publishTillDate, setPublishTillDate] = React.useState(null);

  const fetchPosts = async () => {
    try {
      const response = await api.get("/api/admin/getadmingallery");
      setGallery(response.data);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  function convertToYYYYMMDD(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  // Handle new image upload
  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("image", data.image[0]);
    formData.append("imageCaption", data.imageCaption);
    formData.append("imageDescription", data.imageDescription);
    formData.append("publishFromDate", convertToYYYYMMDD(data.publishFromDate));
    formData.append("publishTillDate", convertToYYYYMMDD(data.publishTillDate));

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Unauthorized: No token found");
        return;
      }

      const response = await api.post("/api/admin/uploadGallery", formData, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        toast.success("Image upload successful");
        fetchPosts();
        reset();
        setOpenModal(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to upload image");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Validate edit form
  const validateEditForm = () => {
    const errors = {};
    if (!editImageCaption) errors.imageCaption = "Image Caption is required";
    if (!editImageDescription)
      errors.imageDescription = "Image Description is required";
    if (!editPublishFromDate)
      errors.publishFromDate = "Publish From Date is required";
    if (!editPublishTillDate)
      errors.publishTillDate = "Publish Till Date is required";
    if (
      editPublishFromDate &&
      editPublishTillDate &&
      new Date(editPublishTillDate) <= new Date(editPublishFromDate)
    ) {
      errors.publishTillDate = "End date must be after start date";
    }
    setEditErrors(errors);
    return Object.keys(errors).length === 0;
  };

  // Handle image edit and save
  const handleEditSubmit = async (index) => {
    if (!validateEditForm()) return;

    const imageId = gallery[index].id;
    const formData = new FormData();
    if (editImageFile) {
      formData.append("image", editImageFile);
    }
    formData.append("imageCaption", editImageCaption);
    formData.append("imageDescription", editImageDescription);
    formData.append("publishFromDate", convertToYYYYMMDD(editPublishFromDate));
    formData.append("publishTillDate", convertToYYYYMMDD(editPublishTillDate));

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Unauthorized: No token found");
        return;
      }

      const response = await api.put(
        `/api/admin/updateGallery/${imageId}`,
        formData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        toast.success("Image updated successfully");
        setEditingIndex(null);
        fetchPosts();
        reset();
      }
    } catch (error) {
      console.error("Error updating image:", error);
      toast.error("Failed to update image");
    }
  };

  const handleDelete = async (index) => {
    const imageId = gallery[index].id;
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Unauthorized: No token found");
        return;
      }

      const response = await api.delete(
        `/api/admin/deleteGalleryImage/${imageId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        toast.success("Image deleted successfully");
        setGallery((prevGallery) => prevGallery.filter((_, i) => i !== index));
      }
    } catch (error) {
      console.error("Error deleting image:", error);
      toast.error("Failed to delete image");
    }
  };

  const handleEdit = (index) => {
    const image = gallery[index];
    setEditImageCaption(image.imageCaption);
    setEditImageDescription(image.imageDescription);
    setEditPublishFromDate(
      new Date(image.publishFromDate).toISOString().split("T")[0]
    );
    setEditPublishTillDate(
      new Date(image.publishTillDate).toISOString().split("T")[0]
    );
    setEditingIndex(index);
    setEditErrors({});
  };

  const handleCancel = () => {
    setEditingIndex(null);
    setEditImageCaption("");
    setEditImageDescription("");
    setEditPublishFromDate("");
    setEditPublishTillDate("");
    setEditImageFile(null);
    setEditErrors({});
  };

  const handleCloseModal = () => {
    reset();
    setPublishFromDate(null);
    setPublishTillDate(null);
    setOpenModal(false);
  };

  return (
    <div>
      <div className="photo-upload-container">
        <ToastContainer />
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenModal(true)}
          style={{ marginBottom: "20px" }}
        >
          Add Gallery Image
        </Button>

        {/* Upload Modal */}
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
          Select Area
            
            <IconButton
              onClick={handleCloseModal}
              onClose={() => setOpenModal(false)}
              style={{ position: "absolute", right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
          <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
          <form>
      <div className="form-group">
        <label>
          Upload Image <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="file"
         accept="image/*"
          {...register("image")}
          style={{
            borderColor: errors.image ? "red" : "initial",
          }}
        />
        {errors.image && <p className="error-message">{errors.image.message}</p>}
      </div>
      <div className="form-group">
        <label>
          Image Caption <span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          {...register("imageCaption")}
          style={{
            borderColor: errors.imageCaption ? "red" : "initial",
          }}
        />
        {errors.imageCaption && (
          <p className="error-message">{errors.imageCaption.message}</p>
        )}
      </div>
      <div className="form-group">
        <label>
          Image Description <span style={{ color: "red" }}>*</span>
        </label>
        <textarea
          {...register("imageDescription")}
          style={{
            borderColor: errors.imageDescription ? "red" : "initial",
          }}
        />
        {errors.imageDescription && (
          <p className="error-message">{errors.imageDescription.message}</p>
        )}
      </div>

      {/* Date Pickers in One Line */}
      <div className="form-group-date" style={{ display: "flex", gap: "1rem" }}>
        <div style={{ flex: 1 }}>
          <label>
            Publish From Date <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            selected={publishFromDate}
            onChange={(date) => {
              setPublishFromDate(date);
              setValue("publishFromDate", date);
            }}
            dateFormat="yyyy-MM-dd" 
            className={`form-control ${
              errors.publishFromDate ? "invalid-field" : ""
            }`}
          />
          <br/>
          {errors.publishFromDate && (
            <p className="error-message">{errors.publishFromDate.message}</p>
          )}
        </div>
        <div style={{ flex: 1 }}>
          <label>
            Publish Till Date <span style={{ color: "red" }}>*</span>
          </label>
          <DatePicker
            selected={publishTillDate}
            onChange={(date) => {
              setPublishTillDate(date);
              setValue("publishTillDate", date);
            }}
            dateFormat="yyyy-MM-dd"
            className={`form-control ${
              errors.publishTillDate ? "invalid-field" : ""
            }`}
          />
          <br/>
          {errors.publishTillDate && (
            <p className="error-message">{errors.publishTillDate.message}</p>
          )}
        </div>
      </div>
    </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              disabled={isSubmitting}
              variant="contained"
              color="primary"
            >
              {isSubmitting ? (
                <ClipLoader color="white" size="20" />
              ) : (
                "Upload Image"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {/* Gallery Display */}
      <div className="gallery">
        {gallery.map((image, index) => (
          <div key={index} className="gallery-item-dashboard">
            <img src={image.imageUrl} alt={image.imageName} />
            <br />
            {editingIndex === index ? (
              <>
                <input
                  type="text"
                  value={editImageCaption}
                  onChange={(e) => setEditImageCaption(e.target.value)}
                  style={{
                    borderColor: editErrors.imageCaption ? "red" : "initial",
                  }}
                />
                {editErrors.imageCaption && (
                  <p className="error-message">{editErrors.imageCaption}</p>
                )}
                <br />
                <textarea
                  value={editImageDescription}
                  onChange={(e) => setEditImageDescription(e.target.value)}
                  style={{
                    borderColor: editErrors.imageDescription ? "red" : "initial",
                  }}
                />
                {editErrors.imageDescription && (
                  <p className="error-message">{editErrors.imageDescription}</p>
                )}
                <br />
                <DatePicker 
             selected={editPublishFromDate}
              onChange={(date) => setEditPublishFromDate(date)} 
              // style={{
              //   borderColor: editErrors.publishTillDate ? "red" : "initial"
              // }}
              className={`form-control ${
                editErrors.publishFromDate ? "invalid-field" : ""
              }`}
              />
                {editErrors.publishFromDate && (
                  <p className="error-message">{editErrors.publishFromDate}</p>
                )}
                <br />
                <DatePicker 
             selected={editPublishTillDate}
              onChange={(date) => setEditPublishTillDate(date)} 
              // style={{
              //   borderColor: editErrors.publishTillDate ? "red" : "initial",
              // }}
              className={`form-control ${
                editErrors.publishTillDate ? "invalid-field" : ""
              }`}
              
              />
                {editErrors.publishTillDate && (
                  <p className="error-message">{editErrors.publishTillDate}</p>
                )}
                <br />
                <input
                  type="file"
                  onChange={(e) => setEditImageFile(e.target.files[0])}
                />
                <br />
                <button onClick={() => handleEditSubmit(index)}>Save</button>
                <button onClick={handleCancel}>Cancel</button>
              </>
            ) : (
              <>
                <p>
                  <b>Image Caption: </b>
                  {image.imageCaption}
                </p>
                <br />
                <p>
                  <b>Image Description: </b>
                  {image.imageDescription}
                </p>
                <br />
                <p>
                  <b>From: </b>
                  {image.publishFromDate}
                </p>
                <br />
                <p>
                  <b>Till: </b>
                  {image.publishTillDate}
                </p>
                <br />
                <button
                  className="delete-btn"
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </button>
                <button className="edit-btn" onClick={() => handleEdit(index)}>
                  Edit
                </button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoUploadForm;