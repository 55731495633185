import React, { createContext, useState } from 'react';

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [address, setAddress] = useState("");
  const [latlng, setLatlng] = useState(null);
  const [country, setCountry] = useState(null);
  const [isLoggedIn,setLoggedIn]=useState(false);
  const [profilePicture,setProfilePicture]=useState(false);
  const [role,setRole]=useState("");
  const [userId,setUserId]=useState('');
  const [addressData,setAddressData]=useState({});
  const [collectedPoints,setCollectedPoints]=useState(0);
  const [redeemedPoints,setRedeemedPoints]=useState(0);

  return (
    <GlobalContext.Provider value={{ userId,setUserId,address, setAddress, latlng, setLatlng ,country, setCountry,isLoggedIn,
    setLoggedIn,profilePicture,setProfilePicture,role,setRole,addressData,setAddressData,redeemedPoints,setRedeemedPoints,collectedPoints,setCollectedPoints}}>
      {children}
    </GlobalContext.Provider>
  );
};
