import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import api from '../axios/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/NewFiles/Css/ForgetPasswordPage.css';

// Define validation schema using yup
const emailSchema = yup.object().shape({
  userEmail: yup.string().email('Please enter a valid email').required('Email is required'),
});

const resetPasswordSchema = yup.object().shape({
  otp: yup.string().required('OTP is required'),
  newPassword: yup.string().min(6, 'Password must be at least 6 characters').required('New password is required'),
});

const ForgetPasswordPage = () => {
  const [step, setStep] = useState(1);
  const [userEmail, setuserEmail] = useState('');

  // Separate forms for different steps
  const {
    register: registerEmail,
    handleSubmit: handleEmailSubmit,
    formState: { errors: emailErrors },
  } = useForm({
    resolver: yupResolver(emailSchema),
  });

  const {
    register: registerReset,
    handleSubmit: handleResetSubmit,
    formState: { errors: resetErrors },
  } = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });


  const handleSendOtp = async (data) => {
    try {
      const response = await api.post('/api/auth/sendotp', { userEmail: data.userEmail });
      if (response.data.message === 'OTP sent successfully') {
        toast.success('OTP sent successfully');
        setStep(2);
        setuserEmail(data.userEmail); // Save email after successful request
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error sending OTP. Please try again.');
    }
  };

  const handleResetPassword = async (data) => {
    try {
      const response = await api.post('/api/auth/verifyotp', { userEmail, otp: data.otp, newPassword: data.newPassword });
      if (response.data.message === 'Password reset successfully') {
        toast.success('Password reset successfully');
        setStep(3);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error('Error resetting password. Please try again.');
    }
  };

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  return (
    <div className="forget-password-container">
      <ToastContainer />
      <div className="forget-password-form">
        <h2>Forget Password</h2>

        {step === 1 && (
          <form onSubmit={handleEmailSubmit(handleSendOtp)}>
                   <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
            <div className="forget-form-group">
              <label>Email <span style={{color:'red'}}>*</span></label>
              <input
                type="email"
                {...registerEmail('userEmail')}
                placeholder="Enter your email"
                style={emailErrors.userEmail ? invalidFieldStyle : {}}
              />
              {emailErrors.userEmail && <p className="error-message">{emailErrors.userEmail.message}</p>}
            </div>
            <button type="submit" className="send-otp-button">Send OTP</button>
          </form>
        )}

        {step === 2 && (
          <form onSubmit={handleResetSubmit(handleResetPassword)}>
             <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
            <div className="forget-form-group">
              <label>OTP <span style={{color:'red'}}>*</span></label>
              <input
                type="text"
                {...registerReset('otp')}
                placeholder="Enter OTP"
                style={resetErrors.otp ? invalidFieldStyle : {}}
              />
              {resetErrors.otp && <p className="error-message">{resetErrors.otp.message}</p>}
            </div>
            <div className="forget-form-group">
              <label>New Password <span style={{color:'red'}}>*</span></label>
              <input
                type="password"
                {...registerReset('newPassword')}
                placeholder="Enter new password"
                style={resetErrors.newPassword ? invalidFieldStyle : {}}
              />
              {resetErrors.newPassword && <p className="error-message">{resetErrors.newPassword.message}</p>}
            </div>
            <button type="submit" className="reset-password-button">Reset Password</button>
          </form>
        )}

        {step === 3 && <div style={{ fontWeight: 'bold' }}>Password reset successfully!</div>}

        <div className='forget-navigation'>
          <p>Already have an account? </p><Link to="/login" className="option-link">Login</Link>
          <p> Or </p><Link to="/Signup" className="option-link">Sign up Here</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
