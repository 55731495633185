import { useNavigate } from "react-router-dom";
import imageCompression from "browser-image-compression";
import Select from "react-select";
import DatePicker from "react-datepicker";
import React, { useState, useContext, useEffect, useRef } from "react";
import EXIF from "exif-js";
import PopupMap from "./PopupMap";
import { GlobalContext } from "../../contexts/GlobalContext";
import api from "../../axios/api";
import { toast,ToastContainer } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TreeUploadForm = () => {
  const [age, setAge] = useState("");
  const [ageOptions] = useState([
    { value: "1W", label: "1 Week" },
    { value: "6M", label: "1 - 6 Months" },
    { value: "12M", label: "1 Year" },
    { value: "36M", label: "2 - 3 Years" },
    { value: "60M", label: "4 - 5 Years" },
    { value: "96M", label: "6 - 8 Years" },
    { value: "144M", label: "9 - 12 Years" },
    { value: "156M", label: "13 + Years" },
  ]);
  const [uploadType, setUploadType] = useState("");
  const [uploadTypeOptions] = useState([
    { value: "Individual", label: "Individual" },
    { value: "Employee", label: "Employee" },
  ]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [plantSpecies, setPlantSpecies] = useState("");
  const [plantSpeciesOptions, setPlantSpeciesOptions] = useState([]);
  const {
    address,
    setAddress,
    latlng,
    setLatlng,
    country,
    setCountry,
    setLoggedIn,
    isLoggedIn,
    role,addressData,setAddressData
  } = useContext(GlobalContext);
  const fileUploadRef = useRef(null);
  const [invalidFields, setInvalidFields] = useState([]);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [image, setImage] = useState(null);
  const [treeName, setTreeName] = useState("");
  const [treeId, setTreeId] = useState("");
  const [plantedOn, setPlantedOn] = useState("");
  const [diameter,setDiameter]=useState("");
  const [height,setHeight]=useState("");
  const [error, setError] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [saveLoginInfo, setSaveLoginInfo] = useState(false);  
  const [modalConfig, setModalConfig] = useState({
    isOpen: false,
    title: "",
    component: null
  });

  // Function to handle modal opening with different components
  const openModal = (title, component) => {
    setModalConfig({
      isOpen: true,
      title,
      component
    });
  };

  // Function to handle modal closing
  const closeModal = () => {
    setModalConfig({
      isOpen: false,
      title: "",
      component: null
    });
  };

  const handleToggleChange = () => {
    setSaveLoginInfo(!saveLoginInfo);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    // Check if the file is an image
      if (!file || (!file.type.startsWith("image/") && file.type !== "image/webp")) {
      setError("Please upload a valid image file.");
      toast.error("Please upload a valid image file.");
      e.target.value = "";
      return;
    }
    

    // Function to read EXIF data before compression
    const readExifData = () => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.onload = function () {
            EXIF.getData(img, function () {
              const lat = EXIF.getTag(this, "GPSLatitude");
              const lon = EXIF.getTag(this, "GPSLongitude");
              if (lat && lon) {
                console.log(
                  "Raw EXIF data - Latitude:",
                  lat,
                  "Longitude:",
                  lon
                );
                resolve({ lat, lon, img });
              } else {
                reject(new Error("No GPS data found in EXIF"));
              }
            });
          };
          img.src = event.target.result;
        };
        reader.readAsDataURL(file);
      });
    };

    let exifData;
    try {
      // Try to read EXIF data for geolocation
      exifData = await readExifData();

      // Get reference direction (N, S, E, W)
      const latRef = EXIF.getTag(exifData.img, "GPSLatitudeRef") || "N";
      const lonRef = EXIF.getTag(exifData.img, "GPSLongitudeRef") || "W";

      // Calculate the latitude and longitude in decimal format
      const latitude =
        (exifData.lat[0] + exifData.lat[1] / 60 + exifData.lat[2] / 3600) *
        (latRef === "N" ? 1 : -1);
      const longitude =
        (exifData.lon[0] + exifData.lon[1] / 60 + exifData.lon[2] / 3600) *
        (lonRef === "W" ? -1 : 1);

      setLatlng({ lat: latitude, lng: longitude });
      // Fetch address data from the calculated coordinates
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&accept-language=en&lat=${latitude}&lon=${longitude}`
      );
      if (!response.ok) {
        throw new Error("Unable to fetch address data");
      }
      const data = await response.json();
      setAddress(data.display_name);
      setCountry(data.address.country);
      setAddressData(data.address);
    } catch (error) {
      if (error.message === "No GPS data found in EXIF") {
        console.warn("Warning:", error.message);
        setError("No geolocation data found in the image.");
        toast.warn("No geolocation data found in the image.");
        setAddress("");
        setCountry("");
      } else if (error.message === "Unable to fetch address data") {
        console.error("Error:", error.message);
        setError("Failed to retrieve address data from coordinates.");
        toast.error("Failed to retrieve address data from coordinates.");
        setAddress("");
        setCountry("");
      } else {
        console.error("Error processing image:", error.message);
        setError("Failed to process geolocation.");
        toast.error("Failed to process geolocation.");
        setAddress("");
        setCountry("");
      }

      setLatlng(null);
      setError("No geolocation data found in the image.");
    }

    try {
      const options = {
        maxSizeMB: 1,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(file, options);
      setImage(compressedFile);
      toast.success("Image processed successfully!");
    } catch (compressionError) {
      console.error("Error compressing image:", compressionError.message);
      setError("Failed to compress the image.");
      toast.error("Failed to compress the image.");
    }
  };

  useEffect(() => {
    // Update location when address context changes
  }, [address]);

  useEffect(() => {
    const fetchPlantSpecies = async () => {
      try {
        const response = await api.get("/api/user/plantSpecies");
        const speciesData = response.data.map((species) => ({
          value: species.id,
          label: species.label,
          code: species.code,
        }));

        setPlantSpeciesOptions(speciesData);
      } catch (error) {
        console.error("Error fetching plant species:", error);
      }
    };

    fetchPlantSpecies();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const result = await api.get("/api/project/employeeprojects");
        const options = result.data.projects.map((project) => ({
          value: project.id,
          label: project.name,
        }));
        setProjectOptions(options);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, [uploadType]);

  const renderComponent = () => {
    const Component = modalConfig.component;
    return Component ? <Component onClose={closeModal} /> : null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isLoggedIn) {
      toast.error("Please log in to upload a post.");
      setTimeout(() => {
        navigate("/login"); // Redirect to the login page after delay
      }, 1000);
      return;
    }

    const newInvalidFields = [];

    if (!treeId) newInvalidFields.push("treeId");
    if (!image) newInvalidFields.push("image");
    if (!treeName) newInvalidFields.push("treeName");
    if (!address) newInvalidFields.push("address");
    if (!height) newInvalidFields.push("height");
    if (!diameter) newInvalidFields.push("diameter");
    if (!plantedOn) newInvalidFields.push("plantedOn");
    if (!latlng) newInvalidFields.push("latlng");
    if (!age) newInvalidFields.push("age");
    if (!plantSpecies) newInvalidFields.push("species");
    if (role === "employee" && !uploadType) {
      newInvalidFields.push("uploadType");
    }
    if (role === "employee" && uploadType === "Employee" && !selectedProject) {
      newInvalidFields.push("project");
    }
    if (role === "corporate" && !selectedProject) {
      newInvalidFields.push("project");
    }
    if (role === "admin" && !selectedProject) {
      newInvalidFields.push("project");
    }

    if (newInvalidFields.length > 0) {
      setInvalidFields(newInvalidFields);
      setError("Please provide all required information.");
      toast.error("Please provide all required information.");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please log in to upload a post.");
      navigate("/login");
      setLoggedIn(false);
      return;
    }

    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("plantedOn", new Date(plantedOn).toISOString());
    formData.append("Address", address);
    formData.append("Plant_name", treeName);
    formData.append("TreeId", treeId);
    formData.append("lat", latlng.lat.toString());
    formData.append("lng", latlng.lng.toString());
    formData.append("image", image);
    formData.append("image_name", image.name);
    formData.append("country", country);
    formData.append("age", age);
    formData.append("plantSpecies", plantSpecies);
    formData.append("uploadType", uploadType);
    formData.append("projectId", selectedProject);
    formData.append("diameter",diameter);
    formData.append("height",height);

    // More data For Address and Stats
    formData.append("City", addressData.city ? addressData.city : null);
    formData.append("State",addressData.state ? addressData.state : null);
    formData.append("postCode",addressData.postcode ? addressData.postcode : null);

    try {
      const res = await api.post("/api/upload/user", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(res.data.message);

      if (!saveLoginInfo) {
        setTreeId("");
        setAge("");
        setPlantSpecies("");
        setUploadType("");
        setSelectedProject("");
        setImage(null);
        setTreeName("");
        setAddress("");
        setLatlng(null);
        setPlantedOn("");
        setCountry("");
        setAge("");
        setUploadType("");
        setPlantSpecies("");
        setError("");
        fileUploadRef.current.value = "";
        // Navigate after a delay if not saving form data
        setTimeout(() => {
          navigate("/trees");
        }, 2000);
      }
      if (saveLoginInfo) {
        setImage(null);
        setTreeId("");
        setTreeName("");
        fileUploadRef.current.value = "";
        toast.success("Form data saved successfully!");
      }
    } catch (error) {
      setError("Failed to upload image or submit data.");
      toast.error(`Failed to upload image or submit data.${error.response.data.message}`);
    } finally {
      setIsSubmitting(false); // Re-enable the button
    }
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleFocus = (event) => {
    event.target.showPicker();
  };

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  const CustomInput = ({ value, onClick, invalidFields }) => (
    <input
      type="text"
      value={value}
      onClick={onClick}
      readOnly
      placeholder="Planted On *"
      className={`form-control ${
        invalidFields.includes("plantedOn") ? "invalid-field" : ""
      }`}
      style={{
        cursor: "pointer",
        ...(invalidFields.includes("plantedOn")
          ? { border: "1px solid red" }
          : {}),
      }}
    />
  );

  return (<>
   <Dialog 
        open={modalConfig.isOpen} 
        onClose={closeModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>
          {modalConfig.title}
          <IconButton
            onClick={closeModal}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {renderComponent()}
        </DialogContent>
      </Dialog>

    <form onSubmit={handleSubmit} >
              <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
      <h3>Enter all below details to upload tree</h3>
      
      <div style={{display:'flex', gap: "10px",alignItems: "center"}}>

      <input
        type="text"
        value={treeId}
        onChange={(e) => setTreeId(e.target.value)}
        placeholder="Unique Tree ID *"
        style={invalidFields.includes("treeId") ? invalidFieldStyle : {}}
        className="TreeId"
        tabIndex="2"
      />
        <input
        type="text"
        value={treeName}
        onChange={(e) => setTreeName(e.target.value)}
        placeholder="Tree Name *"
        style={invalidFields.includes("treeName") ? invalidFieldStyle : {}}
        tabIndex="2"
      />

      </div>


      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        {role === "employee" && (
          <Select
            placeholder={"Upload As *"}
            options={uploadTypeOptions}
            onChange={(selectedOptions) => setUploadType(selectedOptions.value)}
            styles={{
              container: (base) => ({
                ...base,
                width: "50%",
              }),
              control: (base) => ({
                ...base,
                backgroundColor: "#F5F5F5",
                minHeight: "50px",
                borderColor: "#ced4da",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#adb5bd",
                },
                ...(invalidFields.includes("uploadType")
                  ? invalidFieldStyle
                  : {}),
              }),
              singleValue: (base) => ({
                ...base,
                color: "#495057",
              }),
              placeholder: (base) => ({
                ...base,
                color: "#6c757d",
              }),
            }}
          />
        )}
        {(role === "admin" ||
          role === "corporate" ||
          (role === "employee" && uploadType === "Employee")) && (
          <Select
            placeholder={"Select Project *"}
            onChange={(selectedOptions) =>
              setSelectedProject(selectedOptions.value)
            }
            options={projectOptions}
            styles={{
              container: (base) => ({
                ...base,
                width: "50%",
              }),
              control: (base) => ({
                ...base,
                backgroundColor: "#F5F5F5",
                minHeight: "50px",
                borderColor: "#ced4da",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#adb5bd",
                },
                ...(invalidFields.includes("project") ? invalidFieldStyle : {}),
              }),
              singleValue: (base) => ({
                ...base,
                color: "#495057",
              }),
              placeholder: (base) => ({
                ...base,
                color: "#6c757d",
              }),
            }}
          />
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Select
          placeholder={"Plant Age *"}
          options={ageOptions}
          onChange={(selectedOptions) => setAge(selectedOptions.value)}
          styles={{
            container: (base) => ({
              ...base,
              width: "50%",
            }),
            control: (base) => ({
              ...base,
              backgroundColor: "#F5F5F5",
              minHeight: "50px",
              borderColor: "#ced4da",
              boxShadow: "none",
              "&:hover": {
                borderColor: "#adb5bd",
              },
              ...(invalidFields.includes("age") ? invalidFieldStyle : {}),
            }),
            singleValue: (base) => ({
              ...base,
              color: "#495057",
            }),
            placeholder: (base) => ({
              ...base,
              color: "#6c757d",
            }),
          }}
        />
        <Select
          placeholder={"Plant Species *"}
          options={plantSpeciesOptions}
          onChange={(selectedOptions) => setPlantSpecies(selectedOptions.value)}
          styles={{
            container: (base) => ({
              ...base,
              width: "50%",
            }),
            control: (base) => ({
              ...base,
              backgroundColor: "#F5F5F5",
              minHeight: "50px",
              borderColor: "#ced4da",
              boxShadow: "none",
              "&:hover": {
                borderColor: "#adb5bd",
              },
              ...(invalidFields.includes("species") ? invalidFieldStyle : {}),
            }),
            singleValue: (base) => ({
              ...base,
              color: "#495057",
            }),
            placeholder: (base) => ({
              ...base,
              color: "#6c757d",
            }),
          }}
        />
      </div>

      <div style={{display:'flex', gap: "10px",alignItems: "center"}}>

<input
  type="number"
  value={diameter}
  onChange={(e) => setDiameter(e.target.value)}
  placeholder="Diameter At Breast Height (Meter Only)*"
  style={invalidFields.includes("diameter") ? invalidFieldStyle : {}}
  tabIndex="2"
/>
  <input
  type="number"
  value={height}
  onChange={(e) => setHeight(e.target.value)}
  placeholder="Height (Meter Only)*"
  style={invalidFields.includes("height") ? invalidFieldStyle : {}}
  tabIndex="2"
/>

</div>

      <input
        type="file"
        onChange={handleImageUpload}
        ref={fileUploadRef}
        style={invalidFields.includes("image") ? invalidFieldStyle : {}}
        tabIndex="1"
      />


      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="text"
          placeholder="Select Location From Map *"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          readOnly
          style={{
            flex: "1",
            marginRight: "10px",
            ...(invalidFields.includes("address") ? invalidFieldStyle : {}),
          }}
          onClick={() => openModal("Select Location", PopupMap)}
        />
        <button
          type="button"
          className="fa-solid"
          onClick={() => openModal("Select Location", PopupMap)}
          style={{
            height: "50px",
            paddingRight: "55px",
            paddingLeft: "55px",
            marginBottom: "20px",
            marginTop:"0px",
            backgroundColor: "#3E6294",
          }}
          tabIndex="3"
          disabled={isSubmitting} 
        >
          📍
        </button>
      </div>

      <div
        style={{
          display: "grid",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <DatePicker
          tabIndex="4"
          selected={plantedOn}
          onChange={(date) => setPlantedOn(date)}
          onFocus={handleFocus}
          placeholderText="Planted On"
          showTimeSelect
          dateFormat="Pp"
          className={`form-control ${
            invalidFields.includes("plantedOn") ? "invalid-field" : ""
          }`}
          popperClassName="date-picker-popper"
          popperPlacement="bottom-start"
          customInput={<CustomInput invalidFields={invalidFields} />}
          maxDate={new Date()}
        />
      </div>
      <div className="checkbox-container">
        <input
          type="checkbox"
          id="saveLoginToggle"
          checked={saveLoginInfo}
          onChange={handleToggleChange}
          className="checkbox"
        />
        <span className="checkbox-text">Save info</span>
      </div>

      <br />
      <button type="submit" disabled={isSubmitting}>
  {isSubmitting ? (
    <ClipLoader color="white" size='20'/>
  ) : (
    <>
      Submit
      <i className="fa-solid fa-arrow-right-long" />
    </>
  )}
</button>


      {!address && error && (
        <p
          style={{
            color: "red",
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "10px",
            textAlign: "center",
          }}
        >
          {error}
        </p>
      )}

    </form>
    <ToastContainer />
    </>

  );
};

export default TreeUploadForm;
