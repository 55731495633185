import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

const InteractiveTable = () => {
  const [stats, setStats] = useState([]);

  useEffect(() => {
    const fetchCountryStats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/upload/country-stats`
        );
        setStats(response.data);
      } catch (error) {
        console.error("Error fetching country stats", error);
      }
    };

    fetchCountryStats();
  }, []);

  const TableData = [...stats];

  // Function to calculate totals
  const calculateTotals = (TableData) => {
    let totalPlants = 0;
    let verifiedPlants = 0;
    TableData.forEach((row) => {
      totalPlants += row.total_plants;
      verifiedPlants += row.verified_plants;
    });
    return {
      state: "Total",
      total_plants: totalPlants,
      verified_plants: verifiedPlants,
    };
  };

  // Add the total row to the TableData
  const totalRow = calculateTotals(TableData);
  const dataWithTotal = [...TableData, totalRow];

  return (
    <div style={{ padding: '50px 30px', alignContent: 'center' }}>
      <h1 style={{ textAlign: 'center' }}>States</h1>
      <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
        <Table sx={{ minWidth: 600 }}>
          <TableHead sx={{ backgroundColor: '#002F43' }}>
            <TableRow>
              <TableCell sx={{ color: '#fff' }}>States</TableCell>
              <TableCell sx={{ color: '#fff' }}>Plants</TableCell>
              <TableCell sx={{ color: '#fff' }}>Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataWithTotal.map((row, index) => (
              <TableRow
                key={index}
                sx={row.state === 'Total' ? { 
                  fontWeight: 'bold',
                  '& .MuiTableCell-root': { fontWeight: 'bold' }
                } : {}}
              >
                <TableCell>{row.state}</TableCell>
                <TableCell>{row.total_plants}</TableCell>
                <TableCell>{row.verified_plants}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <style>
        {`
          @media screen and (max-width: 768px) {
            h1, p {
              font-size: 1.2rem;
            }
          }

          @media screen and (max-width: 480px) {
            h1, p {
              font-size: 1rem;
            }
          }
        `}
      </style>
    </div>
  );
};

export default InteractiveTable;