import React, { useState, useEffect } from "react";
import * as yup from "yup";
import api from "../../axios/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BulkUserModal from "./BulkUserModal";

// Validation schema
const validationSchema = yup.object({
  userName: yup
    .string()
    .required("Name is required")
    .min(2, "Name should be at least 2 characters"),
  userEmail: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password should be at least 6 characters"),
  role: yup
    .string()
    .oneOf(["individual", "admin"], "Please select a valid role")
    .required("Role is required"),
});

const initialFormState = {
  userName: "",
  userEmail: "",
  password: "",
  role: "individual",
};

const CreateUser = () => {
  const [open, setOpen] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const handleClickOpen = () => setOpen(true);

  const [isBulkUserModalOpen, setIsBulkUserModalOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setFormData(initialFormState);
    setErrors({});
    setTouched({});
  };

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const response = await api.get("/api/admin/getUsers");
        const adminsData = response.data.users.filter(
          (user) => user.role_code === "admin"
        );
        setAdmins(adminsData);
      } catch (error) {
        console.error("Error fetching admins:", error);
        toast.error("Failed to fetch admin data");
      }
    };

    fetchAdmins();
  }, []);

  const validateField = async (name, value) => {
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (error) {
      setErrors((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (touched[name]) {
      validateField(name, value);
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prev) => ({ ...prev, [name]: true }));
    validateField(name, formData[name]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTouched({
      userName: true,
      userEmail: true,
      password: true,
      role: true,
    });

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Unauthorized: No token found");
        return;
      }

      await api.post(
        "/api/admin/createUser",
        {
          ...formData,
          userEmail: formData.userEmail.toLowerCase(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("User created successfully");
      handleClose();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      } else {
        console.error("Error creating user:", error);
        toast.error("Failed to create user");
      }
    }
  };

  return (
    <Box>
      <ToastContainer />

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleClickOpen}
      >
        Create New User
      </Button>

      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={()=>setIsBulkUserModalOpen(true)}
        sx={{ ml: 3 }}
      >
        Create Multiple Users
      </Button>
      <BulkUserModal 
  open={isBulkUserModalOpen} 
  onClose={() => setIsBulkUserModalOpen(false)} 
        />

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Create New User
          <br />
        </DialogTitle>
        <p style={{ fontSize: "13px", padding: "0px 24px" }}>
          NOTE: fields marked with <span style={{ color: "red" }}>*</span> are
          mandatory to input.
        </p>

        <form onSubmit={handleSubmit}>
          <DialogContent>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                fullWidth
                id="userName"
                name="userName"
                label="Name *"
                value={formData.userName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.userName && Boolean(errors.userName)}
                helperText={touched.userName && errors.userName}
                multiline={true}
                rows={1}
              />

              <TextField
                fullWidth
                id="userEmail"
                name="userEmail"
                label="Email *"
                value={formData.userEmail}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.userEmail && Boolean(errors.userEmail)}
                helperText={touched.userEmail && errors.userEmail}
                multiline={true}
                rows={1}
              />

              <TextField
                fullWidth
                id="password"
                name="password"
                label="Password *"
                type="password"
                value={formData.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />

              <TextField
                fullWidth
                id="role"
                name="role"
                select
                label="Role *"
                value={formData.role}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.role && Boolean(errors.role)}
                helperText={touched.role && errors.role}
              >
                <MenuItem value="individual">Individual</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </TextField>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" variant="contained">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#002F43" }}>
            <TableRow>
              <TableCell style={{ color: "#fff" }}>Username</TableCell>
              <TableCell style={{ color: "#fff" }}>Email</TableCell>
              <TableCell style={{ color: "#fff" }}>Verified Trees</TableCell>
              <TableCell style={{ color: "#fff" }}>Last Login</TableCell>
              <TableCell style={{ color: "#fff" }}>Total Posts</TableCell>
              <TableCell style={{ color: "#fff" }}>Geolocated Count</TableCell>
              <TableCell style={{ color: "#fff" }}>Role</TableCell>
              <TableCell style={{ color: "#fff" }}>Deleted Posts</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {admins.map((admin) => (
              <TableRow key={admin.id}>
                <TableCell>{admin.full_name}</TableCell>
                <TableCell>{admin.userEmail}</TableCell>
                <TableCell>{admin.verifiedTrees}</TableCell>
                <TableCell>
                  {new Date(admin.lastLogin).toLocaleString()}
                </TableCell>
                <TableCell>{admin.totalTrees}</TableCell>
                <TableCell>{admin.geoLocatedTreesCount}</TableCell>
                <TableCell>{admin.role_code}</TableCell>
                <TableCell>{admin.deletedTrees}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CreateUser;
