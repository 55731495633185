import { useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import { FaSatellite } from "react-icons/fa";

const MapToggle = () => {
    const [isSatellite, setIsSatellite] = useState(false);
    const map = useMap();
  
    const handleToggle = () => {
      setIsSatellite(!isSatellite);
      map.eachLayer((layer) => {
        if (layer instanceof L.TileLayer) {
          map.removeLayer(layer);
        }
      });
  
      const newTileLayer = L.tileLayer(
        isSatellite
          ? "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
          : "https://mt1.google.com/vt/lyrs=m&hl=local&x={x}&y={y}&z={z}"
      );
      newTileLayer.addTo(map);
    };
  
    return (
        <div className="leaflet-top leaflet-right" style={{ marginTop: "10px", marginRight: "10px" }}>
        <div className="leaflet-control">
          <button
            onClick={handleToggle}
            className="rounded-full shadow-lg flex items-center justify-center font-bold bg-green-500 text-white p-2 hover:bg-green-600 transition-colors"
          >
           <FaSatellite size={30}/>
          </button>
        </div>
      </div>
    );
  };

  export default MapToggle;