import React, { useState, useEffect } from "react";
import "../../assets/NewFiles/Css/ProjectsDashboard.css";
import { useNavigate } from "react-router-dom";
import api from "../../axios/api";
import { FaPenSquare, FaCheck, FaImage, FaUserAlt,FaMap } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

const ProjectsDashboard = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const rowsPerPage = 10;
  const navigate = useNavigate();

  const fetchProjects = async () => {
    try {
      const response = await api.get("/api/project/getProjects");
      setProjects(response.data.projects);
      
      // Extract unique organizations from projects
      const orgSet = new Set();
      response.data.projects.forEach(project => {
        const orgs = project.organizationName.split(',');
        orgs.forEach(org => orgSet.add(org.trim()));
      });
      setOrganizations(Array.from(orgSet).sort());
      
      setFilteredProjects(response.data.projects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleOrgChange = (event) => {
    const org = event.target.value;
    setSelectedOrg(org);
    setPage(1); // Reset to first page when filter changes
    
    if (org === '') {
      setFilteredProjects(projects);
    } else {
      const filtered = projects.filter(project => 
        project.organizationName.split(',').map(o => o.trim()).includes(org)
      );
      setFilteredProjects(filtered);
    }
  };

  const updateProjectStatus = async (projectId) => {
    try {
      const response = await api.post(
        `/api/project/updateProjectStatus/${projectId}`
      );
      if (response.status === 200) {
        fetchProjects();
      }
    } catch (error) {
      console.error("Error updating project status:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  // Calculate pagination
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedProjects = filteredProjects.slice(startIndex, endIndex);
  const pageCount = Math.ceil(filteredProjects.length / rowsPerPage);

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <div className="projects-dashboard">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          className="btn-create"
          onClick={() => navigate("/dashboard/projectdashboard/newproject")}
        >
          Create New Project
        </Button>

        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="org-select-label">Filter by Organization</InputLabel>
          <Select
            labelId="org-select-label"
            value={selectedOrg}
            label="Filter by Organization"
            onChange={handleOrgChange}
          >
            <MenuItem value="">All Organizations</MenuItem>
            {organizations.map((org) => (
              <MenuItem key={org} value={org}>
                {org}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#002F43" }}>
            <TableRow>
              <TableCell style={{ color: "#fff" }}>Name</TableCell>
              <TableCell style={{ color: "#fff" }}>Duration</TableCell>
              <TableCell style={{ color: "#fff" }}>Planted / Target</TableCell>
              <TableCell style={{ color: "#fff" }}>Address</TableCell>
              <TableCell style={{ color: "#fff" }}>Organization</TableCell>
              <TableCell style={{ color: "#fff" }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedProjects.length > 0 ? (
              paginatedProjects.map((project) => (
                <TableRow key={project.project_id}>
                  <TableCell>{project.name}</TableCell>
                  <TableCell>
                    {new Date(project.startDate).toLocaleDateString()}
                    <br />
                    {new Date(project.endDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {project.total_trees_planted} / {project.target}
                  </TableCell>
                  <TableCell>{project.address}</TableCell>
                  <TableCell>{project.organizationName}</TableCell>
                  <TableCell>
                    <button
                      className="btn-manage"
                      data-tooltip-id="edit-tooltip"
                      data-tooltip-content="Edit Project"
                      onClick={() =>
                        navigate(
                          `/dashboard/projectdashboard/${project.project_id}`
                        )
                      }
                    >
                      <FaPenSquare color="#00a9d3" size={"20px"} />
                    </button>
                    <Tooltip id="edit-tooltip" />

                    <button
                      className="btn-manage"
                      data-tooltip-id="manage-gallery"
                      data-tooltip-content="Manage project gallery"
                      onClick={() =>
                        navigate(
                          `/dashboard/projectdashboard/updateprojectgallery/${project.project_id}`
                        )
                      }
                    >
                      <FaImage color="#00a9d3" size="20px" />
                    </button>
                    <Tooltip id="manage-gallery" />


                    <button
                      className="btn-manage"
                      data-tooltip-id="manage-area"
                      data-tooltip-content="Manage project Area"
                      onClick={() =>
                        navigate(
                          `/dashboard/projectdashboard/projectArea/${project.project_id}`
                        )
                      }
                    >
                      <FaMap color="#00a9d3" size="20px" />
                    </button>
                    <Tooltip id="manage-area" />

                    <button
                      className="btn-manage"
                      data-tooltip-id="active"
                      data-tooltip-content={
                        project.is_active === "0"
                          ? "Activate Project"
                          : "Deactivate Project"
                      }
                      onClick={() => updateProjectStatus(project.project_id)}
                    >
                      <FaCheck
                        color={project.is_active === "0" ? "red" : "#83d300"}
                        size="20px"
                      />
                    </button>

                    <button
                      className="btn-manage"
                      data-tooltip-id="active"
                      data-tooltip-content="Project Contributors"
                      onClick={() =>
                        navigate(
                          `/dashboard/projectcontributors/${project.project_id}`
                        )
                      }
                    >
                      <FaUserAlt color="gray" size="20px" />
                    </button>
                    <Tooltip id="active" />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan="6" align="center">
                  No projects found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color="primary"
          className="pagination-container"
        />
      </Box>
    </div>
  );
};

export default ProjectsDashboard;