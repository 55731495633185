import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaTrash, FaCheck ,FaUserAlt,FaCalendarAlt,FaPagelines,FaClock,FaCalendarPlus,FaTree,FaMapMarkerAlt} from "react-icons/fa";
import api from "../../axios/api";
import { ToastContainer, toast } from 'react-toastify';
import "../../assets/NewFiles/Css/Dashboard.css";
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const VerifyPosts = () => {
  const [posts, setPosts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("latest");
  const [selectedPosts, setSelectedPosts] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const rejectionOptions = [
    { value: "not_tree", label: "Not Tree" },
    { value: "blurry_image", label: "Blurry Image" },
    { value: "duplicate_image", label: "Duplicate Image" },
    { value: "dead_tree", label: "Dead Tree" },
    { value: "need_to_contact", label: "Need to contact" },
  ];

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await api.get("/api/admin/getPosts");
        setPosts(response.data.images);
      } catch (error) {
        console.error("Error fetching posts:", error);
        toast.error("Failed to fetch posts");
      }
    };

    fetchPosts();
  }, []);

  const handleSearch = (e) => setSearchTerm(e.target.value);
  const handleFilter = (e) => setFilter(e.target.value);
  const handleSortOrder = (e) => setSortOrder(e.target.value);

  const handleVerify = async (id) => {
    setIsVerifying(true);
    try {
      await api.patch(`/api/admin/posts/${id}/verify`);
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === id ? { ...post, isVerified: 1 } : post
        )
      );
      toast.success("Verified");
      const response = await api.get("/api/admin/getPosts");
      setPosts(response.data.images);
    } catch (error) {
      console.error("Error verifying post:", error);
      toast.error("Failed to verify post");
    } finally {
      setIsVerifying(false);
    }
  };
  
  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      await api.delete(`/api/admin/posts/${id}`);
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== id));
      toast.error("Deleted");
    } catch (error) {
      console.error("Error deleting post:", error);
      toast.error("Failed to delete post");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleReject = async (id, reason) => {
    try {
      await api.post(`/api/admin/posts/reject/${id}`, { Reason: reason.value });
      toast.error("Rejected");
      const response = await api.get("/api/admin/getPosts");
      setPosts(response.data.images);
    } catch (error) {
      console.error("Error rejecting post:", error);
      toast.error("Failed to reject post");
    }
  };

  const handleSelectPost = (id) => {
    setSelectedPosts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((postId) => postId !== id)
        : [...prevSelected, id]
    );
  };

  const handleVerifySelected = async () => {
    setIsVerifying(true);
    try {
      await Promise.all(
        selectedPosts.map((id) => api.patch(`/api/admin/posts/${id}/verify`))
      );
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          selectedPosts.includes(post.id)
            ? { ...post, isVerified: 1 }
            : post
        )
      );
      setSelectedPosts([]);
      toast.success("Selected posts verified");
      const response = await api.get("/api/admin/getPosts");
      setPosts(response.data.images);
    } catch (error) {
      console.error("Error verifying selected posts:", error);
      toast.error("Failed to verify selected posts");
    } finally {
      setIsVerifying(false);
    }
  };

  const filteredPosts = posts
    .filter(
      (post) =>
        post.isVerified !== 1 &&
        post.treeName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        post.full_name.toLowerCase().includes(filter.toLowerCase())
    )
    .sort((a, b) =>
      sortOrder === "latest"
        ? new Date(b.createdAt) - new Date(a.createdAt)
        : new Date(a.createdAt) - new Date(b.createdAt)
    );

  return (
    <div>
      <ToastContainer/>
      <h2>Verify Trees</h2>
      <div className="controls">
        <input
          type="text"
          placeholder="Search by Plant Name..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-bar"
        />
        <input
          type="text"
          placeholder="Filter by Planter Name..."
          value={filter}
          onChange={handleFilter}
          className="filter-input"
        />
        <select
          value={sortOrder}
          onChange={handleSortOrder}
          className="sort-select"
        >
          <option value="latest">Latest</option>
          <option value="oldest">Oldest</option>
        </select>
      </div>
      <button
        onClick={handleVerifySelected}
        className="verify-selected-button"
        disabled={selectedPosts.length === 0 || isVerifying}
      >
      <FaCheck /> Verify Selected
      </button>

      <div className="posts-grid">
        {filteredPosts.map((post) => (
          <div
            key={post.id}
            className={`post-card ${post.isVerified === 1 ? "verified" : ""}`}
          >
            <input
              type="checkbox"
              checked={selectedPosts.includes(post.id)}
              onChange={() => handleSelectPost(post.id)}
              className="select-checkbox"
            />
            <img
              src={post.treeImageUrl}
              alt={post.imageName}
              className="post-image"
            />
           <div style={{textAlign:'left',padding: '10px', lineHeight: '1.5'}}>
           <h3 data-tooltip-id="View-More-plantName" data-tooltip-content="Tree Name">{post.treeName}</h3>
           
           <p className="p-label"  data-tooltip-id="View-More-plantName" data-tooltip-content="Tree Id"><FaTree style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {post.tree_id}</p>
            {/* <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/> */}
            <br/>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded By"><FaUserAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/>  {post.full_name}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded On"><FaCalendarPlus style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/>  {new Date(post.createdAt).toLocaleDateString()}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Planted On"><FaCalendarAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/>  {new Date(post.plantedOn).toLocaleDateString()}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Species"><FaPagelines style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {post.speciesLabel}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            <p className="p-label" data-tooltip-id="View-More-plantName" data-tooltip-content="Age"><FaClock style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {post.age} year(s)</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
            <br/>
            <p className="post-address" data-tooltip-id="View-More-plantName" data-tooltip-content="Location"><FaMapMarkerAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/> {post.address}</p>
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
           </div>
           <div style={{ display: 'flex', alignItems: 'center', gap: '0px' }}>
  <button
    onClick={() => handleVerify(post.id)}
    className={`verify-button ${post.isVerified === 1 ? "verified" : ""}`}
    style={{
      fontSize: '12px',
      padding: '5px 5px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
    }}
    disabled={post.isVerified === 1 || isVerifying}
  >
    <FaCheckCircle style={{ marginRight: '5px' }} /> {post.isVerified === 1 ? "Verified" : "Verify"}
  </button>

  <button
    onClick={() => handleDelete(post.id)}
    className="delete-button"
    style={{
      fontSize: '12px',
      padding: '5px 5px',
      height: '30px',
      display: 'flex',
      alignItems: 'center',
    }}
    disabled={isDeleting}
  >
    <FaTrash style={{ marginRight: '5px' }} /> Delete
  </button>

  <div style={{ width: '110px' }}>
    <Select
      options={rejectionOptions}
      onChange={(selectedOption) => handleReject(post.id, selectedOption)}
      placeholder="Reject Post"
      className="reject-select"
      classNamePrefix="reject-select"
      styles={{
        control: (provided) => ({
          ...provided,
          fontSize: '12px',
          padding: '0',
          height: '30px',
          minHeight: '20px',
          
        }),
        menu: (provided) => ({
          ...provided,
          fontSize: '12px',
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px',
        }),
      }}
    />
  </div>
</div>


          </div>
        ))}
      </div>
    </div>
  );
};

export default VerifyPosts;