import React, { useEffect, useState } from "react";
import { Line, Doughnut, Bar } from "react-chartjs-2";
import { MapContainer, TileLayer, Popup } from "react-leaflet";
import { FaTree,FaMapMarkerAlt,FaCalendarPlus,FaCalendarAlt,FaClock,FaPagelines ,FaUserAlt} from 'react-icons/fa';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "leaflet/dist/leaflet.css";
import "../assets/NewFiles/Css/ProfilePage2.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { FaCheckCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import api from "../axios/api";
import ReactLoading from "react-loading";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon
} from "react-share";
import {FaInstagram} from 'react-icons/fa';
import MapToggle from "../components/common/MapToggle";

const verifiedIcon = new L.Icon({
  iconUrl: "/images/icon/leaf.png",
  iconSize: [40, 40],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const unverifiedIcon = new L.Icon({
  iconUrl: "/images/icon/leaf.png",
  iconSize: [40, 40],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const mockData = {
  treesByMonth: {
    labels: [
      "2021-1",
      "2021-6",
      "2021-11",
      "2022-4",
      "2022-9",
      "2023-2",
      "2023-7",
      "2023-12",
      "2024-5",
    ],
    data: [300, 3200, 700, 900, 800, 400, 300, 500, 200],
  },
  co2OffsetByMonth: {
    labels: [
      "2021-1",
      "2021-6",
      "2021-11",
      "2022-4",
      "2022-9",
      "2023-2",
      "2023-7",
      "2023-12",
      "2024-5",
    ],
    data: [20, 40, 80, 120, 160, 220, 300, 380, 460],
  },
  position: [20.5937, 78.9629],
  averageWeeklyTrees: {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    data: [120, 150, 180, 130, 160, 90, 110],
  },
};

export default function ClientImpactDashboard() {
  const { clientId } = useParams();
  const [loading, setLoading] = useState(true);

  const [clientData, setClientData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [error, setError] = useState(null);
  const [treeStats,setTreeStats]=useState();
  const [apiStats,setApiStats]=useState([]);
  const calculateAverageWeeklyTrees = (data) => {
    const averageWeeklyTrees = {
      labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      data: [0, 0, 0, 0, 0, 0, 0],
    };

    const dayOfWeekMap = {
      0: 6,
      1: 0,
      2: 1,
      3: 2,
      4: 3,
      5: 4,
      6: 5,
    };

    // Get the current date and subtract 7 days to cover the past week
    const currentDate = new Date();
    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - 7);

    const dayCount = Array(7).fill(0);

    // Iterate through the data and calculate trees planted per day
    data.forEach((tree) => {
      const plantedOn = new Date(tree.plantedOn);
      if (plantedOn >= startDate && plantedOn <= currentDate) {
        const dayOfWeek = plantedOn.getDay();
        const index = dayOfWeekMap[dayOfWeek];
        averageWeeklyTrees.data[index]++;
        dayCount[index]++;
      }
    });

    // Calculate the average by dividing the sum by the number of trees per day
    averageWeeklyTrees.data = averageWeeklyTrees.data.map((count, index) => {
      return dayCount[index] === 0 ? 0 : count;
    });
    return averageWeeklyTrees;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/upload/clientData/${clientId}`);
        if (!response.data || response.data.length === 0) {
          setError("No data available for this client.");
        } else {
          const result = response.data.Treesdata.reduce(
            (acc, tree) => {
              acc.totalTrees++;
              if (tree.isverified === 1) {
                acc.verifiedTrees++;
              }
              return acc;
            },
            { totalTrees: 0, verifiedTrees: 0 }
          );
          result.geolocatedTrees = result.totalTrees;
          setTreeStats(result);
          
          setClientData(response.data.Userdata[0]);
          setTreeData(response.data.Treesdata);
          setCompanyData(response.data.Companydata[0]);
          setApiStats(response.data.TreeStats);
        }
      } catch (error) {
        setError("Failed to fetch data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [clientId]);

  const months = apiStats.map(stat => stat.registration_month) || ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'];

  const treeStatsData = {
   labels: months,
   datasets: [
     {
       label: 'Total Trees Registered',
       borderColor: '#4CAF50',
       data: apiStats.map(stat => stat.total_trees_registered),
       tension: 0.1
     }
   ]
 };

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <ReactLoading type="spin" color="#8BC34A" height={50} width={50} />
      </div>
    );
  }

  const formatDateUs = (dateString) => {
    const options = { year: "numeric", month: "short" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <ReactLoading type="spin" color="#8BC34A" height={50} width={50} />
      </div>
    );
  }

  if (error) {
    return (
      <div style={styles.errorContainer}>
        <p>{error}</p>
      </div>
    );
  }

  if (!clientData || clientData.length === 0) {
    return (
      <div style={styles.noDataContainer}>
        <p>No data found for this client.</p>
      </div>
    );
  }

  return (
    <div className="armasteel-dashboard">
      <section className="bg-image pt-40 pb-40">
        <div className="container">
          <h2
            className="wow fadeInUp"
            data-wow-duration="1.2s"
            data-wow-delay=".2s"
          >
            Client Impact Dashboard :{" "}
            {companyData?.company_name
              ? companyData.company_name
              : clientData?.full_name}{" "}
          </h2>
        </div>
      </section>

<div style={{justifyContent:'center'}}>
{companyData.company_cover_pic && (
  <div
    className="cover-image"
    style={{
      backgroundImage: `url(${companyData.company_cover_pic})`,
      height: "250px",
    }}
  ></div>
)}
</div>

      <div className="dashboard-grid">
        <div className="dashboard-card impact-profile">
          <h2>User Profile</h2>
          <div className="profile-content">
          <img
  src={companyData.company_logo}
  alt="Armasteel Logo"
  className="company-logo"
  style={{ objectFit: 'contain' }}
/>

            <div className="company-info">
              <h3>
                {" "}
                {companyData?.company_name
                  ? companyData.company_name
                  : clientData?.full_name}
              </h3>
              <p>{companyData?.industry ? companyData.industry : ""}</p>
              <br />
              <p>Since: {formatDateUs(clientData.createdAt)}</p>
              
            </div>
            
          </div>
          <div className="share-buttons">
            <FacebookShareButton url={window.location.href} >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton url={window.location.href}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <button
              onClick={() => {
                navigator.clipboard
                  .writeText(window.location.href)
                  .then(() => {
                    const shareUrl = `https://www.instagram.com/`;
                    window.open(shareUrl, "_blank");
                  })
                  .catch((err) => {
                    console.error("Failed to copy: ", err);
                  });
              }}
              style={{
                height: "32px",
                width: "32px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                padding: "5px",
                backgroundColor: "#D93F7C",
              }}
            >
              <FaInstagram size={32} />
            </button>
            <WhatsappShareButton url={window.location.href}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <LinkedinShareButton url={window.location.href}>
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <EmailShareButton url={window.location.href}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>
        </div>

        <div className="dashboard-card total-trees">
          <h2>Total Trees</h2>
          <div className="value">{treeData.length}</div>
          <Line
            style={{ width: "60%", height: "60%" }}
           data={treeStatsData}
            options={{
              plugins: { legend: { display: false } },
              scales: { y: { display: false }, x: { display: false } },
            }}
          />
        </div>

        <div className="dashboard-card plantation-status">
          <h2>Plantation Status</h2>
          <div className="status-circle">
            <Doughnut
              data={{
                labels: ["Planted", "Verified", "Geolocated"],
                datasets: [
                  {
                    data: [
                      treeStats?.totalTrees ?? 0,
                      treeStats?.verifiedTrees ?? 0,
                      treeStats?.geoLocatedTrees ?? 0,
                    ],
                    backgroundColor: ["#4CAF50", "#FFA000", "#d30000"],
                  },
                ],
              }}
              options={{
                cutout: "85%",
                plugins: { legend: { display: false } },
              }}
            />
            <div className="status-text">
              <div className="value">{treeData.length / 1000}K</div>
              <div>Planted</div>
            </div>
          </div>
          <div className="status-legend">
            <div className="legend-item">
              <span className="dot planted"></span> Planted
            </div>
            <div className="legend-item">
              <span className="dot verified"></span> Verified
            </div>
            <div className="legend-item">
              <span className="dot geolocated"></span> Geolocated
            </div>
          </div>
        </div>

        <div className="dashboard-card average-weekly-trees">
          <h2>Average Weekly Trees</h2>
          <div className="value">
            {Math.round(
              calculateAverageWeeklyTrees(treeData).data.reduce(
                (a, b) => a + b,
                0
              ) / 7
            )}
          </div>
          <Bar
            data={{
              labels: calculateAverageWeeklyTrees(treeData).labels,
              datasets: [
                {
                  label: "Trees Planted",
                  data: calculateAverageWeeklyTrees(treeData).data,
                  backgroundColor: "#4CAF50",
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { display: false },
                title: { display: false },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  title: {
                    display: true,
                    text: "Number of Trees",
                  },
                },
                x: {
                  title: {
                    display: true,
                    text: "Day of Week",
                  },
                },
              },
            }}
          />
        </div>


        <div className="dashboard-card trees-by-month">
          <h2>Total Trees by Month</h2>
          <Line data={treeStatsData}/>
        </div>

        <div className="dashboard-card co2-offset-by-month">
          <h2>Total Cumulative CO2 Offset by Month</h2>
          <Line
            data={{
              labels: mockData.co2OffsetByMonth.labels,
              datasets: [
                {
                  label: "CO2 Offset",
                  data: mockData.co2OffsetByMonth.data,
                  borderColor: "#4CAF50",
                  backgroundColor: "rgba(76, 175, 80, 0.1)",
                  fill: true,
                },
              ],
            }}
            options={{
              responsive: true,
              plugins: {
                legend: { display: false },
                title: { display: false },
              },
              scales: { y: { beginAtZero: true } },
            }}
          />
        </div>

        <div className="dashboard-card project-map">
          <h2>Project Locations & Tree Tracking</h2>
          <MapContainer
            center={mockData.position}
            zoom={4}
            style={{ height: "50vh", width: "100%" }}
          >
            <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" />
            <MapToggle/>
            <MarkerClusterGroup>
              {treeData.map((tree) => (
               <Marker
               key={tree.id}
               position={[tree.lat, tree.lng]}
               icon={tree.isVerified ? verifiedIcon : unverifiedIcon}
             >
               <Popup>
                 <div style={{
                   display: 'flex',
                   minWidth: '400px',
                   maxWidth: '600px',
                   gap:'4px',
                   padding:'0'
                 }}>
                   <div style={{ width: '40%' }}>
                   <a
                         target="_blank"
                         className="search-more-info-btn"
                         href={`/plantprofile/${tree.tree_id}`}
                         rel="noreferrer"
                         data-tooltip-id="View-More-plantName" data-tooltip-content="More Info"
                       >
                     <img
                       src={tree.treeimageUrl}
                       alt={tree.imageName}
                       style={{
                         width: '100%',
                         height:'100%',
                         objectFit: 'scale-down',
                         borderRadius: '4px'
                       }}
                     /></a>
                   </div>
                   <div style={{
                     width: '40%',
                     fontSize: '8px'
                   }}>
                     <div style={{
                       display: 'flex',
                       alignItems: 'center',
                     }}>
                        <a
                         target="_blank"
                         className="search-more-info-btn"
                         href={`/plantprofile/${tree.tree_id}`}
                         rel="noreferrer"
                         data-tooltip-id="View-More-plantName" data-tooltip-content="More Info"
                       >
                       <p style={{
                         margin: 0,
                         lineHeight:'1'
   
   
                       }}>{tree.treeName}</p>
                       </a>
                       {tree.isVerified ? (
                         <FaCheckCircle style={{
                           color: '#16a34a',
                           fontSize: '12px'
                         }}/>
                       ):(<FaCheckCircle style={{
                         color: 'yellow',
                         fontSize: '12px'
                       }}/>)}
                     </div>
                     
                     <div style={{ display: 'flex', flexDirection: 'column',marginLeft:'1px'}}>
                     <p style={{ margin: 0,fontSize:'10px',lineHeight:'1',padding:'0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="TreeId">
                         <span style={{ fontWeight: 600,color:'black',fontSize:'10px'}}><FaTree style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/></span>{' '}
                         {tree.tree_id}
                       </p>
                       <p style={{ margin: 0,fontSize:'10px',lineHeight:'1',padding:'0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded By">
                         <span style={{ fontWeight: 600,color:'black',fontSize:'10px'}}><FaUserAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/></span>{' '}
                         {tree.full_name}
                       </p>
                       <p style={{ margin: 0 ,fontSize:'10px',lineHeight:'1',padding:'0'}} data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded On">
                         <span style={{ fontWeight: 600,color:'black' }}><FaCalendarPlus style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/></span>{' '}
                         {new Date(tree.createdAt).toLocaleDateString()}
                       </p>
                       <p style={{ margin: 0,fontSize:'10px',lineHeight:'1' ,padding:'0'}} data-tooltip-id="View-More-plantName" data-tooltip-content="Planted On">
                         <span style={{ fontWeight: 600,color:'black',fontSize:'10px'}}><FaCalendarAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/></span>{' '}
                         {new Date(tree.plantedOn).toLocaleDateString()}
                       </p>
                       <p style={{ margin: 0,fontSize:'10px',lineHeight:'1',padding:'0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="Species">
                         <span style={{ fontWeight: 600,color:'black',fontSize:'10px'}}><FaPagelines style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/></span>{' '}
                         {tree.tree_species_label}
                       </p>
                       <p style={{ margin: 0,fontSize:'10px',lineHeight:'1',padding:'0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="Age">
                         <span style={{ fontWeight: 600,color:'black',fontSize:'10px'}}><FaClock style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/></span>{' '}
                         {tree.age}
                       </p>
   
                       <p style={{
                        margin: 0,
                        overflow: 'hidden', // Prevents overflow
                        textOverflow: 'ellipsis', // Adds ellipsis if the text overflows
                        display: '-webkit-box', // Ensures the flexbox container
                        WebkitBoxOrient: 'vertical', // Sets the text to be vertical
                        WebkitLineClamp: 2, // Limits the lines before truncation, can adjust the number of lines
                        wordBreak: 'break-word', // Breaks long words onto the next line
                        fontSize: '10px',
                        lineHeight: '1',
                        padding: '0'
                       }} data-tooltip-id="View-More-plantName" data-tooltip-content="Location">
                         <span style={{ fontWeight: 600,color:'black' }}><FaMapMarkerAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'} size={14}/></span> {tree.address}
                       
                       </p>
                       </div>
                   </div>
                 </div>
          {/* <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/> */}

               </Popup>
             </Marker>
              ))}
             </MarkerClusterGroup>
          </MapContainer>
          
        </div>
      </div>
    </div>
  );
}

const styles = {
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
};
