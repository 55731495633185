import React, { useEffect, useState } from 'react';
import image01 from '../assets/images/testimonial/01.png';
import api from '../axios/api';
import { FaTree,FaIndustry,FaCalendarAlt } from 'react-icons/fa'
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const TestimonialItem = ({
  userId,
  imageSrc,
  companyName,
  industry,
  treesPlanted,
  since,
}) => {
  return (
    <div className="swiper-slide client_box">
      <div className="testimonial__item">
        <div className="testimonial__item-head">
          <div className="testimonial__item-head-info">
            <div className="testimonial__item-head-info-image">
              <a rel="noopener noreferrer" target="_blank" href={`/clientprofile/${userId}`}>
              <img src={imageSrc} alt={`${companyName} logo`} />
              </a>
            </div>
            <div className="testimonial__item-head-info-con">
            <a rel="noopener noreferrer" target="_blank" href={`/clientprofile/${userId}`}>
            <h3>{companyName}</h3>
            </a>
            <div style={{ color: '#8BC34A' ,fontWeight:"bold"}} data-tooltip-id="View-More-plantName" data-tooltip-content="Industry Type">
            <FaIndustry style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'}/>
            {"  "}{industry}
            <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{fontSize:"10px",padding:'5px'}}/>
          </div>

            </div>
          </div>
        </div>
        <div className="testimonial__item-content">
          <p><FaTree style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'}/> Trees Planted: {treesPlanted}</p>
          <p><FaCalendarAlt style={{ display: "inline-flex",marginBottom:'2px' }}  color={'#002D62'}/> Since: {new Date(since).toLocaleDateString('en-GB', { day: '2-digit', month: 'long', year: 'numeric' })}</p>
          <p><a rel="noopener noreferrer" target="_blank" href={`/clientprofile/${userId}`}>More Info</a></p>
        </div>
      </div>
    </div>
  );
};

const ClientPage = () => {
  const [testimonialData, setTestimonialData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        setIsLoading(true);
        const response = await api.get('/api/upload/getAllClients');
        setTestimonialData(response.data.Companydata || []);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
        setError('Failed to load testimonials. Please try again later.');
        setIsLoading(false);
      }
    };

    fetchTestimonials();
  }, []);

  if (isLoading) {
    return <div>Loading testimonials...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <section className="testimonial">
      <section className="bg-image pt-40 pb-40">
        <div className="container">
          <h2 className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">Clients</h2>      
        </div>
      </section>

      <div className="container">
        <div className="testimonial__head-wrp mb-40">
          <div className="section-header m-5">
            <h5 className="wow fadeInUp" data-wow-duration="1.2s" data-wow-delay=".2s">
              <img src="/images/icon/leaf.png" alt="leaf icon" style={{ display: "inline" }} /> 
              Clients we have served
            </h5>
          </div>
          
          <div className="dot-wrp wow fadeInUp mt-5 mt-sm-0" data-wow-duration="1.6s" data-wow-delay=".6s">
            <div className="dot testimonial__dot"></div>
          </div>
        </div>

        <div className="swiper testimonial__slider___nik">
          <div className="swiper-wrapper___nik client_collage">
            {testimonialData.length > 0 ? (
              testimonialData.map((item, index) => (
                <TestimonialItem
                  key={index}
                  userId={item.created_by_user}
                  imageSrc={item.company_logo || image01}
                  companyName={item.company_name}
                  industry={item.type_name}
                  treesPlanted={item.totalTreesCountByUsers}
                  since={item.created_date}
                />
              ))
            ) : (
              <div>No testimonials available at the moment.</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientPage;