import React, { useEffect, useState } from "react";
import api from "../../axios/api";
import {
  FaCheckCircle,
  FaInstagram,
  FaMapMarkerAlt,
  FaTree,
  FaUsers,
  FaUserCircle,
  FaCalendarAlt,
  FaUserAlt,
  FaCalendarPlus,
  FaClock,
  FaPagelines,
} from "react-icons/fa";
import ReactLoading from "react-loading";
import "../../assets/NewFiles/Css/searchcomponent.css";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from "react-share";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Pagination, Box, Select, MenuItem } from "@mui/material";

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    treeName: false,
    uploadedBy: false,
    location: false,
  });
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(true);
  const [shareUrl, setShareUrl] = useState("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  //pagination
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get(`/api/upload/all-images`);
        setData(response.data.images);
        setStats(response.data.stats);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1);
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFilters({
      ...filters,
      [name]: checked,
    });
    setPage(1);
  };

  const filteredData = data.filter((item) => {
    const lowerSearchTerm = searchTerm.toLowerCase();
    return (
      (!filters.treeName ||
        item.treeName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.uploadedBy ||
        item.userName.toLowerCase().includes(lowerSearchTerm)) &&
      (!filters.location ||
        item.address.toLowerCase().includes(lowerSearchTerm))
    );
  });

  // Calculate pagination
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);
  const pageCount = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <ReactLoading type="spin" color="#8BC34A" height={50} width={50} />
      </div>
    );
  }

  const ShareModal = ({ url, onClose }) => (
    <div className="share-modal">
      <div className="share-modal-content">
        <h3>Share this tree</h3>
        <div className="share-buttons">
          <FacebookShareButton url={url}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <TwitterShareButton url={url}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          <button
            onClick={() => {
              navigator.clipboard
                .writeText(url)
                .then(() => {
                  const shareUrl = `https://www.instagram.com/`;
                  window.open(shareUrl, "_blank");
                })
                .catch((err) => {
                  console.error("Failed to copy: ", err);
                });
            }}
            style={{
              height: "32px",
              width: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              padding: "0",
              backgroundColor: "#D93F7C",
            }}
          >
            <FaInstagram size={20} />
          </button>
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
          <EmailShareButton url={url}>
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>

        <input type="text" value={url} readOnly />
        <button
          onClick={() => {
            navigator.clipboard.writeText(url);
            alert("Link copied!");
          }}
        >
          Copy Link
        </button>

        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );

  return (
    <div className="search-component">
      <div className="search-container">
        <div className="search-row">
          <div className="search-col">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleInputChange}
              className="search-input"
            />
          </div>
          <div className="search-col">
            <div className="filters">
              <div className="checkbox-group">
                <label>
                  <input
                    type="checkbox"
                    name="treeName"
                    checked={filters.treeName}
                    onChange={handleCheckboxChange}
                    className="checkbox-input"
                  />
                  Tree Name
                </label>
                <label >
                  <input
                    type="checkbox"
                    name="uploadedBy"
                    checked={filters.uploadedBy}
                    onChange={handleCheckboxChange}
                    className="checkbox-input"
                  />
                  Uploaded By
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    name="location"
                    checked={filters.location}
                    onChange={handleCheckboxChange}
                    className="checkbox-input"
                  />
                  Location
                </label>
                <label className="checkbox-label" style={{gap:'5px'}}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={rowsPerPage}
                  label="rowsPerPage"
                  onChange={(event) => setRowsPerPage(event.target.value)}
                  sx={{height:30}}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
                Trees Per page
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Centering stats */}
      <div className="stats-container">
        <div className="stat-item">
          <FaTree className="stat-icon" />
          <span className="stat-text">
            Total Plants: {stats.totalTrees || 0}
          </span>
        </div>
        <div className="stat-item">
          <FaCheckCircle className="stat-icon" />
          <span className="stat-text">
            Verified Plants: {stats.verifiedTrees || 0}
          </span>
        </div>
        <div className="stat-item">
          <FaUsers className="stat-icon" />
          <span className="stat-text">
            Total Users: {stats.totalUsers || 0}
          </span>
        </div>
        <div className="stat-item">
          <FaUserCircle className="stat-icon" />
          <span className="stat-text">
            Verified Users: {stats.verifiedUsers || 0}
          </span>
        </div>
      </div>

      {/* Card section */}
      <section className="search-card-section">
        <div className="search-card-container">
          <div className="search-card-row">
            {paginatedData.map((item, index) => (
              <div key={index} className="search-card-col">
                <div className="search-blogCardItem">
                  <div className="search-blogCardImage">
                    <a
                      target="_blank"
                      className="search-more-info-btn"
                      href={`/plantprofile/${item.tree_id}`}
                      rel="noreferrer"
                    >
                      <img
                        data-tooltip-id="View-More"
                        data-tooltip-content="View More Details"
                        src={item.treeImageUrl}
                        alt={item.treeName}
                        className="search-card-image"
                      />
                      <Tooltip id="View-More" className="custom-Tooltip" />
                    </a>
                  </div>
                  <div>
                    <span
                      className="search-card-plantName"
                      data-tooltip-id="View-More-plantName"
                      data-tooltip-content="View More Details"
                    >
                      <span className="search-verified">
                        {item.isVerified ? (
                          <FaCheckCircle color="green" />
                        ) : (
                          <FaCheckCircle color="yellow" />
                        )}
                      </span>
                      <a
                        target="_blank"
                        className="search-more-info-btn"
                        href={`/plantprofile/${item.tree_id}`}
                        rel="noreferrer"
                      >
                        &nbsp;&nbsp;{item.treeName}
                      </a>
                      <Tooltip
                        id="View-More-plantName"
                        className="custom-Tooltip"
                        style={{ fontSize: "10px", padding: "5px" }}
                      />
                    </span>

                    <div className="search-blogCardDetails">
                      <span
                        className="search-card-detail"
                        data-tooltip-id="View-More-plantName"
                        data-tooltip-content="Tree Id"
                      >
                        <FaTree
                          style={{
                            display: "inline-flex",
                            marginBottom: "2px",
                          }}
                          color={"#002D62"}
                          size={14}
                        />{" "}
                        {item.tree_id}
                        <Tooltip
                          id="View-More-plantName"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />
                      </span>
                      <span
                        className="search-card-detail"
                        data-tooltip-id="View-More-plantName"
                        data-tooltip-content="Uploaded By"
                      >
                        <FaUserAlt
                          style={{
                            display: "inline-flex",
                            marginBottom: "2px",
                          }}
                          color={"#002D62"}
                          size={14}
                        />{" "}
                        {item.userName}
                        <Tooltip
                          id="View-More-plantName"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />
                      </span>
                      <span
                        className="search-card-detail"
                        data-tooltip-id="View-More-plantName"
                        data-tooltip-content="Uploaded On"
                      >
                        <FaCalendarPlus
                          style={{ display: "inline-flex" }}
                          color={"#002D62"}
                          size={14}
                        />{" "}
                        {new Date(item.createdAt).toDateString()}
                        <Tooltip
                          id="View-More-plantName"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />
                      </span>
                      <span
                        className="search-card-detail"
                        data-tooltip-id="View-More-plantName"
                        data-tooltip-content="Planted On"
                      >
                        <FaCalendarAlt
                          style={{ display: "inline-flex" }}
                          color={"#002D62"}
                          size={14}
                        />{" "}
                        {new Date(item.plantedOn).toDateString()}
                        <Tooltip
                          id="View-More-plantName"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />
                      </span>
                      <span
                        className="search-card-detail hoverable"
                        data-tooltip-id="View-More-plantName"
                        data-tooltip-content="Species"
                      >
                        <FaPagelines
                          style={{ display: "inline-flex" }}
                          color={"#002D62"}
                          size={14}
                        />{" "}
                        {item.speciesLabel}
                        <Tooltip
                          id="View-More-plantName"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />
                      </span>
                      <span
                        className="search-card-detail hoverable"
                        data-tooltip-id="View-More-plantName"
                        data-tooltip-content="Age"
                      >
                        <FaClock
                          style={{ display: "inline-flex" }}
                          color={"#002D62"}
                          size={14}
                        />{" "}
                        {item.age}
                        <Tooltip
                          id="View-More-plantName"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />
                      </span>
                      <span
                        className="search-card-detail hoverable"
                        data-tooltip-id="View-More-plantName"
                        data-tooltip-content="Location"
                      >
                        <FaMapMarkerAlt
                          style={{ display: "inline-flex" }}
                          color={"#002D62"}
                          size={14}
                        />{" "}
                        {item.address}
                        <Tooltip
                          id="View-More-plantName"
                          className="custom-Tooltip"
                          style={{ fontSize: "10px", padding: "5px" }}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {isShareModalOpen && (
        <ShareModal url={shareUrl} onClose={() => setIsShareModalOpen(false)} />
      )}
      <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color="primary"
          className="pagination-container"
        />
      </Box>
    </div>
  );
};

export default SearchComponent;

const styles = {
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
};
