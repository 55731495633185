import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../contexts/GlobalContext';
import api from '../axios/api';
import { toast } from 'react-toastify';
import CoverPhoto from '../assets/images/banner/cover.jpg'
import '../assets/NewFiles/Css/ProfilePage.css';
import { FaCheckCircle,FaBuilding,FaIndustry,FaTree,FaGlobe,FaMapMarkerAlt } from 'react-icons/fa';
import * as Yup from 'yup';
import { ToastContainer } from 'react-toastify';

const CompanyProfile = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const { isLoggedIn, setLoggedIn } = useContext(GlobalContext);
  const [imagePreview, setImagePreview] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [industryTypeOptions,setIndustryTypeOptions]=useState([]);
  const [errors, setErrors] = useState({});

  const validationSchema = Yup.object().shape({
    ...(editedUser?.role_code === 'corporate' && {
      company_name: Yup.string().required('Company name is required'),
      industry_type_id: Yup.string().required('Industry type is required'),
      website: Yup.string().url('Must be a valid URL').nullable(),
    })
  });

  const fetchIndustryTypes = async () => {
    try {
      const response = await api.get('/api/user/industrytypes');
      setIndustryTypeOptions(response.data[0]);
    } catch (error) {
      console.error('Error fetching Organization Data:', error);
      toast.error('Failed to load organization options. Please try again.');
    }
  }

  useEffect(() => {
    fetchIndustryTypes();
    const fetchData = async () => {
      try {
        const response = await api.get('/api/org/companydetails');
        if (response.data.isLoggedIn) {
          const CompanyInfo = response.data.CompanyInfo;
          setUser(CompanyInfo);
          setEditedUser(CompanyInfo);
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      } catch (error) {
        console.error('Error fetching user data', error);
        setLoggedIn(false);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [setLoggedIn]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "userEmail") return; 
    setEditedUser((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleSave = async () => {
    try {
      await validationSchema.validate(editedUser, { abortEarly: false });
      
      const token = localStorage.getItem('token');
      const formData = new FormData();

      formData.append('companyName', editedUser.company_name);
      formData.append('website', editedUser.website || '');
      formData.append('industryType', editedUser.industry_type_id);

      if (editedUser.userProfilePic instanceof File) {
        formData.append('profilePicture', editedUser.userProfilePic);
      }
      if (editedUser.userCoverPic instanceof File) {
        formData.append('coverImage', editedUser.userCoverPic);
      }
     
      const response = await api.put(`/api/org/updatecompany`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        }
      });

      if (response.data.success) {
        setUser({ ...editedUser });
        toast.success('Profile updated successfully');
        setIsEditing(false);
        toast.success('Profile updated successfully');
      } else {
        console.error('Error updating profile:', response.data.message);
      }
    } catch (error) {
      console.log(error);
      if (error instanceof Yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
        setErrors(newErrors);
      } else {
        console.error('Error updating profile:', error);
      }
    }
  };

  const handleEdit = () => setIsEditing(true);
  const handleCancel = () => {
    setEditedUser({ ...user });
    setIsEditing(false);
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditedUser((prev) => ({ ...prev, userProfilePic: file }));
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditedUser((prev) => ({ ...prev, userCoverPic: file }));
      setCoverImagePreview(URL.createObjectURL(file));
    }
  };

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  if (!isLoggedIn || !user) {
    return <div className="not-logged-in">You are not logged in.</div>;
  }

  const invalidFieldStyle = {
    border: "2px solid red",
  };

  return (
    <div className="profile-container">
      <div className="cover-photo-wrapper">
        <img
          src={coverImagePreview || user.company_cover_pic || CoverPhoto}
          alt="Cover"
          className="cover-photo"
        />
        {isEditing && (
          <div className="image-overlay" onClick={() => document.getElementById('coverPhotoInput').click()}>
            <span>Edit Cover Photo</span>
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          id="coverPhotoInput"
          onChange={handleCoverImageChange}
          className="file-input"
        />
      </div>

      <div className="profile-content">
        <div className="profile-header">
          <div className="profile-picture-wrapper">
            <img
              src={imagePreview || user.company_logo || '/images/sprout.png'}
              alt="Profile"
              className="profile-picture"
            />
            {isEditing && (
              <div className="image-overlay" onClick={() => document.getElementById('profilePictureInput').click()}>
                <span>Edit Profile Picture</span>
              </div>
            )}
            <input
              type="file"
              accept="image/*"
              id="profilePictureInput"
              onChange={handleProfilePictureChange}
              className="file-input"
            />
          </div>
          <div className="profile-info">
          <div className="name-and-wallet">
              <h2 style={{width:'100%',margin:'0'}}>{user.company_name}</h2>

            </div>
            <p className="user-role">{user.industry_type_name}</p>
          </div>
        </div>

        <div className="profile-details">
          {isEditing ? (
            <div className="edit-form">
      <p style={{fontSize:'13px'}}>NOTE: fields marked with <span style={{color:'red'}}>*</span> are mandatory to input.</p>
                  <div className="form-group">
                    <label htmlFor="companyName">Company Name <span style={{color:'red'}}>*</span></label>
                    <input
                      type="text"
                      name="company_name"
                      value={editedUser.company_name}
                      onChange={handleChange}
                      className="profile-input"
                      style={errors.company_name ? invalidFieldStyle : {}}
                    />
                      {errors.company_name && <span className="error-message">{errors.company_name}</span>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="industryType">Industry Type <span style={{color:'red'}}>*</span></label>
                    <select name="industry_type_id" className="profile-input" style={errors.industry_type_id ? invalidFieldStyle : {}} value={editedUser.industry_type_id} onChange={handleChange} >
                        <option value="">Select Industry Type</option>
                        {industryTypeOptions.map((option) => (
                          <option key={option.id} value={option.id} onChange={handleChange} >{option.type_name}</option>
                        ))}
                        
                    </select>
                    {errors.industry_type_id && <span className="error-message">{errors.industry_type_id}</span>}
                    </div>
                  <div className="form-group">
                    <label htmlFor="website">Website</label>
                    <input
                      type="text"
                      name="website"
                      value={editedUser.website}
                      onChange={handleChange}
                      className="profile-input"
                    />
                  </div>
            </div>
          ) : (
            <div className="user-details">              
                  <div className="detail-item">
                    <span className="detail-label" style={{display:'flex'}}><FaBuilding style={{marginTop:'5px',marginRight:'2px'}}/> Company : </span>
                    <span className="detail-value">{user.company_name || ''}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label" style={{display:'flex'}}><FaGlobe style={{marginTop:'5px',marginRight:'2px'}}/> Website : </span>
                    <span className="detail-value">{user.website || ''}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label" style={{display:'flex'}}><FaIndustry style={{marginTop:'5px',marginRight:'2px'}}/> Industry : </span>
                    <span className="detail-value">{user.industry_type_name || ''}</span>
                  </div>
              
              <div className="detail-item">
                <span className="detail-label" style={{display:'flex'}}><FaTree style={{marginTop:'5px',marginRight:'2px'}}/> Total Trees  : </span>
                <span className="detail-value">{user.totalTrees}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label" style={{display:'flex'}}><FaCheckCircle color='green' style={{marginTop:'5px',marginRight:'2px'}}/> Verified Trees  : </span>
                <span className="detail-value">{user.verifiedTrees}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label" style={{display:'flex'}}><FaMapMarkerAlt style={{marginTop:'5px',marginRight:'2px'}}/> Geotagged Trees  : </span>
                <span className="detail-value">{user.geoLocatedTreesCount}</span>
              </div>
            </div>
          )}
        </div>

        <div className="action-buttons">
          {isEditing ? (
            <>
              <button onClick={handleSave} className="button save-button">Save</button>
              <button onClick={handleCancel} className="button cancel-button">Cancel</button>
            </>
          ) : (
            <button onClick={handleEdit} className="button edit-button">Edit Profile</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;