import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import axios from 'axios';
import {
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl
} from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Chart = () => {
  const [yearlyData, setYearlyData] = useState({});
  const [weeklyData, setWeeklyData] = useState([]);
  const [yesterdayData, setYesterdayData] = useState([]);
  const [label, setLabel] = useState([]);
  const [selectedChartFilter, setSelectedChartFilter] = useState('Yearly');
  const [selectedTableYear, setSelectedTableYear] = useState(new Date().getFullYear()); // Default to current year

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/upload/monthly-data`);
        const { data, labels, weekData, threeHourData, yearlyData } = response.data;
        setYearlyData(yearlyData);
        setWeeklyData(weekData);
        setYesterdayData(threeHourData);
        setLabel(labels);
      } catch (error) {
        console.error("Error fetching the data", error);
      }
    };
    fetchData();
  }, []);

  // Filter for chart data
  const filters = {
    'Yesterday': {
      labels: Array.from({ length: 24 }, (_, i) => `${i}:00`),
      datasets: [
        {
          label: 'Yesterday',
          data: yesterdayData,
          fill: false,
          borderColor: 'rgba(255,99,132,1)',
          tension: 0.01
        }
      ]
    },
    'This week': {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      datasets: [
        {
          label: 'This Week',
          data: weeklyData,
          fill: false,
          borderColor: 'rgba(54, 162, 235, 1)',
          tension: 0.01
        }
      ]
    },
    'Yearly': {
      labels: label,
      datasets: Object.keys(yearlyData).map((year) => ({
        label: `Year ${year}`,
        data: yearlyData[year],
        fill: false,
        borderColor: getRandomColor(),
        tension: 0.01
      }))
    }
  };

  const handleChartFilterChange = (event) => {
    setSelectedChartFilter(event.target.value);
  };

  const handleTableYearChange = (event) => {
    setSelectedTableYear(event.target.value);
  };

  const currentChartData = filters[selectedChartFilter];
  
  // Get table data for the selected year in the table's select dropdown
  const availableYears = Object.keys(yearlyData); // Dynamically get available years from yearlyData
  const tableData = yearlyData[selectedTableYear] || [];

  return (
    <Box
      sx={{
        margin: '50px',
        border: '1px solid',
        padding: '50px',
        borderRadius: '10px',
        '& .MuiTableCell-root': {
          padding: '8px',
        }
      }}
    >
      {/* Chart Section */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        margin: '20px 0' 
      }}>
        <FormControl>
          <Select
            value={selectedChartFilter}
            onChange={handleChartFilterChange}
            sx={{
              minWidth: 120,
              '& .MuiSelect-select': {
                padding: '10px',
                fontSize: '16px',
              }
            }}
          >
            <MenuItem value="Yesterday">Yesterday</MenuItem>
            <MenuItem value="This week">This week</MenuItem>
            <MenuItem value="Yearly">Yearly</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ width: '100%', height: '500px' }}>
        <Line data={currentChartData} options={{
          responsive: true,
          plugins: {
            legend: { position: 'top' },
            title: { display: true, text: 'Data Visualization' }
          },
          maintainAspectRatio: false,
        }} />
      </Box>

      {/* Table Section */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        margin: '50px 0',
      }}>
        <FormControl>
          <Select
            value={selectedTableYear}
            onChange={handleTableYearChange}
            sx={{
              minWidth: 120,
              '& .MuiSelect-select': {
                padding: '10px',
                fontSize: '16px',
              }
            }}
          >
            {availableYears.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <TableContainer 
          component={Paper} 
          sx={{ 
            width: '80%', 
            '& .MuiTableCell-root': {
              textAlign: 'center'
            }
          }}
        >
          <Table>
            <TableHead sx={{ backgroundColor: '#002F43' }}>
              <TableRow>
                <TableCell sx={{ color: '#fff' }}>Months {selectedTableYear}</TableCell>
                <TableCell sx={{ color: '#fff' }}>Plants</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {label.map((monthLabel, index) => (
                <TableRow key={index}>
                  <TableCell>{monthLabel}</TableCell>
                  <TableCell>{tableData[index] ?? 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Chart;
