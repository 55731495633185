import React, { useState, useEffect, useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../contexts/GlobalContext";
import "../assets/NewFiles/Css/Dashboard.css";
import { useParams } from "react-router-dom";

const Dashboard = () => {
  const [activeMenu, setActiveMenu] = useState("stats");
  const { role } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const {projectId}=useParams();

  useEffect(() => {
    const path = location.pathname.split("/").pop(); // Get the last part of the URL
    switch (path) {
      case "stats":
        setActiveMenu("stats");
        break;
      case "profile":
        setActiveMenu("editProfile");
        break;
      case "mytrees":
        setActiveMenu("ProfilePosts");
        break;
      case "verifytrees":
        setActiveMenu("verifyPosts");
        break;
      case "createuser":
        setActiveMenu("viewUsers");
        break;
      case "viewusers":
        setActiveMenu("viewUsers");
        break;
      case "updategallery":
        setActiveMenu("updateGallery");
        break;
      case "projectdashboard":
        setActiveMenu("projectdashboard");
        break;
      case `${id}`:
        setActiveMenu("projectdashboard");
        break;
      case "newproject":
        setActiveMenu("projectdashboard");
        break;
      case "verifytreehistory":
        setActiveMenu("verifytreehistory");
        break;
      case "activity":
        setActiveMenu("activity");
        break;
      case "rewardpoints":
        setActiveMenu("rewardpoints");
        break;
      case "redeem":
        setActiveMenu("redeem");
        break;
      case "companyprofile":
        setActiveMenu("companyprofile");
        break;
      case  `${projectId}`:
          setActiveMenu("projectdashboard");
          break;
        case "myemployees":
          setActiveMenu("myemployees");
          break;
      default:
        setActiveMenu("stats");
    }
  }, [location]);

  const handleNavigation = (menu, path) => {
    setActiveMenu(menu);
    navigate(path);
  };

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <button
          className={activeMenu === "stats" ? "active" : ""}
          onClick={() => handleNavigation("stats", "./stats")}
        >
          Dashboard
        </button>
        <button
          className={activeMenu === "editProfile" ? "active" : ""}
          onClick={() => handleNavigation("editProfile", "./profile")}
        >
          Profile
        </button>
        <button
          className={activeMenu === "ProfilePosts" ? "active" : ""}
          onClick={() => handleNavigation("ProfilePosts", "./mytrees")}
        >
          My Trees
        </button>
        <button
          className={activeMenu === "activity" ? "active" : ""}
          onClick={() => handleNavigation("activity", "./activity")}
        >
          {role === "admin" ? "User Activity" : "My Activity"}
        </button>
        <button
          className={activeMenu === "redeem" ? "active" : ""}
          onClick={() => handleNavigation("redeem", "./redeem")}
        >
          {role === "admin" ? "Redeemption Requests" : "Redeem Points"}
        </button>

        {role === "admin" && (
          <>
            <button
              className={activeMenu === "verifyPosts" ? "active" : ""}
              onClick={() => handleNavigation("verifyPosts", "./verifytrees")}
            >
              Verify Trees
            </button>
            <button
              className={activeMenu === "verifytreehistory" ? "active" : ""}
              onClick={() =>
                handleNavigation("verifytreehistory", "./verifytreehistory")
              }
            >
              Verify Tree History
            </button>
          
            <button
              className={activeMenu === "viewUsers" ? "active" : ""}
              onClick={() => handleNavigation("viewUsers", "./viewusers")}
            >
              Manage Users
            </button>
            <button
              className={activeMenu === "updateGallery" ? "active" : ""}
              onClick={() =>
                handleNavigation("updateGallery", "./updategallery")
              }
            >
              Home Gallery
            </button>
            <button
              className={activeMenu === "rewardpoints" ? "active" : ""}
              onClick={() => handleNavigation("rewardpoints", "./rewardpoints")}
            >
              Reward Point Setting
            </button>
          </>
        )}
        {(role === "corporate" || role ==='ngo') && (
          <>
            <button
              className={activeMenu === "myemployees" ? "active" : ""}
              onClick={() =>
                handleNavigation("myemployees", "./myemployees")
              }
            >
              My Employees
            </button>
          </>
        )}

        {(role === "admin" || role === "corporate" || role ==='ngo') && (
          <>
            <button
              className={activeMenu === "projectdashboard" ? "active" : ""}
              onClick={() =>
                handleNavigation("projectdashboard", "./projectdashboard")
              }
            >
              Green Projects
            </button>
          </>
        )}
      </div>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
