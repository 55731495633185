import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { FaCheckCircle, FaTree, FaMapMarkerAlt, FaCalendarPlus, FaCalendarAlt, FaClock, FaPagelines, FaUserAlt ,FaSatellite} from 'react-icons/fa';
import axios from "axios";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import MapToggle from "../common/MapToggle";

const verifiedIcon = new L.Icon({
  iconUrl: "/images/icon/leaf.png",
  iconSize: [40, 40],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const unverifiedIcon = new L.Icon({
  iconUrl: "/images/icon/leaf.png",
  iconSize: [40, 40],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const TreeMap = () => {
  const [trees, setTrees] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/upload/all-images`);
        setTrees(response.data.images);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const position = [20.5937, 78.9629];

  return (
    <MapContainer
      center={position}
      zoom={4}
      style={{ height: "100vh", width: "100%" }}
    >
      <TileLayer url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}" />
      <MapToggle/>
      <MarkerClusterGroup>
        {trees.map((tree) => (
          <Marker
            key={tree.id}
            position={[tree.lat, tree.lng]}
            icon={tree.verified ? verifiedIcon : unverifiedIcon}
          >
            <Popup>
              <div style={{
                display: 'flex',
                minWidth: '400px',
                maxWidth: '600px',
                gap: '4px',
                padding: '0'
              }}>
                <div style={{ width: '40%' }}>
                  <a
                    target="_blank"
                    className="search-more-info-btn"
                    href={`/plantprofile/${tree.tree_id}`}
                    rel="noreferrer"
                    data-tooltip-id="View-More-plantName" data-tooltip-content="More Info"
                  >
                    <img
                      src={tree.treeImageUrl}
                      alt={tree.imageName}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'scale-down',
                        borderRadius: '4px'
                      }}
                    />
                  </a>
                </div>
                <div style={{
                  width: '40%',
                  fontSize: '8px'
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <a
                      target="_blank"
                      className="search-more-info-btn"
                      href={`/plantprofile/${tree.tree_id}`}
                      rel="noreferrer"
                      data-tooltip-id="View-More-plantName" data-tooltip-content="More Info"
                    >
                      <p style={{
                        margin: 0,
                        lineHeight: '1'
                      }}>{tree.treeName}</p>
                    </a>
                    {tree.isVerified ? (
                      <FaCheckCircle style={{
                        color: '#16a34a',
                        fontSize: '12px'
                      }} />
                    ) : (<FaCheckCircle style={{
                      color: 'yellow',
                      fontSize: '12px'
                    }} />)}
                  </div>

                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '1px' }}>
                    <p style={{ margin: 0, fontSize: '10px', lineHeight: '1', padding: '0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="TreeId">
                      <span style={{ fontWeight: 600, color: 'black', fontSize: '10px' }}><FaTree style={{ display: "inline-flex", marginBottom: '2px' }} color={'#002D62'} size={14} /></span>{' '}
                      {tree.tree_id}
                    </p>
                    <p style={{ margin: 0, fontSize: '10px', lineHeight: '1', padding: '0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded By">
                      <span style={{ fontWeight: 600, color: 'black', fontSize: '10px' }}><FaUserAlt style={{ display: "inline-flex", marginBottom: '2px' }} color={'#002D62'} size={14} /></span>{' '}
                      {tree.userName}
                    </p>
                    <p style={{ margin: 0, fontSize: '10px', lineHeight: '1', padding: '0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="Uploaded On">
                      <span style={{ fontWeight: 600, color: 'black' }}><FaCalendarPlus style={{ display: "inline-flex", marginBottom: '2px' }} color={'#002D62'} size={14} /></span>{' '}
                      {new Date(tree.createdAt).toLocaleDateString()}
                    </p>
                    <p style={{ margin: 0, fontSize: '10px', lineHeight: '1', padding: '0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="Planted On">
                      <span style={{ fontWeight: 600, color: 'black', fontSize: '10px' }}><FaCalendarAlt style={{ display: "inline-flex", marginBottom: '2px' }} color={'#002D62'} size={14} /></span>{' '}
                      {new Date(tree.plantedOn).toLocaleDateString()}
                    </p>
                    <p style={{ margin: 0, fontSize: '10px', lineHeight: '1', padding: '0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="Species">
                      <span style={{ fontWeight: 600, color: 'black', fontSize: '10px' }}><FaPagelines style={{ display: "inline-flex", marginBottom: '2px' }} color={'#002D62'} size={14} /></span>{' '}
                      {tree.speciesLabel}
                    </p>
                    <p style={{ margin: 0, fontSize: '10px', lineHeight: '1', padding: '0' }} data-tooltip-id="View-More-plantName" data-tooltip-content="Age">
                      <span style={{ fontWeight: 600, color: 'black', fontSize: '10px' }}><FaClock style={{ display: "inline-flex", marginBottom: '2px' }} color={'#002D62'} size={14} /></span>{' '}
                      {tree.age}
                    </p>

                    <p style={{
                      margin: 0,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      wordBreak: 'break-word',
                      fontSize: '10px',
                      lineHeight: '1',
                      padding: '0'
                    }} data-tooltip-id="View-More-plantName" data-tooltip-content="Location">
                      <span style={{ fontWeight: 600, color: 'black' }}><FaMapMarkerAlt style={{ display: "inline-flex", marginBottom: '2px' }} color={'#002D62'} size={14} /></span> {tree.address}
                    </p>

                    <Tooltip id="View-More-plantName" className="custom-Tooltip" style={{ fontSize: "10px", padding: '5px' }} />
                  </div>
                </div>
              </div>
            </Popup>
          </Marker>
        ))}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default TreeMap;