import React, { useState, useEffect, useContext } from "react";
import api from "../axios/api";
import {
  Box,
  Typography,
  Button,
  Modal,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Pagination,
} from "@mui/material";
import { GlobalContext } from "../contexts/GlobalContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const itemsPerPage = 10;

const PointRedemptionPage = () => {
  const [availablePoints, setAvailablePoints] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redemptionRequests, setRedemptionRequests] = useState([]);
  const [pointsToClaim, setPointsToClaim] = useState("");
  const [userName, setUserName] = useState("");
  const { role } = useContext(GlobalContext);
  const [page, setPage] = useState(1);

  //search sort and filter
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFilter, setSearchFilter] = useState("requestCode");
  const [sortOrder, setSortOrder] = useState("desc");

  const filteredAndSortedRequests = redemptionRequests
    .filter((request) => {
      if (searchFilter === "user") {
        return request.full_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      } else if (searchFilter === "requestCode") {
        return request.request_code
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      } else if (searchFilter === "status") {
        const status =
          request.request_status === 1
            ? "success"
            : request.request_status === -1
            ? "rejected"
            : "new request";
        return status.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return true;
    })
    .sort((a, b) => {
      if (sortOrder === "asc") {
        return new Date(a.created_at) - new Date(b.created_at);
      } else {
        return new Date(b.created_at) - new Date(a.created_at);
      }
    });

  const displayValue = (value) => value || "-";

  const getPaginatedData = () => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredAndSortedRequests.slice(startIndex, endIndex);
  };

  const paginatedActivities = getPaginatedData();

  const totalPages = Math.ceil(filteredAndSortedRequests / itemsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchPointRedemptionData();
  }, []);

  const fetchPointRedemptionData = async () => {
    try {
      const response = await api.get("/api/points/getpointredemptionData", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      const { Requests, Points } = response.data;
      setRedemptionRequests(Requests);
      setAvailablePoints(Points[0].collected_points);
      setUserName(Points[0].full_name);
    } catch (error) {
      toast.error("Failed to fetch point redemption data");
    }
  };

  const handleClaimClick = () => {
    setIsModalOpen(true);
  };

  const handleConfirmRedemption = async () => {
    const pointsToClaimNumber = parseInt(pointsToClaim, 10);

    if (
      !pointsToClaim ||
      pointsToClaim <= 0 ||
      pointsToClaim > availablePoints
    ) {
      toast.error("Points exceed current balance");
      return;
    }

    setIsLoading(true);
    try {
      await api.post(
        "/api/points/redeemMyPoints",
        { RequestedPoints: pointsToClaimNumber },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setIsLoading(false);
      setIsModalOpen(false);
      toast.success(
        "Your Redemption request created successfully ! our team will respond to it within 24 hr!"
      );
      fetchPointRedemptionData();
      setPointsToClaim("");
    } catch (error) {
      setIsLoading(false);
      setIsModalOpen(false);
      toast.error(error.response?.data?.message || "Failed to redeem points");
    }
  };

  const handleApprove = async (reqid) => {
    try {
      await api.post(
        "/api/admin/ApproveClaimRequest",
        { reqid },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Request approved successfully");
      fetchPointRedemptionData();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to approve request");
    }
  };

  const handleReject = async (reqid, rejectionReason) => {
    try {
      await api.post(
        "/api/admin/RejectClaimRequest",
        { reqid, rejectionReason },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      toast.success("Request rejected successfully");
      fetchPointRedemptionData();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to reject request");
    }
  };

  return (
    <Box sx={{ width: "100%", margin: "auto", padding: 3 }}>
      <Typography variant="h6" gutterBottom>
        Current Balance: {availablePoints} points ({userName})
      </Typography>

      <Button
        variant="contained"
        onClick={handleClaimClick}
        sx={{ minWidth: 200, marginBottom: 4 }}
      >
        Create Redeem Request
      </Button>

      <Box sx={{ display: "flex", gap: 2, marginBottom: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ flexGrow: 1 }}
          multiline={true}
          rows={1}
        />
        <FormControl variant="outlined" size="small" sx={{ minWidth: 120 }}>
          <InputLabel>Search By</InputLabel>
          <Select
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            label="Search By"
            multiline={true}
            rows={1}
          >
            <MenuItem value="requestCode">Request Code</MenuItem>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="status">Status</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="outlined"
          onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
        >
          Sort {sortOrder === "asc" ? "↑" : "↓"}
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#002F43" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff" }}>Request Code</TableCell>
              <TableCell sx={{ color: "#fff" }}>User</TableCell>
              <TableCell sx={{ color: "#fff" }}>
                Approved / Requested Points
              </TableCell>
              <TableCell sx={{ color: "#fff" }}>Status</TableCell>
              {/* <TableCell sx={{ color: "#fff" }}>Approved Points</TableCell> */}
              <TableCell sx={{ color: "#fff" }}>Requested At</TableCell>
              {role === "admin" && (
                <TableCell sx={{ color: "#fff" }}>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedActivities.map((request) => (
              <TableRow key={request.id}>
                <TableCell>{displayValue(request.request_code)}</TableCell>
                <TableCell>{displayValue(request.full_name)}</TableCell>
                <TableCell
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {`${displayValue(request.point_approved)} / ${displayValue(
                    request.point_requested
                  )}`}
                </TableCell>

                <TableCell>
                  {request.request_status === 1 && (
                    <>
                      {" "}
                      <span style={{ color: "green" }}>Success</span>
                    </>
                  )}
                  {request.request_status === -1 && (
                    <>
                      <span style={{ color: "red" }}>Rejected</span>
                      <div style={{ color: "red" }}>
                        {" "}
                        ({request.rejection_reason})
                      </div>
                    </>
                  )}
                  {request.request_status === 0 && (
                    <span style={{ color: "#FFDE21" }}>New Request</span>
                  )}
                </TableCell>
                {/* <TableCell>{displayValue(request.point_approved)}</TableCell> */}
                <TableCell>
                  {displayValue(
                    request.created_at
                      ? new Date(request.created_at).toLocaleString()
                      : null
                  )}
                </TableCell>
                {role === "admin" ? (
                  request.request_status === 0 ? (
                    <TableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={() => handleApprove(request.id)}
                          variant="contained"
                          color="primary"
                          size="small"
                          sx={{ width: 80, marginBottom: "0px" }}
                        >
                          Approve
                        </Button>

                        <FormControl
                          sx={{
                            width: 80,
                            marginBottom: "10px",
                            lineHeight: "1.75",
                            display: "inline-flex",
                            padding: "0px",
                          }}
                        >
                          <InputLabel
                            id={`reject-reason-label-${request.id}`}
                            sx={{
                              fontSize: 12,
                              paddingBottom: 4,
                              textAlign: "center",
                            }}
                          >
                            Reject Reason
                          </InputLabel>
                          <Select
                            labelId={`reject-reason-label-${request.id}`}
                            defaultValue=""
                            size="small"
                            onChange={(e) =>
                              handleReject(request.id, e.target.value)
                            }
                          >
                            <MenuItem value="" disabled>
                              Select Reason
                            </MenuItem>
                            <MenuItem value="Reason A">Reason A</MenuItem>
                            <MenuItem value="Reason B">Reason B</MenuItem>
                            <MenuItem value="Reason C">Reason C</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </TableCell>
                  ) : (
                    <TableCell
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "24px",
                      }}
                    >
                      -
                    </TableCell>
                  )
                ) : (
                  <></>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {totalPages > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>
      )}

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="redemption-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Redeem Points
          </Typography>
          <TextField
            fullWidth
            type="number"
            label="Points to Redeem *"
            value={pointsToClaim}
            onChange={(e) => {
              const value = e.target.value;
              if (
                value === "" ||
                (/^\d*$/.test(value) && !value.includes("."))
              ) {
                setPointsToClaim(value);
              }
            }}
            sx={{ mt: 2 }}
            required
          />

          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={() => setIsModalOpen(false)} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              onClick={handleConfirmRedemption}
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Confirm"}
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer closeOnClick />
    </Box>
  );
};

export default PointRedemptionPage;
